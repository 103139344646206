<template>
  <el-dialog
    :title="$t(`${info.title}`)"
    :visible.sync="info.show"
    :append-to-body="true"
    :class="info.class"
    :show-close="showClose"
    top="10px"
    :close-on-click-modal="false"
    width="460px"
  >
    <div class="contents">
      <slot></slot>
      <div v-html="info.content"></div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel" v-if="!info.hideCancel">{{
        $t(`${info.cancel}`)
      }}</el-button>
      <el-button type="primary" v-if="!info.hideSave" @click="save">{{
        $t(`${info.save}`)
      }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    info: Object,
    showClose: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {}
  },
  created () {},
  methods: {
    cancel () {
      this.info.show = false
      this.$emit('cancel', 'notAllowed')
    },
    save () {
      this.$emit('save')
      this.info.show = false
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.el-dialog {
  background: #fff;
  .el-dialog__body {
    text-align: left;
    border-top: 2px solid #f5f5f5;
  }
  .el-dialog__header {
    line-height: 22px;
    text-align: left;
  }
  .el-dialog__title {
    color: #28282e;
    font-size: 18px;
    font-weight: bold;
  }
  .el-dialog__close {
    font-size: 20px;
    color: #28282e;
    font-weight: bold;
  }
  .el-button {
    border: none;
    background: none;
    color: #555661;
    padding: 9px 20px;
    width: auto;
    border-radius: 20px;
  }
  .el-button--primary {
    color: #fff;
    height: auto;
    background: linear-gradient(
      270deg,
      rgba(3, 161, 118, 1) 0%,
      rgba(113, 186, 42, 1) 59%,
      rgba(208, 217, 0, 1) 100%
    );
  }
}
.contents {
  text-align: left;
  font-size: 16px;
  div,
  p {
    word-break: break-word;
  }
}
</style>
