<template>
  <div
    class="container"
    :class="{
      allScreenContainer:
        singChannel.shareCode &&
        singChannel.shareCode == singChannel.id &&
        isSelfAllScreen,
      share: singChannel.shareCode,
      fullScreen: isFullscreen,
      down: !State.showFootBar
    }"
  >
    <div class="hide" style="position:absolute; z-index:100">
      r:{{ singChannel.id }}, p:{{ singChannel.channel }}, {{ controlMute }}
    </div>
    <div class="nickNameBox">{{ nickName }}</div>
    <div class="box" :style="{'height':modeKey === 'mainWindow' ?'calc(100vh - 63px)':''}">
      <div
        class="video-con"
        ref="videoCon"
        :style="{'border':coordRoomStatus && notJoinR ? '2px solid #33AB4F':'' }"
        :class="{
          mainWindow: title === 'PGM' || title === 'VFB' || title === 'Share',
          isPip: isPip
        }"
      >
        <div class="videoBg" v-if="showVideoBg">
          <span class="title"
            >{{ nickName || title }}{{ tagName ? ` / ${tagName}` : '' }}</span
          ><span
            class="meTitle"
            v-if="userInfo.rtilCode == singChannel.id && nickName"
            >({{$t('coordinationRoom.me')}})</span
          >
        </div>
        <slot />
        <div
          class="videoBox"
          ref="videoTest"
          :id="`videoBox${singChannel.id}`"
          :class="{ private: showPrivateBg }"
        ></div>
        <VideoControl
          ref="videoControl"
          v-if="videoControl"
          :muteAudioIcon="muteAudioIcon"
          :userInfo="userInfo"
          @callback="controlCallback"
          :class="addPipClass"
          :videoObj="$refs['videoTest']"
          :singChannel="singChannel"
          :pgmMute="pgmMute"
        />
        <div class="operateBox" v-if="singChannel.id && showOperateBox">
          <div class="column clearfix" style="display: flex; flex-wrap: nowrap;justify-content: space-between;">
            <div style="float: left;">
              <div class="iconBox" v-show="privateInfo.length" :style="{'margin-right':!isTalkback ?'-9px':''}">
                <i class="iconfont icon-a-talkbackoff" :class="{privateIcon: privateInfo.length}"></i>
              </div>
              <div class="iconBox" style="margin-right: 8px;" :style="{'margin-left':!privateInfo.length? '2px':''}">
                <div class="buttons">
                  <div class="lds-ripple" v-if="isTalkback">
                  <div></div><div></div>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('coordinationParticipant.talkBack')"
                    placement="top">
                    <i v-if="talkBackType=='callAll'" class="iconfont icon-toolbartalkbackon"></i>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('coordinationParticipant.talkHostBack')"
                    placement="top">
                    <i v-if="talkBackType=='callHost'" class="iconfont icon-talkHostOff"></i>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="$t('coordinationParticipant.talkPipBack')"
                    placement="top">
                    <i v-if="talkBackType=='callPip'" class="iconfont icon-talkPipOff"></i>
                  </el-tooltip>
                  </div>
                </div>
              </div>
              <div class="iconBox" v-if="showMuteIcons" :style="{'margin-left':isTalkback?'':''}">
                <!-- 麦克风摄像头 -->
                <i
                  :class="{
                    'el-icon-arrow-down': showVideoBottom,
                    'el-icon-arrow-up': !showVideoBottom
                  }"
                  v-if="$scopedSlots['videoBottom']"
                  @click="showVideoBottom = !showVideoBottom"
                ></i>
                <!-- <i v-if="!muteAudioIcon" class="iconfont icon-universal_micon" :class="{green: volume.level}" :title="volume.level || ''" @click="setVideoAndAudio('muteAudio')"></i> -->
                <!--开-->
                <i
                  class="micphone"
                  v-if="!muteAudioIcon && $route.name != 'coordination'"
                  @click="setVideoAndAudio('muteAudio')"
                  :title="volume.level ? parseInt(volume.level) : ''"
                >
                  <MicPhone :level="volume.level" />
                </i>
                <!--关-->
                <svg
                  class="muteAudioImg"
                  aria-hidden="true"
                  v-if="muteAudioIcon && $route.name != 'coordination'"
                  @click="setVideoAndAudio('muteAudio')"
                >
                  <use xlink:href="#icon-universal_micoff"></use>
                </svg>
                <i
                  v-if="!muteVideoIcon && showVideoIcon"
                  class="iconfont icon-universal_cameraon camera"
                  @click="setVideoAndAudio('muteVideo')"
                ></i>
                <svg
                  class="muteVideoImg"
                  aria-hidden="true"
                  v-if="muteVideoIcon && showVideoIcon"
                  @click="setVideoAndAudio('muteVideo')"
                >
                  <use xlink:href="#icon-universal_cameraoff"></use>
                </svg>
                <!-- 角色设置 -->
                <div class="setRole" v-if="asRoleList.length">
                  <operate-button
                    :btnList="asRoleList"
                    :userInfo="userInfo"
                    :singChannel="singChannel"
                    :nickName="nickName"
                    title="Role as"
                    activeBg="green"
                    type="asRole"
                    :currentRole="currentRole"
                    @callback="operateCallback"
                  ></operate-button>
                </div>
                <slot name="iconSlot"></slot>
                <VoIP
                  v-show="judgeShowVoIP"
                  :selectRid="selectRid"
                  :userInfo="userInfo"
                  :rtilCode="singChannel.id"
                  :memberId="singChannel.tid"
                  :nickName="nickName"
                  ref="voip"
                >
                </VoIP>
              </div>
            </div>
            <!-- 仅自己可以修改名字 -->
            <div
              class="infoBox"
              :class="{
                active: privateEach,
                editTitle: editTitle,
                danger: theme == 'danger'
              }"
            >
              <div
                class="title clearfix"
                :class="{
                  isMe: userInfo.rtilCode == singChannel.id && nickName
                }"
                :title="nickName"
                v-if="showTitle"
              >
                <div
                  class="left titleBox"
                  :class="`${nameColor}`"
                  v-if="showTitle && !showEditInput && !showVideoBg"
                >
                  <div class="name" v-if="theme != 'danger'">
                   <span> {{ nickName || title }}{{ tagName ? ` / ${tagName}` : ''}}</span>
                    <span
                      class="meTitle"
                      v-if="userInfo.rtilCode == singChannel.id && nickName"
                      >({{$t('coordinationRoom.me')}})</span
                    >
                  </div>
                  <div class="rename" @click="editName" v-if="nickName">
                    <i class="el-icon-edit"></i>{{$t('leftPane.rename')}}
                  </div>
                </div>
                <el-input
                  ref="name"
                  class="editInput"
                  :class="{ red: redTip }"
                  :maxlength="32"
                  :autofocus="true"
                  v-if="showEditInput"
                  v-model="name"
                  @keyup.enter.native="saveName"
                ></el-input>
                <span class="red" v-if="redTip && showEditInput">{{
                  redTip
                }}</span>
              </div>
              <div class="appendTitle right" v-if="operateList.length">
                <!-- <slot name="appendTitle"></slot> -->
                <el-divider
                  class="line"
                  direction="vertical"
                  v-if="showTitle"
                ></el-divider>
                <operate-button
                  :btnList="operateList"
                  :userInfo="userInfo"
                  :singChannel="singChannel"
                  :nickName="nickName"
                  :organization="organization"
                  :sourceLiveStatus="buttonStatus"
                  :currentRole="currentRole"
                  @callback="operateCallback"
                ></operate-button>
              </div>
            </div>
          </div>
          <div class="videoBottom" v-show="showVideoBottom">
            <slot name="videoBottom"></slot>
          </div>
        </div>
        <div class="disconnected" v-if="coordRoomStatus && notJoinR">
          <div class="notJoinRContent">
            <span>{{ $t('HostCommon.rejoinRText') }}</span>
            <el-button :loading="loading" @click="rejoinR()" class="rejoinRButton">{{ $t('HostCommon.rejoinR')  }}</el-button>
          </div>
         </div>
        <p class="initCameraTips" v-if="showTips">{{ $t('HostCommon.initVideoTrack') }}</p>
        <p class="initCameraTips" v-if="State.showInitCameraTips && title == 'Me'">{{ $t('HostCommon.initCameraTips', { name: State.cameraName }) }}</p>
      </div>
      <div
        class="bottom-video-bar-wrapper"
        v-show="
          !isSelfAllScreen &&
            singChannel.shareCode &&
            singChannel.shareCode == singChannel.id
        "
      >
        <!-- 分享屏幕发起人的名称 -->
        <div class="bottom-box-left">
          <span class="shareNickName ellipsis" :title="nickName">{{
            nickName
          }}</span>
          {{$t('coordinationRoom.isSharing')}}
        </div>
        <div class="bottom-box-right">
          <i
            class="iconfont allScreenIcon pointer"
            @click="checkAll(singChannel)"
          ></i>
        </div>
      </div>
      <div class="topLeft clearfix">
        <div class="status" v-if="singChannel.id && !notJoinR">
          <!-- //onAir muteAudio isTalkBack talkType -->
            <div v-if="!isTalkBack">
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('coordinationRoom.offAirEnabled')"
                placement="top"
                v-show="!onAir && coordRoomStatus && !muteAudio">
                <span class="status-off-air">{{$t('coordinationRoom.offAir')}}</span>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('coordinationRoom.onAirEnabled')"
                placement="top"
                v-show="onAir && !muteAudio"
                >
                <span class="status-on-air">{{$t('coordinationRoom.onAir')}}</span>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('coordinationRoom.mutedOff')"
                placement="top"
                v-show="muteAudio && coordRoomStatus">
                <span  class="status-muted">{{$t('coordinationRoom.muted')}}</span>
              </el-tooltip>
            </div>
            <div v-else class="talkBackContent">
              <el-tooltip
                class="item"
                effect="dark"
                :content="$t('coordinationParticipant.talkBackEnabled')"
                placement="top">
                <span v-if="talkType && coordRoomStatus">{{ talkBackText }}</span>
              </el-tooltip>
            </div>
        </div>
        <div class="liveSign" v-if="liveInfo.peerId">
          <el-tooltip
            class="item"
            effect="dark"
            :content="liveInfo.name"
            placement="top"
          >
            <span>· {{$t('coordinationRoom.live')}}</span>
          </el-tooltip>
        </div>
        <RemoteNetWorkQuality @callBack="getRemoteNetWorkIconColor" v-show="showNetStatus" :remoteNetWorks="remoteNetWorks" style="margin-top:4px;"></RemoteNetWorkQuality>
        <slot name="topLeftSolt">
          <!-- 外层div左浮 -->
        </slot>
      </div>
    </div>
    <canvas class="hide" :ref="singChannel.id"></canvas>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Bus from '@/assets/js/bus.js'
import OperateButton from '@/components/common/operateButton.vue'
import MicPhone from '@/components/common/micphone.vue'
import VoIP from '@/components/common/voip.vue'
import RemoteNetWorkQuality from '@/components/common/remoteNetWork.vue'
import VideoControl from '@/components/common/videoControl.vue'
import { changeNickname, updateAmSourceName } from '@/assets/js/utils.js'
export default {
  components: {
    OperateButton,
    VoIP,
    MicPhone,
    RemoteNetWorkQuality,
    VideoControl
  },
  props: {
    modeKey: String,
    remoteNetWorks: {
      type: Object,
      default: () => {
        return {}
      }
    },
    privateInfo: {
      type: Array,
      default: () => {
        return []
      }
    },
    nickName: String,
    tagName: String,
    organization: String,
    netStatus: Number,
    editNickName: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    userInfo: {
      type: Object,
      default () {
        return {}
      }
    },
    lockName: {
      type: Boolean,
      default () {
        return false
      }
    },
    singChannel: {
      type: Object,
      default: function () {
        return {}
      }
    },
    title: {
      type: String,
      default: null
    },
    groupPartyCode: {
      type: String,
      default: ''
    },
    isallScrren: {
      type: Boolean,
      default: true
    },
    theme: {
      type: String,
      validator: function (value) {
        if (['success', 'danger'].includes(value)) {
          return value
        }
        return ''
      }
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    volume: {
      type: Object,
      default: function () {
        return {
          level: 0
        }
      }
    },
    outputRtilCode: {
      type: String,
      default: ''
    },
    video: {
      type: Boolean,
      default: true
    },
    coordRoomStatus: {
      // 协调室页面的与会者列表mic，offAir , onAir,muted,talkBack 状态
      // 评论员页面只有onAir 状态
      type: Boolean,
      default: false
    },
    muteAudio: {
      // 是否默认静音
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    notJoinR: {
      type: Boolean,
      default: false
    },
    onAir: {
      type: Boolean,
      default: null
    },
    isTalkBack: {
      type: Boolean,
      default: false
    },
    talkType: {
      type: String,
      default: ''
    },
    blockVideo: {
      // 是否默认关闭摄像头
      type: Boolean,
      default: false
    },
    blockAudio: {
      // 是否默认静音
      type: Boolean,
      default: false
    },
    controlMute: {
      // 用于单独控制静音
      default: ''
    },
    notShowPraviteBg: {
      // 直接控制不显示私聊背景优先级最大
      type: Boolean,
      default: false
    },
    asRoleList: {
      type: Array,
      default: () => {
        return []
      }
    },
    operateList: {
      type: Array,
      default: () => {
        return []
      }
    },
    buttonStatus: {
      default: ''
    },
    showMuteIcons: {
      type: Boolean,
      default: true
    },
    currentRole: {
      type: String,
      default: ''
    },
    nameColor: {
      type: String,
      default: ''
    },
    liveInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    showOperateBox: {
      type: Boolean,
      default: true
    },
    selectRid: {
      type: String,
      default: ''
    },
    outputSourceType: {
      type: String,
      default: () => {
        return ''
      }
    },
    rtc: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isPip: {
      type: Boolean,
      default: false
    },
    videoControl: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    isPrivate: {
      type: Boolean,
      default: false
    },
    isTalkback: {
      type: Boolean,
      default: false
    },
    talkBackType: {
      type: String,
      default: ''
    },
    userNewFullScreen: {
      type: Boolean,
      default: false
    },
    showVideoIcon: {
      type: Boolean,
      default: true
    },
    pgmMute: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      pipStatusInfo: {
        onAir: {
          text: 'ON Air',
          color: 'white'
        },
        OffAir: {
          text: 'OFF',
          color: 'white'
        },
        2: {
          text: 'Preparing(5 min)',
          color: 'white'
        },
        3: {
          text: 'Preparing(5 min)',
          color: 'white'
        }
      },
      remoteNetWorkIconColor: '',
      showEditInput: false,
      name: '',
      redTip: '',
      nameAjax: false,
      isTvuOne: false,
      showVideoBottom: false,
      praivateInfoForVolume: {},
      lastAudioOperationTime: 0, // 用来显示快速点击的问题
      lastVideoOperationTime: 0, // 用来显示快速点击的问题
      initVideoPopup: true,
      initAudioPopup: true,
      loadVideoTrack: false,
      audioLevel: 100,
      isFullscreen: false
    }
  },
  created () {
    Bus.$on('initVoipInfo', (val) => {
      val.forEach((item) => {
        if (item.rtilCode == this.singChannel.id) {
          this.selectRid = item.receiverId
        }
      })
    })
    // 处理common party当有私聊的时候声音改变的问题
    Bus.$on('startPrivateConversation', (v) => {
      if (
        this.$route.name != 'party' &&
        this.$route.name != 'partyParticipate'
      ) {
        return
      }

      const { rtilCode, crewRtilCode } = v
      if (
        this.userInfo.rtilCode == rtilCode ||
        this.userInfo.rtilCode == crewRtilCode
      ) {
        if (
          this.singChannel.id != rtilCode &&
          this.singChannel.id != crewRtilCode
        ) {
          // host没有ref(0-100)
          if (this.singChannel.audioTrack) {
            // const currentLevel = this.singChannel.audioTrack.getVolumeLevel() //注意获取值的范围是[0,1]
            this.singChannel.audioTrack.setVolume(50) // 注意setVloume时发送的音量范围是[0-100]，私聊时声音减半
            this.$refs.videoControl.changeVolumeVal(50)
          }
        }
      }
    })
    Bus.$on('cancelPrivateConversation', (v) => {
      const _this = this
      const { rtilCode, crewRtilCode } = v
      if (
        _this.userInfo.rtilCode == rtilCode ||
        _this.userInfo.rtilCode == crewRtilCode
      ) {
        if (
          _this.singChannel.id != rtilCode &&
          _this.singChannel.id != crewRtilCode
        ) {
          if (_this.singChannel.audioTrack) {
            this.singChannel.audioTrack.setVolume(100)
            this.$refs.videoControl.changeVolumeVal(100)
          }
        }
      }
      // 恢复视频声音
      if (
        this.userInfo.rtilCode != rtilCode &&
        this.userInfo.rtilCode != crewRtilCode
      ) {
        if (
          this.singChannel.id == rtilCode ||
          this.singChannel.id == crewRtilCode
        ) {
          // 对于正在私聊的人的视频要静音不能被听见
          if (this.singChannel.audioTrack && !this.isMute) {
            this.rtc.play(this.singChannel.audioTrack, '', { type: 'audio' })
          }
        }
      }
    })
    Bus.$on('startVoIP', (val) => {
      if (
        val.rtilCode &&
        val.rtilCode == this.singChannel.id &&
        this.$refs.voip
      ) {
        this.$refs.voip.changeSourceId(val)
        if (val.rtilCode == this.userInfo.rtilCode) {
          this.Webrtc.init(
            this.State.config.urlInfo.webRtc,
            () => {
              this.$refs.voip.startVoIP()
            },
            { loginName: val.sourceId }
          )
        } else {
          this.$refs.voip.changeVoIPStatus(1)
        }
      }
    })

    Bus.$on('stopVoIP', (val) => {
      if (val.rtilCode == this.singChannel.id && this.$refs.voip) {
        if (val.rtilCode == this.userInfo.rtilCode) {
          this.$refs.voip.stopVoIP()
          // 停掉之前的ws，因为sourceId会变
          this.Webrtc.websocket.sock.close()
        } else {
          this.$refs.voip.changeVoIPStatus(0)
        }
        this.$refs.voip.changeSourceId('')
      }
    })
  },
  computed: {
    ...mapState({
      State: (state) => state,
      isMute: (state) => state.isMute,
      praivateInfo: (state) => state.praivateInfo,
      Webrtc: (state) => state.webrtc,
      autoPlayFailed: (state) => state.autoPlayFailed,
      eventInfo: state => state.eventInfo
    }),
    talkBackText () {
      switch (this.talkType) {
        case 'callAll':
          return this.$t('coordinationParticipant.talkBack');
        case 'callHost':
          return this.$t('coordinationParticipant.talkHostBack');
        case 'callPip':
          return this.$t('coordinationParticipant.talkPipBack');
        default:
          return '';
      }
    },
    showNetStatus () {
      let flag = false
      if (
        !this.title
      ) {
        flag = false
      } else if (this.remoteNetWorkIconColor === 'Orange' || this.remoteNetWorkIconColor === 'Red') {
        flag = true
      }
      return flag
    },
    showVideoBg () {
      let flag = false
      if (this.blockVideo && !this.isTvuOne) {
        flag = true
      }
      if (
        (this.title == 'PGM' || this.title === 'VFB') &&
        this.outputSourceType != 'Participant'
      ) {
        // 通过R那边推送过来的流，在离开时会mute掉video和audio，所以忽略背景显示
        flag = false
      }
      return flag
    },
    muteAudioIcon () {
      let flag = false
      if ((this.muteAudio || this.blockAudio) && !this.isTvuOne) {
        flag = true
      }
      return flag
    },
    muteVideoIcon () {
      let flag = false
      if ((!this.video || this.blockVideo) && !this.isTvuOne) {
        flag = true
      }
      return flag
    },
    editTitle () {
      let flag = false
      if (
        this.userInfo.managerRtils &&
        this.userInfo.managerRtils.includes(this.userInfo.rtilCode)
      ) {
        // host可以修改所有人
        flag = true
      }
      if (
        this.singChannel.id == this.userInfo.rtilCode &&
        this.userInfo.managerRtils &&
        !this.userInfo.managerRtils.includes(this.userInfo.rtilCode) &&
        !this.lockName
      ) {
        // 与会者只能修改自己的rename，并且在party的lockName没有锁住的情况下
        flag = true
      }
      if (this.isTvuOne || !this.editNickName) flag = false
      return flag
    },
    isSelfAllScreen () {
      return this.isallScrren
    },
    showPrivateBg () {
      let flag = false
      if (this.notShowPraviteBg) flag = false
      return flag
    },
    privateEach () {
      if (!this.userInfo.rtilCode) return false
      const bg = false
      return bg
    },
    judgeShowVoIP () {
      if (
        this.title != 'PGM' &&
        this.title != 'Share' &&
        this.title != 'VFB' &&
        this.selectRid &&
        !this.selectRid.includes('ffff') &&
        this.singChannel.tid &&
        this.singChannel.tid.length != 16 &&
        this.singChannel.tid.length != 15
      ) {
        return true
      } else {
        return false
      }
    },
    addPipClass () {
      let _str = ''
      if (this.title != 'PGM' && this.title != 'VFB' && this.title != 'Share') {
        _str = 'pip'
      }
      return _str
    },
    showTips () {
      let showIt = false
      if (this.singChannel.id == this.userInfo.rtilCode && this.State.initCamera) {
        showIt = true
      }
      return showIt
    }
  },
  methods: {
    setSpeakerAttendUser () {
      if (this.singChannel.audioTrack && this.singChannel.id) {
        this.singChannel.audioTrack.setPlaybackDevice(localStorage.getItem('speakerId'))
        this.singChannel.audioTrack.play()
      }
    },
    rejoinR () {
      this.$emit('reJoinR')
    },
    drawFrame () {
      const canvas = this.$refs[this.singChannel.id]
      const context = canvas.getContext('2d')
      context.drawImage(this.$refs.videoTest.children[0].children[0], 0, 0, 640, 360)
      setTimeout(() => {
        requestAnimationFrame(this.drawFrame);
      }, 5000)
    },
    operateCallback (obj) {
      const { type, rtilCode } = obj
      if (type === 'fullScreen') {
        if (rtilCode == this.userInfo.rtilCode) {
          const moduleName = this.$route.name
          if (moduleName === 'coordinator') {
            this.$emit('callback', obj)
          } else {
            // 为了找到SDK自动创建的video
            // const element = document.getElementById('local_stream').children[0].children[0].requestFullscreen()
          }
        } else {
          if (this.$refs.videoTest.children[0]) {
            this.$refs.videoTest.children[0].children[0].requestFullscreen()
          }
        }
      } else {
        this.$emit('callback', obj)
      }
    },
    getRemoteNetWorkIconColor (remoteNetWorkIconColor) {
      this.remoteNetWorkIconColor = remoteNetWorkIconColor
    },
    controlCallback (info) {
      const { type, val, newType } = info
      if (type === 'fullScreen') {
        if (this.userNewFullScreen) {
          val ? this.isFullscreen = true : this.isFullscreen = false
          this.$emit('callback', { type: 'fullScreen', val })
        } else {
          if (val) {
            this.$refs.videoCon.requestFullscreen()
          } else {
            if (document.fullscreenElement) {
              document.exitFullscreen()
            }
          }
        }
      } else if (type === 'controlAudio') {
        this.audioLevel = val
        if (newType === 'control') {
          if (val === 0) {
          // 证明这个是mute的
            this.$emit('callback', { type: 'muteAudio', id: this.singChannel.id, isControlMute: true })
          } else {
            this.$emit('callback', { type: 'muteAudio', id: this.singChannel.id, isControlMute: false })
          }
        }
      }
    },
    setVideoAndAudio (type) {
      if (
        this.singChannel.id == this.userInfo.rtilCode ||
        this.userInfo.managerRtils.includes(this.userInfo.rtilCode)
      ) {
        // 防止快速点击
        const timeStamp = Date.now()
        if (type === 'muteAudio') {
          if (timeStamp - this.lastAudioOperationTime < 1000) {
            if (this.initAudioPopup && this.$route.name != 'coordination') {
              this.initAudioPopup = !this.initAudioPopup
              this.$message.warning('Please do not operate frequently')
            }
            return
          } else {
            this.initAudioPopup = true
          }
        }

        if (type === 'muteVideo') {
          if (timeStamp - this.lastVideoOperationTime < 1000) {
            if (this.initVideoPopup && this.$route.name != 'coordination') {
              this.initVideoPopup = !this.initVideoPopup
              this.$message.warning('Please do not operate frequently')
            }
            return
          } else {
            this.initVideoPopup = true
          }
        }
        if (type === 'muteAudio') {
          this.lastAudioOperationTime = timeStamp
        }
        if (type === 'muteVideo') {
          this.lastVideoOperationTime = timeStamp
        }
        this.$emit('callback', { type, id: this.singChannel.id })
      }
    },
    editName (str) {
      this.showEditInput = true
      this.name = this.nickName || this.title
      this.redTip = ''
    },
    saveName () {
      if (!this.name.replace(/(^\s*)|(\s*$)/g, '')) {
        this.name = this.nickName || this.title
        this.showEditInput = false
        this.$message.warning(this.$t('coordinationRoom.nameEmpty'))
        return
      }
      if (this.nameAjax) return
      this.nameAjax = true
      const params = {
        senderRtilCode: this.userInfo.rtilCode,
        toRtilCode: this.singChannel.id,
        nickName: this.name,
        partyCode: this.userInfo.joinCode
      }
      const updateParams = {
        coordinationPartyCode: this.groupPartyCode,
        partyCode: this.userInfo.realPartyCode,
        rtilCode: this.userInfo.rtilCode,
        rtilName: this.name.replace(/(^\s*)|(\s*$)/g, ''), // 去掉首尾空格
        eventId: this.eventInfo.id,
        toRtilCode: this.singChannel.id
      }
      changeNickname(params, (res) => {
        if (res.data.errorCode === '0x0') {
          this.nameAjax = false
          this.showEditInput = false
          if (this.userInfo.rtilCode != this.singChannel.id) {
            // host 更改与会者的名字
            this.$message.success(this.$t('HostCommon.renameParticipantSuceess'))
          } else {
            this.$message.success(this.$t('HostCommon.reNameSuccess'))
          }
          updateAmSourceName(updateParams)
        } else {
          this.redTip = res.data.errorCode
        }
      })
    },
    checkAll (obj) {
      Bus.$emit('checkAll', obj)
    }
  },
  watch: {
    name (val) {
      this.name = this.name.replace(
        /\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g,
        ''
      )
    },
    showEditInput (val) {
      if (val) {
        this.$nextTick(() => {
          // 自动获取焦点 element组件autofocus失效
          this.$refs.name.$refs.input.focus()
          // event.currentTarget.select();
          this.$refs.name.$refs.input.select()
        })
      }
    },
    isMute (val) {
      if (!this.singChannel.id || !this.singChannel.audioTrack) return
      // if (this.singChannel.id == this.outputRtilCode) return
      if (this.controlMute !== '') {
        if (this.controlMute) {
          this.rtc.stop(this.singChannel.audioTrack)
        } else {
          this.rtc.play(this.singChannel.audioTrack, '', { type: 'audio' })
        }
      } else {
        if (this.showPrivateBg) {
          this.rtc.stop(this.singChannel.audioTrack)
        } else {
          if (this.isMute) {
            this.rtc.stop(this.singChannel.audioTrack)
          } else {
            // commom party模式不需要听见host自己的声音
            if (
              this.$route.name === 'party' &&
              this.userInfo.rtilCode == this.singChannel.id
            ) {
              return
            }
            this.rtc.play(this.singChannel.audioTrack, '', { type: 'audio' })
          }
        }
      }
    },
    controlMute (val) {
      if (this.singChannel.audioTrack) {
        if (this.controlMute !== '') {
          if (this.controlMute) {
            this.rtc.stop(this.singChannel.audioTrack)
          } else {
            this.rtc.play(this.singChannel.audioTrack, '', { type: 'audio' })
          }
        } else {
          if (this.isMute) {
            this.rtc.stop(this.singChannel.audioTrack)
          } else {
            this.rtx.play(this.singChannel.audioTrack, '', { type: 'audio' })
          }
        }
      }
    },
    singChannel: {
      immediate: true,
      handler (newValue, oldValue) {
        if (!newValue) {
          return
        }
        // const _this = this
        this.$nextTick(() => {
          // 使用对应speaker去播
          if (this.singChannel.audioTrack) {
            this.setSpeakerAttendUser()
          }
          if (
            ((!newValue.videoTrack && !newValue.audioTrack) || !newValue.id) &&
            !newValue.clearVideo
          ) {
            return
          }

          if (newValue.videoTrack) {
            let palyerConfig = ''
            if (newValue.shareCode == newValue.id) {
              palyerConfig = { fit: 'contain' }
            }
            this.rtc.play(
              newValue.videoTrack,
              palyerConfig,
              { videoBox: this.$refs.videoTest, type: 'video' },
              () => {
                // 禁用掉video标签右键 oncontextmenu="return false;"
                // const x = _self.$refs.videoTest.children[0].children[0].setAttribute('oncontextmenu', 'return false')
                this.loadVideoTrack = true
                // const video = _this.$refs.videoTest.children[0].children[0]
                // video.addEventListener('play', function () {
                //   _this.drawFrame();
                // })
              }
            )
          }
          if (oldValue && oldValue.audioTrack) {
            this.rtc.stop(oldValue.audioTrack)
          }
          if (newValue.audioTrack) {
            if (this.controlMute !== '') {
              if (this.controlMute) {
                this.rtc.stop(newValue.audioTrack)
              } else {
                this.rtc.play(newValue.audioTrack, '', { type: 'audio' })
              }
            } else {
              if (this.isMute) {
                this.rtc.stop(newValue.audioTrack)
              } else {
                this.rtc.play &&
                  this.rtc.play(newValue.audioTrack, '', { type: 'audio' })
              }
            }
          }

          if (newValue.audioTrack) {
            // 改用新版本后每次对端禁用麦克风在打开都会是一个新的track
            if (!this.isMute) {
              // 有私聊判断是否是私聊的与会者
              if (this.praivateInfo.rtilCode) {
                if (
                  this.userInfo.rtilCode != this.praivateInfo.rtilCode &&
                  this.userInfo.rtilCode != this.praivateInfo.crewRtilCode
                ) {
                  if (
                    this.singChannel.id != this.praivateInfo.rtilCode &&
                    this.singChannel.id != this.praivateInfo.crewRtilCode
                  ) {
                    this.rtc.play(newValue.audioTrack, '', { type: 'audio' })
                  } else {
                    this.rtc.stop(newValue.audioTrack)
                  }
                }
              } else {
                this.rtc.play &&
                  this.rtc.play(newValue.audioTrack, '', { type: 'audio' })
              }
              if (isNaN(this.audioLevel)) return
              newValue.audioTrack.setVolume(this.audioLevel)
            }
          }
        })
      }
    },
    outputRtilCode (val) {
      // 判断当前是否有在私聊
      if (JSON.stringify(this.State.praivateInfo) != '{}') {
        const userRtilCode = this.State.currentRtilCode
        if (
          userRtilCode == this.State.praivateInfo.crewRtilCode ||
          userRtilCode == this.State.praivateInfo.rtilCode
        ) {
          if (val == this.singChannel.id) {
            this.singChannel.audioTrack &&
              this.singChannel.audioTrack.setVolume(50)
            this.$refs.videoControl.changeVolumeVal(50)
          }
        }
      }
    },
    praivateInfo: {
      deep: true,
      handler (val) {
        const userRtilCode = this.State.currentRtilCode
        if (JSON.stringify(val) != '{}') {
          // 根据私聊信息去改变正在私聊的两个人的vfb声音
          if (
            userRtilCode == val.crewRtilCode ||
            userRtilCode == val.rtilCode
          ) {
            this.praivateInfoForVolume = val
            if (this.outputRtilCode) {
              // 只要当前有在私聊就把vfb视频声音降低50%
              if (this.outputRtilCode == this.singChannel.id) {
                this.singChannel.audioTrack &&
                  this.singChannel.audioTrack.setVolume(50)
                this.$refs.videoControl.changeVolumeVal(50)
              }
            }
          }
        } else {
          if (
            this.outputRtilCode &&
            (this.praivateInfoForVolume.crewRtilCode == userRtilCode ||
              userRtilCode == this.praivateInfoForVolume.rtilCode)
          ) {
            this.praivateInfoForVolume = {}
            // 结束私聊的时候根据当前vfb声音分别处理
            if (this.outputRtilCode == this.singChannel.id) {
              this.singChannel.audioTrack &&
                this.singChannel.audioTrack.setVolume(100)
            }
          }
        }
      }
    },
    muteAudio (val) {
      if (this.isMute || this.controlMute) return
      if (this.showPrivateBg && !val) return
      if (this.singChannel.audioTrack) {
        if (val) {
          this.rtc.stop(this.singChannel.audioTrack)
        } else {
          this.rtc.play(this.singChannel.audioTrack, '', { type: 'audio' })
        }
      }
    },
    video (val) {
      this.hideVideoBg = !val
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.singChannel.audioTrack) {
        if (this.controlMute !== '') {
          if (this.controlMute) {
            this.rtc.stop(this.singChannel.audioTrack)
          } else {
            this.rtc.play(this.singChannel.audioTrack, '', { type: 'audio' })
          }
        } else {
          if (this.isMute) {
            this.rtc.stop(this.singChannel.audioTrack)
          } else {
            this.rtc.play &&
              this.rtc.play(this.singChannel.audioTrack, '', { type: 'audio' })
          }
        }
      }
      this.State.v3List &&
        this.State.v3List.forEach((item) => {
          if (this.singChannel.id && item.rtilCode == this.singChannel.id) {
            this.isTvuOne = true
          }
        })
    })
  }
}
</script>

<style lang="less" scoped>
#local_stream,
#local_stream_extral {
  width: 100%;
  height: 100%;
  /deep/ video {
    position: relative !important;
  }
}
.container {
  width: 100%;
  // margin-top: -1px;
  margin-right: -1px;
  position: relative;
  &.fullScreen {
    position: fixed;
    height: calc(100vh - 63px);
    width: 100%;
    z-index: 2;
    top: 0;
    left: 0;
    .box {
      height: 100%;
      padding: 0;
      .video-con {
        max-height: none!important;
      }
    }
  }
  &.down {
    height: calc(100vh);
    .box {
      height: 100vh!important;
    }
  }
  .el-divider--vertical {
    width: 1px;
    opacity: 0.2;
  }
  .conversationBg {
    width: 100%;
    height: 100%;
    position: absolute;
    background: #333339;
    text-align: center;
    color: #666666;
    display: flex;
    align-items: center;
    z-index: 1;
    justify-content: center;
  }
  &.layout-5 {
    .box {
      padding-bottom: 57%;
    }
  }
  &.layout-3 {
    min-height: 10px;
    .box {
      padding-bottom: 56%;
    }
  }
  .nickNameBox {
    display: none;
  }
  .muted {
    position: absolute;
    top: 10px;
    right: 20px;
    width: 32px;
    height: 32px;
    background-color: #fff;
    z-index: 20;
    &.no-audio {
      background-color: red;
    }
  }
  .box {
    width: 100%; /*防止矩形被里面的内容撑出多余的高度*/
    height: 0px;
    // overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    vertical-align: middle;
    .videoBg {
      width: 100%;
      height: 100%;
      position: absolute;
      background: #333339;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 16px;
    }

    .video-con {
      border: 1px solid #303030;
      background-repeat: no-repeat;
      background-size: contain;
      // font-size: 0;
      width: calc(100% - 2px);
      height: calc(100% - 2px);
      position: absolute;
      color: #666;
      z-index: 1;
      overflow: hidden;
      .defaultText {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 24px;
        width: 100%;
        text-align: center;
      }
      &:hover {
        ::v-deep #videoControlBox {
          opacity: 1;
        }
      }
      &.isPip:hover {
        ::v-deep #videoControlBox {
          opacity: 1;
          bottom: 30px;
          background: linear-gradient(360deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.5), transparent);
        }
      }
      .videoBox {
        width: 100%;
        height: 100%;
      }
      .operateBox {
        height: 30px;
        line-height: 30px;
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 3;
        background: rgba(0, 0, 0, 0.5);
        .icon-a-talkbackoff {
          background: #27272a;
          font-size: 16px;
          border-radius: 4px;
          &.privateIcon {
            margin-left: -6px;
            background: #33AB4F;
          }
        }
        .iconBox {
          padding-left: 8px;
          float: left;
          line-height: 30px;
          .buttons {
            width: 0px;
            .lds-ripple {
              display: inline-block;
              z-index: 10;
              position: relative;
              width: 46px;
              height: 54px;
              top: -10px;
              left: -19px;
              .icon-toolbartalkbackon {
                font-size: 28px;
                color: #33AB4F;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
              .icon-talkHostOff{
                font-size: 20px;
                color: #33AB4F;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
              .icon-talkPipOff{
                font-size: 20px;
                color: #33AB4F;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
              div {
                position: absolute;
                border: 4px solid #33AB4F;
                margin-left: -2px;
                opacity: 1;
                border-radius: 50%;
                -webkit-animation: lds-ripple 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
                animation: lds-ripple 1.5s cubic-bezier(0, 0.2, 0.8, 1) infinite;
                &:nth-child(2) {
                  -webkit-animation-delay: -1s;
                  animation-delay: -1s;
                }
              }
              @keyframes lds-ripple {
                0% {
                  top: 22px;
                  left: 22px;
                  width: 0;
                  height: 0;
                  opacity: 1;
                }
                100% {
                  top: 0px;
                  left: 0px;
                  width: 44px;
                  height: 44px;
                  opacity: 0;
                }
              }
            }
          }
          i {
            color: #fff;
            font-size: 19px;
            margin-right: 5px;
            &.green {
              color: #00cc66;
            }
            &.el-icon-arrow-down,
            &.el-icon-arrow-up {
              font-size: 12px;
              margin-left: 0;
            }
          }
          .micphone {
            margin-right: 1px;
            width: 20px;
            display: inline-block;
            text-align: center;
          }
          .muteAudioImg {
            width: 22px;
            height: 21px;
            position: relative;
            top: 6px;
            margin-right: -1px;
          }
          .muteVideoImg {
            width: 24px;
            height: 21px;
            position: relative;
            top: 6px;
            margin-right: 1px;
          }
          .camera {
            margin-right: 1px;
            font-size: 26px;
            position: relative;
          }
          .setRole {
            display: inline-block;
          }
        }
      }
      .disconnected{
        z-index: 111;
        position: absolute;
        background: #000000CC;
        top: 50%;
        width: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #f1f1f1;
        height: 100%;
        .notJoinRContent{
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          span{
            display: block;
            text-align: center;
            white-space: pre-line;
            color: #FFFFFF;
            margin-bottom: 10px;
            font-size: 14px;
          }
          .rejoinRButton{
            background: #33AB4F;
            color: #FFFFFF;
            border-radius: 4px;
            border:unset;
          }
          /deep/.rejoinRButton .el-icon-loading {
            position: absolute;
            left: 78%;
          }
          /deep/.rejoinRButton span{
            margin-right: 10px;
          }
        }
      }
      .initCameraTips {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #f1f1f1;
      }
      .infoBox {
        display: flex;
        align-items: center;
        width: 50%;
        flex-grow: 1;
        justify-content: flex-end;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 30px;
        height: 30px;
        text-align: right;
        padding-right: 5px;
        &.success {
          background-color: #71ba2a;
        }
        &.danger {
          text-align: left;
          .title {
            background-color: #f6445a;
            .titleBox {
              .name {
                display: inline-block;
                background-color: #f6445a;
                width: auto;
                padding: 0 8px;
              }
              .rename {
                display: none;
              }
            }
          }
        }
        &.editTitle {
          .red {
            color: #ff4b4b;
            font-size: 14px;
          }
          .title {
            .titleBox {
              &:hover {
                .name {
                  display: none;
                }
                .rename {
                  display: block;
                }
              }
            }
            /deep/.el-input {
              &.red {
                .el-input__inner {
                  border: 1px solid #ff4b4b;
                }
              }
              .el-input__inner {
                height: 24px;
                line-height: 24px;
                border: none;
                background: #444;
                color: #fff;
              }
            }
          }
        }
        .title {
          font-size: 14px;
          margin-top: 2px;
          color: #fff;
          display: flex;
          justify-content: flex-end;
          // max-width: calc(100% - 23px);
          .appendTitle {
            float: left;
          }
          &.isMe {
            .titleBox {
              position: relative;
              width: 100%;
              .name {
                width: calc(100% - 32px);
                padding-right: 32px;
              }
              .meTitle {
                position: absolute;
                right: -2px;
                top: 0;
                font-size: 14px;
                font-weight: 600;
                color: #fff;
              }
            }
          }
          .titleBox {
            max-width: 100%;
            float: left;
            &.green {
              color: #33ab4f;
              .meTitle {
                color: #33ab4f;
              }
            }
            .name {
              float: left;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              width: 100%;
            }
          }
          .iconfont {
            font-weight: normal;
          }
          &.success {
            background-color: #71ba2a;
          }
          &.danger {
            background-color: #f6445a;
          }
          .rename {
            // display: none;
            cursor: pointer;
            max-width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            i {
              margin-right: 5px;
              color: #00d000;
            }
          }
        }
        &.active {
          .name,
          .meTitle {
            color: #f6445a !important;
          }
        }
        &.earphone {
          .title {
            max-width: calc(100% - 38px);
          }
        }
      }
    }
    .vfbTitle {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      &.danger {
        color: #fff;
        text-align: left;
        display: inline-block;
        background-color: #f6445a;
        width: auto;
        span {
          padding: 5px 8px;
        }
      }
    }
    .topLeft {
      position: absolute;
      z-index: 1;
      top: 1px;
      left: 1px;
      > div {
        margin: 0 3px;
        float: left;
      }
      .networksQuality {
        padding: 2px 0;
        span {
          display: inline-block;
          width: 2px;
          height: 6px;
          border: 1px solid #f6445a;
          margin-right: 2px;
          &.bg {
            background: #f6445a;
          }
          &.level2 {
            height: 10px;
          }
          &.level3 {
            height: 14px;
          }
        }
      }
      .liveSign {
        margin-left: 0;
        span {
          display: block;
          background: linear-gradient(225deg, #f45d24 0%, #ea2428 100%);
          padding: 0px 8px 4px 6px;
        }
      }
      .status{
        span {
          font-size: 12px;
          display: block;
          cursor: default;
          padding: 6px;
          border-radius: 4px;
          margin-top: 2px;
        }
        .status-off-air{
          background: #595959;
        }
        .status-on-air{
          background: #F6445A;
        }
        .status-muted{
          background: #595959;
          color: #F6445A;
        }
        .talkBackContent span{
          font-size: 12px;
          display: block;
          cursor: default;
          background: #33AB4F;
          padding: 6px;
          border-radius: 4px;
          margin-top: 2px;
        }
      }
    }
    .bottom-video-bar-wrapper {
      width: calc(100% - 2px);
      height: 30px;
      background: #33ab4f;
      position: absolute;
      bottom: 0;
      left: 1px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 20px;
      z-index: 100;
      .bottom-box-left {
        width: 85%;
        position: relative;
        top: -2px;
        .shareNickName {
          max-width: calc(100% - 80px);
          display: inline-block;
          top: 4px;
          position: relative;
        }
      }
    }
    .allScreenIcon:before {
      content: '\e658';
      font-size: 20px;
    }
  }
  &.share {
    z-index: 1005;
    .box {
      .video-con {
        .infoBox {
          bottom: 18%;
        }
      }
    }
  }
  &.smallIcon {
    .box {
      .video-con {
        .operateBox {
          min-height: 24px;
          .iconBox {
            line-height: 24px;
            .el-icon-arrow-down,
            .el-icon-arrow-up {
              font-size: 12px;
            }
            i {
              font-size: 18px;
              margin-right: 2px;
              &.green {
                color: #00cc66;
              }
            }
            /deep/.operateBtn {
              .icon-universal_role,
              .icon-universal_interviewer,
              .icon-universal_speaker {
                font-size: 18px;
              }
            }
            .muteAudioImg,
            .muteVideoImg {
              width: 18px;
              height: 18px;
              margin-right: 0;
            }
            .muteVideoImg {
              position: relative;
              top: 2px;
            }
          }
          .infoBox {
            line-height: 24px;
            height: 24px;
            width: calc(100% - 90px);
            .title {
              font-size: 12px;
              max-width: calc(100% - 35px);
            }
            /deep/.operateBtn {
              .icon {
                font-size: 12px;
              }
            }
          }
          /deep/.selectSource {
            .el-select {
              .el-input__inner {
                height: 22px;
                line-height: 22px;
              }
            }
            .el-button {
              padding: 5px 9px;
            }
          }
        }
      }
    }
  }
}
.allScreenContainer {
  float: left;
  height: 84%;
  // width: 90% !important;
  width: calc(100vw - 62px) !important;
  margin-bottom: 6px;
  left: 50%;
  transform: translate(-50%, 0);
  position: absolute;
  // z-index: 99999;
  background: #141417;
  .box {
    padding-bottom: 0;
    height: 100%;
  }
  .title {
    display: none !important;
  }
}
</style>
