import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookie from 'js-cookie'
import elementEN from 'element-ui/lib/locale/lang/en'
import elementZH from 'element-ui/lib/locale/lang/zh-CN'
import customZH from './zh'
import customEN from './en'

Vue.use(VueI18n)

let locale = Cookie.get('userLanguage') === 'ru' ? 'en' : Cookie.get('userLanguage') || navigator.language.substring(0, 2) || navigator.userLanguage || 'en'

if (domain.includes('tsnetech.cn')) {
  locale = 'zh'
}
export default new VueI18n({
  locale,
  messages: {
    en: {
      ...elementEN,
      ...customEN
    },
    zh: {
      ...elementZH,
      ...customZH
    }
  },
  silentTranslationWarn: true
})
