<template>
  <div class="noDataBox">
    <div v-if="type == 'noFile'" class="noFile">
      <h3>{{$t('leftPane.supportMov')}}</h3>
      <span>{{$t('leftPane.clickBtn')}}</span>
      <span>{{$t('leftPane.fileUpload')}}</span>
      <upload-file type="btn"></upload-file>
    </div>
  </div>
</template>
<script>
import UploadFile from '@/components/uploadFile'
export default {
  props: {
    type: String
  },
  data () {
    return {}
  },
  components: {
    UploadFile
  }
}
</script>

<style lang="less" scoped>
.noDataBox {
  width: 100%;
  height: auto;
  .noFile {
    text-align: center;
    padding: 20px 0;
    h3 {
      font-size: 18px;
      margin-bottom: 20px;
    }
    span {
      display: block;
      font-size: 14px;
      color: #999;
    }
  }
}
</style>
