<template>
  <!-- 显示远端用户网络质量 -->
  <div class="networksQuality">
    <span class="level1" :class="bgColor"></span>
    <span class="level2" :class="bgColor"></span>
    <span class="level3" :class="bgColor"></span>
  </div>
</template>

<script>
export default {
  props: {
    remoteNetWorks: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      // 网络质量
      remoteNetWorkAttrs: {},
      remoteNetWorkIconColor: '',
      netWork: {
        upLinkNetWork: '',
        downLinkNetWork: ''
      }
    }
  },
  computed: {
    bgColor () {
      if (this.remoteNetWorkIconColor === 'Green') {
        return 'bgGreen'
      } else if (this.remoteNetWorkIconColor === 'Orange') {
        return 'bgOrange'
      } else if (this.remoteNetWorkIconColor === 'Red') {
        return 'bgRed'
      } else {
        return ''
      }
    }
  },
  watch: {
    remoteNetWorks (newVal) {
      this.remoteNetWorkAttrs = this.remoteNetWorks
      if (this.remoteNetWorkAttrs.uplinkNetworkQuality < 3) {
        this.netWork.upLinkNetWork = 'Good'
      } else if (this.remoteNetWorkAttrs.uplinkNetworkQuality > 4) {
        this.netWork.upLinkNetWork = 'Poor'
      } else {
        this.netWork.upLinkNetWork = 'Mid'
      }

      if (this.remoteNetWorkAttrs.downlinkNetworkQuality < 3) {
        this.netWork.downLinkNetWork = 'Good'
      } else if (this.remoteNetWorkAttrs.downlinkNetworkQuality > 4) {
        this.netWork.downLinkNetWork = 'Poor'
      } else {
        this.netWork.downLinkNetWork = 'Mid'
      }
      this.dealRemoteNetWorkQuality(this.netWork)
      this.$emit('callBack', this.remoteNetWorkIconColor)
    }
  },
  created () {},
  methods: {
    dealRemoteNetWorkQuality (remoteNetWork) {
      if (remoteNetWork.upLinkNetWork === 'Good' && remoteNetWork.downLinkNetWork === 'Good') {
        this.remoteNetWorkIconColor = 'Green'
      } else if (remoteNetWork.upLinkNetWork === 'Good' && remoteNetWork.downLinkNetWork === 'Mid') {
        this.remoteNetWorkIconColor = 'Orange'
      } else if (remoteNetWork.upLinkNetWork === 'Mid' && remoteNetWork.downLinkNetWork === 'Mid') {
        this.remoteNetWorkIconColor = 'Orange'
      } else if (remoteNetWork.upLinkNetWork === 'Poor' || remoteNetWork.downLinkNetWork === 'Poor') {
        this.remoteNetWorkIconColor = 'Red'
      }
    }
  }
}
</script>

<style scoped lang="less">
  .networksQuality {
    display: flex;
    width: 20px;
    height: 20px;
    line-height: 20px;
    background-color: rgba(35, 35, 39, .7);
    border-radius: 3px;
    box-sizing: border-box;
    border: 1px solid #303035;
    align-items: flex-end;
    span {
      display: inline-block;
      width: 3px;
      height: 4px;
      margin-right: 2px;
      border-radius: 1px;
      &:nth-child(1) {
        margin-left: 2px;
      }
      &:nth-child(2) {
        height: 8px;
      }
      &:nth-child(3) {
        height: 12px;
      }
      &.bgRed {
        background-color: #F6445A;
      }
      &.bgOrange {
        background-color: #F5A623;
      }
      &.bgGreen {
        background-color: #33AB4F;
      }
    }
  }
</style>
