/* eslint-disable */
import { $http } from '@/assets/js/axiosExtend.js'
import { Message } from 'element-ui'
import Store from '@/store/index.js'

const messageTip = {
  removeParticipant: 'Removed successfully',
  startPrivateConversation: 'Create private conversation success',
  cancelPrivateConversation: 'The private conversation has been hung up.',
  setCrew: 'Authorize successfully',
  cancelCrew: 'Remove successfully'
}
const errorTip = {
  cancelCew: 'Stop last Crew failed.',
  82201102: 'Common party members out of limit'
}
const fn = function(params, tips = true) {
  return new Promise((resolve, reject) => {
    $http
      .post(`${Store.state.config.urlInfo.partyline}/partyline-backend/party/noLogin/command?key=566920`, params)
      .then((res) => {
        if (res.data.errorCode === '0x0') {
          if (tips && messageTip[params.module]) {
            Message({
              type: 'success',
              message: messageTip[params.module],
              offset: 50,
              showClose: true
            })
          }
        } else {
          if (errorTip[params.module] || errorTip[res.data.errorCode]) {
            Message({
              type: 'error',
              message: errorTip[params.module],
              offset: 50,
              showClose: true
            })
          }
        }
        resolve(res.data)
      })
      .catch(() => {
        reject('error')
      })
  })
}

export default {
  fn
}
