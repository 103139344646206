<template>
  <div class="deviceSelect">
    <el-select
      @visible-change="visibleChange"
      :class="{
        transformIcon: !isHost,
        openSelect: openSelect && !isHost
      }"
      v-model="deviceId"
      ref="deviceSelects"
      @change="handleAudioOrVideo"
      :popper-append-to-body="false"
    >
      <div class="titleBox">
        <span class="title">{{ title | showSelectTitle}}</span>
      </div>
      <el-option
        v-for="item in data"
        :key="item.deviceId"
        :label="item.label"
        :disabled="type === 'video' ? State.initCamera : false"
        :value="item.deviceId"
      >
      </el-option>
      <div class="audioSetting" v-if="type == 'audio' && $route.name=='coordinationParticipate'">
       <el-divider></el-divider>
        <div class="audioSettingPop" @click="showAudioSetting">
          <i class="iconfont icon-audioSetting"></i>
          <span>{{$t('coordinationParticipant.audioSetting')}}</span>
        </div>
      </div>
    </el-select>
    <AudioSettings
      ref="audioSetting"
      v-if="audioSettingsInfo.show"
      :info="audioSettingsInfo"
    />
  </div>
</template>
<script>
import { filter } from 'lodash'
import i18n from '@/lang/index.js'
import AudioSettings from '@/views/commentator/dialog/audioSetting'
import { mapState } from 'vuex'
export default {
  components: {
    AudioSettings
  },
  props: {
    title: String,
    type: {
      default: 'video',
      type: String
    },
    data: {
      default () {
        return []
      },
      type: Array
    },
    deviceSelectId: {
      default: '',
      type: String
    },
    isHost: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      State: state => state
    })
  },
  data () {
    return {
      deviceId: '',
      openSelect: false,
      audioSettingsInfo: {
        show: false
      }
    }
  },
  created () {
    this.deviceId = this.deviceSelectId
  },
  watch: {
    deviceSelectId (val) {
      // this.handleDefault()
      this.deviceId = this.deviceSelectId
    }
  },
  methods: {
    changeDeviceId (id) {
      this.deviceId = id
    },
    showAudioSetting () {
      this.audioSettingsInfo.show = true
      this.$refs.deviceSelects.blur();
    },
    visibleChange (flag) {
      this.openSelect = flag
    },
    handleDefault () {
      if (this.data.length > 0) {
        if (this.type === 'video') {
          const defaultId = localStorage.getItem('cameraId')
          if (
            defaultId &&
            filter(this.data, { deviceId: defaultId }).length > 0
          ) {
            this.deviceId = defaultId
          } else {
            this.deviceId = this.data[0].deviceId
          }
          if (this.data.length > 1) {
            this.handleAudioOrVideo()
          }
        } else if (this.type === 'audio') {
          const temp = filter(this.data, { deviceId: 'default' })
          const defaultId = this.type == 'audio' ? localStorage.getItem('microphoneId') : localStorage.getItem('speakerId')
          if (
            defaultId &&
            filter(this.data, { deviceId: defaultId }).length > 0
          ) {
            this.deviceId = defaultId
            if (this.data.length > 1) {
              this.handleAudioOrVideo()
            }
          } else {
            this.deviceId = temp.length > 0 ? 'default' : this.data[0].deviceId
          }
        }
      }
    },
    handleAudioOrVideo () {
      if (this.type == 'audio' || this.type == 'speaker') {
        this.$emit('updateDeviceId', this.type, this.deviceId)
      }
      this.$emit('changeDevice', {
        type: this.type,
        deviceId: this.deviceId
      })
    }
  },
  filters: {
    showSelectTitle (title) {
      if (!title) return ''
      if (title === 'Select a Camera') {
        return i18n.t('coordinationParticipant.selectCamera')
      } else if (title === 'Select a Microphone') {
        return i18n.t('coordinationParticipant.selectMicrophone')
      } else if (title == 'Select a Speaker') {
        return i18n.t('coordinationParticipant.selectSpeaker')
      } else {
        return title
      }
    }
  }
}
</script>
<style lang="less" scoped>
.deviceSelect {
  width: 15px;
  display: inline-block;
  text-align: left;
  margin-left: 5px;
  .el-select-dropdown .el-select-dropdown__item.selected:after {
  position: absolute;
  right: 20px;
  font-family: element-icons;
  content: '\e6da';
  font-size: 12px;
  font-weight: 700;
  color: #00d000;
  -webkit-font-smoothing: antialiased;
}

.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: rgba(0, 0, 0, .3);
}

    ::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 10px;
  }
  /deep/.el-popper {
    border: none;
    background: #28282e;
    margin-top: 0 !important;
  }
  /deep/.el-select {
    .audioSetting {
      margin-left: 4%;
      margin-right: 4%;
    .el-divider {
      background-color: #333339;
      margin-top: 10px;
      margin-bottom: 0px;
    }
    .audioSettingPop{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-left: 16px;
      padding-top: 10px;
      padding-bottom: 5px;
      cursor: pointer;
      span {
        margin-left: 4px;
      }
    }
  }
    .el-scrollbar__wrap {
      margin-top:9px;
      margin-bottom: -2px !important;
      margin-right: -6px !important;
    }
    width: 15px !important;
    margin-right: 10px !important;
    &.transformIcon {
      &.openSelect {
        .el-input {
          .el-select__caret {
            margin-top: 1px;
            transform: rotate(180deg);
          }
        }
      }
      .el-input {
        .el-select__caret {
          transform: rotateY(22.5deg);
          font-weight: bold;
          font-size: 18px;
        }
      }
      .el-select-dropdown {
        top: auto !important;
        bottom: 65px !important;
        height: fit-content;
      }
    }
    .el-input__suffix {
      right: -6px;
    }
    .el-input--suffix .el-input__inner {
      padding: 0;
      opacity: 0;
      background: none;
    }
    .el-input {
      .el-select__caret {
        color: #fff;
      }
    }
    .el-select-dropdown__item.hover,
    .el-select-dropdown__item:hover {
      background: rgba(0 , 0 , 0, .3);
    }
    .el-select-dropdown .el-select-dropdown__item.selected:after {
      right: auto !important;
      left: -5px !important;
      font-size: 18px;
      // right: -5px;
    }
    .el-select-dropdown {
      // left: auto!important;
      // right: 0!important;
      box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.3);
      .el-scrollbar {
        display: block !important;
      }
      .el-select-dropdown__empty {
        display: none !important;
      }
    }
    .el-select-dropdown__item {
      text-indent: 20px;
      color: #f1f1f1;

      // &:hover {
      //   color: #00cc66;
      // }
      &.selected {
        color: #00cc66;
        font-weight: 700;
      }
    }
    .el-select-dropdown__list {
      padding: 0;
    }
  }
}
.userSettingIcon {
  /deep/.el-select {
    .el-input__suffix {
      right: 0;
      top: 1px;
    }
    .el-input {
      .el-select__caret {
        color: #000;
      }
    }
  }
}
.titleBox {
  padding-left: 20px;
  .line {
    height: 1px;
    width: 100%;
    background: #909090;
    margin-left: -20px;
  }
  .title {
    font-size: 16px;
    color: #909090;
  }
  .default {
    white-space: nowrap;
    color: #00cc66;
    font-size: 16px;
    line-height: 30px;
    margin-bottom: 15px;
  }
}
</style>
