import Store from '@/store/index.js'
import Tool from '@/assets/js/utils.js'
import Cookies from 'js-cookie'

function loadSudoku () {
  const protocol = window.location.protocol
  if (Store.state.config.cc && Store.state.config.urlInfo.mediaServerUrl) {
    let serverUrl = Store.state.config.urlInfo.mediaServerUrl
    if (process.env.NODE_ENV === 'development') {
      serverUrl = 'cc-ms-dev.tvunetworks.com/public/share/m2.1'
    }
    window.serviceList = {
      mediaServerUrl: `${protocol}//${serverUrl}`,
      mediaApiUrl: `${protocol}//${Store.state.config.urlInfo.shareServerUrl}/media`,
      session: Cookies.get('SID') || localStorage.getItem('commentatorSession'),
      userserviceApi: `${protocol}//${Store.state.config.urlInfo.userAuthUrl}`, // "cc-ms-dev.tvunetworks.com/userAuth"， userauth地址           （可按照服务后端配置自行绑定）
      newUserServiceUrl: `${protocol}//${Store.state.config.urlInfo.serviceListUrl}` // "cc-ms-dev.tvunetworks.com/serviceListUI"，
    }
    const timer = setInterval(() => {
      if (window.$) {
        Tool.loadMediaJs(serverUrl)
        clearInterval(timer)
      }
    }, 10)
  }
}
export { loadSudoku }
