/* eslint-disable */
import AgoraRTC from 'agora-rtc-sdk-ng'
import { Message } from 'element-ui'
import Bus from '@/assets/js/bus.js';
import $Store from '@/store/index.js'
import { createMediaTrack } from '@/assets/js/agoraFeature.js'

window.partyLine = AgoraRTC
AgoraRTC.setLogLevel(2)
export default class RTCClient {
  constructor(callBack) {
    this._client = null
    this._callBack = callBack
    this._params = {}
    this._localAudioTrack = null
    this._localVideoTrack = null
    this._AgoraRTC = AgoraRTC
    this._shareTrack = null
    this._publishVideo = false
    this._publishAudio = false
    this._hasPublishVideo = false //是否存在发过video
    this._hasPublishAudio = false //是否存在发过audio
    this._remotePublishUser = {}
    this._subscribeTrack = {}
    this._deviceInfo = {}
    this._role = 'audience'
    // 添加监听自动播放失败函数
    this.listenIsAutoplayFailed()
    this.listenCameraChange()
    this.listenMicChange()
    this.listenPlaybackChange()
  }
  /* _params : {
    audio, //是否创建音频流（可选参数，Boolean， 默认创建true或者undefined）
    publishAudio, //发布音频流（可选参数，Boolean， 默认创建true或者undefined）
    video, //是否创建视频流（可选参数，Boolean， 默认创建true或者undefined）
    publishVideo, //发布音频流（可选参数，Boolean， 默认创建true或者undefined）
    microphoneId, //指定麦克风，不指定为默认
    cameraId, //指定摄像头（可选参数，不传为默认）
    videoConfig, // 视频流参数设置（数组，必传）
    domId, // 播放本地流的容器（可选参数，默认为local_stream）
    isPlay, // 是否需要播放本地流（可选参数，Boolean， 默认播放true或者undefined）
    mode, // 场景选择live直播场景 rtc通信场景（默认live）
    codec, //编码选择vp8/h264 (默认vp8)
    token, 
    appId, 
    channel, 
    uid,
    subscribeRemote: 默认是否订阅远端流（可选参数，Boolean， 默认订阅true或者undefined）
  } */
  listenIsAutoplayFailed () {
    AgoraRTC.onAutoplayFailed = () => {
      this._callBack({
        action: 'autoPlayFailed',
        payload: {
          id: this._params.uid,
          channel: this._params.channel
        }
      })
    }
  }
  listenCameraChange () {
    AgoraRTC.onCameraChanged = (changeDevice) => {
      this._callBack && this._callBack({
        action: 'cameraChange',
        payload: {
          id: this._params.uid,
          channel: this._params.channel,
          changeDevice
        }
      })
    }
  }
  listenMicChange () {
    AgoraRTC.onMicrophoneChanged = (changeDevice) => {
      this._callBack && this._callBack({
        action: 'micChange',
        payload: {
          id: this._params.uid,
          channel: this._params.channel,
          changeDevice
        }
      })
    }
  }
  listenPlaybackChange () {
    AgoraRTC.onPlaybackDeviceChanged = (changeDevice) => {
      this._callBack && this._callBack({
        action: 'playbackChange',
        payload: {
          id: this._params.uid,
          channel: this._params.channel,
          changeDevice
        }
      })
    }
  }

  async getDevice () {
    let obj = {
      cameraList: [],
      microphoneList: [],
      speakerList: [],
      microphoneId: '',
      cameraId: '',
      speakerId: ''
    }
    let cameraDevice = []
    let micophoneDevice = []
    let speakerDevice = []
    let microphoneId = window.localStorage.getItem('microphoneId')
    let cameraId = window.localStorage.getItem('cameraId')
    let speakerId = window.localStorage.getItem('speakerId')
    try {
      // let devices = await AgoraRTC.getDevices()

      await AgoraRTC.getCameras()
        .then((res) => {
          cameraDevice = res
          if (cameraDevice.length > 0) {
            console.log('cameraList: ', cameraDevice)
            cameraDevice.forEach((item, index) => {
              if (item.kind === 'videoinput' && item.deviceId !== '') {
                obj.cameraList.push(item)
                if (cameraId == item.deviceId) {
                  obj.cameraId = cameraId
                }
              }
            })
          }
        })
        .catch(() => {
          console.info('getCameras error')
        })
      await AgoraRTC.getMicrophones()
        .then((res) => {
          micophoneDevice = res
          if (micophoneDevice.length > 0) {
            micophoneDevice.forEach((item, index) => {
              if (item.kind === 'audioinput' && item.deviceId !== '') {
                if (microphoneId == item.deviceId) {
                  obj.microphoneId = microphoneId
                }
                obj.microphoneList.push(item)
              }
            })
          }
        })
        .catch(() => {
          console.info('getMicrophones error')
        })

      await AgoraRTC.getPlaybackDevices()
        .then((res) => {
          speakerDevice = res
          if (speakerDevice.length > 0) {
            speakerDevice.forEach((item, index) => {
              if (item.kind === 'audiooutput' && item.deviceId !== '') {
                if (speakerId == item.deviceId) {
                  obj.speakerId = speakerId
                }
                obj.speakerList.push(item)
              }
            })
          }
        })
        .catch(() => {
          console.info('getSpeakers error')
        })

      if (!obj.cameraId)
        obj.cameraId = obj.cameraList[0] ? obj.cameraList[0].deviceId : ''
      if (!obj.microphoneId)
        obj.microphoneId = obj.microphoneList[0]
          ? obj.microphoneList[0].deviceId
          : ''
      if (!obj.speakerId)
        obj.speakerId = obj.speakerList[0]
          ? obj.speakerList[0].deviceId
          : ''

      this._deviceInfo = obj

      return obj
    } catch (e) {
      Message({
        message: e.message,
        type: 'error',
        offset: 50,
        showClose: true
      })
      console.info('getdevices failed', e)
      return obj
    }
  }
  async setDevice (deviceId, type, needLocalAudioTrack, fn) {
    if (type === 'video') {
      $Store.state.initCamera = true
      $Store.state.showInitCameraTips = false
    }
    if (type == 'video' && !this._localVideoTrack) {
      const videoConfig = this._params.videoConfig
      this._localVideoTrack = await AgoraRTC.createCameraVideoTrack({
        cameraId: deviceId, //指定摄像头
        encoderConfig: {
          // 视频分辨率
          width: videoConfig[0].width,
          height: videoConfig[0].height,
          // 视频编码帧率。通常建议是 15 帧，不超过 30 帧
          frameRate: {
            min: 15,
            max: videoConfig[0].fps
          },
          bitrateMin: videoConfig[0].bitrate,
          bitrateMax: videoConfig[0].bitrate
        }
      })
      $Store.state.initCamera = false
      if (this._localVideoTrack) {
        this._localVideoTrack.play(`${this._params.domId || 'local_stream'}`, {
          mirror: true
        })
        if (!this._hasPublishVideo && this._params.publish !== false) {
          if (this._role !== 'host') {
            await this._client.setClientRole('host') //不需要推流的不用设置host
            this._role = 'host'
          }
          let trackArray = [this._localVideoTrack]
          this._client.publish(trackArray)
        }
      } else {
        $Store.state.showInitCameraTips = true
        this._deviceInfo && this._deviceInfo.cameraList.forEach(item => {
          if (item.deviceId == deviceId) {
            $Store.state.cameraName = item.label
          }
        })
      }
    }
    if (type == 'video' && this._localVideoTrack) {
      this._localVideoTrack.setDevice(deviceId).then(() => {
        $Store.state.initCamera = false
        localStorage.setItem('cameraId', deviceId)
        this.setSolution()
        if (fn) fn('success')
        this._localVideoTrack && this._localVideoTrack.setEnabled(true)
        needLocalAudioTrack && needLocalAudioTrack.setEnabled(true)
      }).catch(() => {
        $Store.state.initCamera = false
        $Store.state.showInitCameraTips = true
        this._deviceInfo && this._deviceInfo.cameraList.forEach(item => {
          if (item.deviceId == deviceId) {
            $Store.state.cameraName = item.label
          }
        })
        if (fn) fn('failed')
        this._localVideoTrack && this._localVideoTrack.setEnabled(false)
        needLocalAudioTrack && needLocalAudioTrack.setEnabled(false)
      })
    }
    if (type == 'audio' && this._localAudioTrack) {
      this._localAudioTrack.setDevice(deviceId).then(() => {
        localStorage.setItem('microphoneId', deviceId)
        if (fn) fn('success')
        if (needLocalAudioTrack) {
          const { uid, channel } = this._params
          this._callBack({
            action: 'local-track',
            payload: {
              uid,
              channel,
              audioTrack: this._localAudioTrack,
              videoTrack: this._localVideoTrack,
              notExecuteDeviceListUpdate: 'notExecuteDeviceListUpdate'
            }
          })
        }
      }).catch(() => {
        if (fn) fn('failed')
      })
    }
  }
  async join (data) {
    this._params = data
    let { mode, codec, token, appId, channel, uid, share, startProxy, getRemoteNetworks } = data
    // 创建本地客户端
    // mode: live直播场景 rtc通信场景
    this._client = AgoraRTC.createClient({
      mode: mode || 'live',
      codec: codec || 'vp8'
    })
    //开启云代理
    if (startProxy) {
      this._client.startProxyServer(3);
    }

    // 添加监听事件
    if (!share) {
      this.addEventsListener()
    }

    // join channel
    try {
      let result = await this._client.join(appId, channel, token, uid)
      console.info('join success', uid, channel)

      // 获取音量
      this._client.enableAudioVolumeIndicator()

      // 创建并播放
      if (share) {
        await this.createShareTrack()
      } else {
        await this.createLocalTrack()
      }
      // 是否获取远端的client的网络状态
      if (getRemoteNetworks) {
        this.getRemoteNetworksFn()
      }
      return result
    } catch (e) {
      console.info('join failed', e, channel, uid)
    }
  }
  async createLocalTrack () {
    try {
      let {
        uid,
        channel,
        audio,
        sendMetadata,
        video,
        microphoneId,
        cameraId,
        videoConfig,
        domId,
        isPlay,
        publish,
        publishAudio,
        publishVideo,
        audioProfil,
        checkedAec,
        checkedAns,
        checkedAgc,
        quality
      } = this._params
      if (audio !== false) {
        //默认创建本地音频流
        try {
          const encoderConfig = audioProfil ? audioProfil : 'music_standard'
          this._localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack({
            AEC: checkedAec != undefined ? checkedAec : true, //回声消除
            ANS: checkedAns != undefined ? checkedAns : true, //噪声抑制
            AGC: checkedAgc != undefined ? checkedAgc : true,  //麦克风增强
            microphoneId, //指定麦克风
            encoderConfig //音频参数
          })
        } catch (e) {
          console.info('creat local audio error', e)
        }
      }
      if (video !== false) {
        if (!videoConfig || !videoConfig.length) return
        let userCameraId = cameraId
        //默认创建本地音频流
        try {
          $Store.state.initCamera = true
          this._localVideoTrack = await AgoraRTC.createCameraVideoTrack({
            cameraId, //指定摄像头
            encoderConfig: quality ? quality : {
              // 视频分辨率
              width: videoConfig[0].width,
              height: videoConfig[0].height,
              // 视频编码帧率。通常建议是 15 帧，不超过 30 帧
              frameRate: {
                min: 15,
                max: videoConfig[0].fps
              },
              bitrateMin: videoConfig[0].bitrate,
              bitrateMax: videoConfig[0].bitrate
            }
          })
          if (this._localVideoTrack) $Store.state.initCamera = false
        } catch (e) {
          $Store.state.initCamera = false
          $Store.state.showInitCameraTips = true
          this._deviceInfo && this._deviceInfo.cameraList && this._deviceInfo.cameraList.forEach(item => {
            if (item.deviceId == cameraId) {
              $Store.state.cameraName = item.label
            }
          })
          // 获取可用的摄像头列表
          // const devices = await navigator.mediaDevices.enumerateDevices()
          // const videoDevices = devices.filter(device => (device.kind === 'videoinput' && device.deviceId !== userCameraId))
          // if (videoDevices.length) { // 如果有多个可用的摄像头，则选择另一个
          //   this._localVideoTrack = await createMediaTrack({
          //     type: 'video',
          //     list: videoDevices,
          //     videoConfig,
          //     AgoraRTC
          //   })
          //   if (!this._localVideoTrack) {
          //     Message({
          //       message: 'Switch to another available camera automatically.',
          //       type: 'warning',
          //       offset: 50,
          //       showClose: true
          //     })
          //   }
          // } else {
          //   Message({
          //     message: 'Create local video failed， please check whether your device is available.',
          //     type: 'error',
          //     offset: 50,
          //     showClose: true
          //   })
          //   // 创建流失败，则就不会发流
          //   this._callBack({
          //     action: 'local-unpublish',
          //     payload: {
          //       id: this._params.uid,
          //       type: ['video']
          //     }
          //   })
          //   console.log('create local video failed: ', e)
          // }
        }
      }

      //只播放本地视频即可，无需播放音频
      if (isPlay !== false && this._localVideoTrack) {
        this._localVideoTrack.play(`${domId || 'local_stream'}`, {
          mirror: false
        })
        this.setSolution()
      }

      if (sendMetadata) {
        this._client &&
          this._client.sendMetadata(
            `{"timeCode": ${Date.now()}, "timestamp": ${Date.now()}, "rtil": ${uid}}`,
            function (error) {
              console.log('sendMetadata', error)
            }
          )
      }
      // 发双流
      if (quality) {
        this._client.enableDualStream()
        this._client.setLowStreamParameter({
          width: 320,
          height: 180,
          framerate: 15,
          bitrate: 150
        })
      }
      // 推流
      if (publish !== false) {
        //直播场景里，调用 setClientRole 将用户角色设为主播后才能publish
        await this._client.setClientRole('host') //不需要推流的不用设置host
        this._role = 'host'

        // 确认需要publish的流
        let trackArray = []
        if (this._localAudioTrack && publishAudio) {
          trackArray.push('audio')
        }
        if (this._localVideoTrack && publishVideo) {
          trackArray.push('video')
        }
        this.publish(trackArray)
      }
      this._callBack({
        action: 'local-track',
        payload: {
          uid,
          channel,
          audioTrack: this._localAudioTrack,
          videoTrack: this._localVideoTrack
        }
      })
    } catch (e) {
      console.info('creat track failed')
    }
  }
  async publish (array, notHasPush, val) {
    // trackArray可以是单个视频或音频流也可是是数组集合的形式
    // let { audio, video } = this._params
    try {
      let trackArray = []
      //直播场景里，调用 setClientRole 将用户角色设为主播后才能publish
      if (this._role !== 'host') {
        await this._client.setClientRole('host') //不需要推流的不用设置host
        this._role = 'host'
      }
      if (array.length) {
        array.forEach((val) => {
          if (
            val == 'audio' &&
            this._localAudioTrack &&
            this._localAudioTrack._enabled &&
            !this._publishAudio
          ) {
            trackArray.push(this._localAudioTrack)
            console.info('publish audio', this._params.channel)
          }
          if (
            val == 'video' &&
            this._localVideoTrack &&
            this._localVideoTrack._enabled &&
            !this._publishVideo
          ) {
            trackArray.push(this._localVideoTrack)
          }
        })
        if (!trackArray.length) return
        await this._client.publish(trackArray)
        // 推流成功更改状态
        array.map((v) => {
          if (v == 'audio') {
            this._hasPublishAudio = true
            this._publishAudio = true
            if (notHasPush) {
              val ? this.publish(['audio']) : this.unpublish(['audio'])
            }
          }
          if (v == 'video') {
            this._hasPublishVideo = true
            this._publishVideo = true
            if (notHasPush) {
              val ? this._localVideoTrack.setEnabled(true) : this._localVideoTrack.setEnabled(false)
            }
          }
        })

        console.info('publish success')
      }
      const { muteVideo, muteAudio } = this._params
      const _this = this
      //加定时是因为如果发的过快Agora那边不会去发禁用掉的那路track(导致host和与会者设备状态不对初始化时)，正常是2路track都publish然后在发unpublish的那路
      setTimeout(() => {
        if (muteVideo) {
          console.log('setEnabled muteVideo', this._client)
          if (!this._hasPublishVideo) {
            _this.publish(['video'], 'notHasPush', false)
          } else {
            if (!notHasPush) {
              this._localVideoTrack && this._localVideoTrack.setEnabled(false, 'video')
            }
          }
        }
        //由于检测音频不发布的需求此处会引起'初始化mute麦克风再open会出问题'因为先将此处注释
        // if (muteAudio) {
        // console.log('setEnabled muteAudio', this._client)
        // if (!this._hasPublishAudio) {
        //   _this.publish(['audio'], 'notHasPush', false)
        // }
        //  else {
        //   if (!notHasPush) {
        //       this._localAudioTrack && this._localAudioTrack.setEnabled(false, 'audio')
        //   }
        // }
        // }
      }, 500)
    } catch (e) {
      console.info('publish failed', e)
    }
  }
  async unpublish (array) {
    // trackArray可以是单个视频或音频流也可是是数组集合的形式
    try {
      let trackArray = []
      if (array.length) {
        array.forEach((val) => {
          if (val == 'audio' && this._localAudioTrack && this._publishAudio) {
            trackArray.push(this._localAudioTrack)
          }
          if (val == 'video' && this._localVideoTrack && this._publishVideo) {
            trackArray.push(this._localVideoTrack)
          }
        })
        await this._client.unpublish(trackArray)
        // 取消推流成功更改状态
        array.map((v) => {
          if (v == 'audio') {
            this._publishAudio = false
            this._hasPublishAudio = false
          }
          if (v == 'video') {
            this._publishVideo = false
            this._hasPublishVideo = false
          }
        })
        // await this._client.setClientRole("audience") //不需要推流的不用设置host
        this._role = 'audience'
        console.info('unpublish suceess')
      }
    } catch (e) {
      console.info('unpublish failed', e)
    }
  }
  setSolution (width) {
    const constraints = {
      aspectRatio: {
        exact: 640 / 360
      }
    }
    this._localVideoTrack
      .getMediaStreamTrack()
      .applyConstraints(constraints)
      .then(() => { })
      .catch((err) => {
        this.setSolution(
          parseInt(
            this._localVideoTrack.getMediaStreamTrack().getConstraints().width /
            2
          )
        )
      })
  }
  async leave (closeCLoudProxy) {
    //手动触发离开channel
    try {
      if (!this._client) return
      return this._client.leave().then(() => {
        if (closeCLoudProxy && this._client) this._client.stopProxyServer()
        this._client = null
        this._publishAudio = false
        this._publishVideo = false
        this._role = 'audience'
        if (this._localAudioTrack) {
          this._localAudioTrack.close()
          this._localAudioTrack = null
        }
        if (this._localVideoTrack) {
          this._localVideoTrack.close()
          this._localVideoTrack = null
        }
      })
      console.log('离开成功 ', this._params.uid, this._params.channel)

    } catch (e) {
      console.info('leave failed', e)
    }
  }
  play (track, playerConfig, info, fun) {
    if (!track) return
    const { type, videoBox } = info
    if (type === 'video') {
      if (videoBox && typeof videoBox != 'string') {
        videoBox.innerHTML = ''
      }
      track.play(videoBox, playerConfig ? playerConfig : '')
      fun && fun()
    } else {
      track.play()
    }
  }
  stop (track) {
    if (!track) return
    track.stop()
  }
  // 订阅track
  async subscribe (uid, mediaType) {
    // mediaType : audio video all
    // uid: 需要订阅人的rtilCode
    console.info(368, uid, mediaType, this._params.channel, this._client)
    let remoteUser = this._remotePublishUser[uid]
    if (remoteUser && this._client) {
      if (mediaType == 'audio' && remoteUser.hasAudio) {
        await this._client.subscribe(remoteUser, 'audio')
      }
      if (mediaType == 'video' && remoteUser.hasVideo) {
        await this._client.subscribe(remoteUser, 'video')
      }
      if (mediaType == 'all') {
        if (remoteUser.hasAudio) {
          await this._client.subscribe(remoteUser, 'audio')
        }
        if (remoteUser.hasVideo) {
          await this._client.subscribe(remoteUser, 'video')
        }
      }
      let { uid, audioMuted, videoMuted, audioTrack, videoTrack } = remoteUser

      this._subscribeTrack[uid] = {
        audioTrack,
        videoTrack,
        channel: this._params.channel
      }
      this._callBack({
        action: 'subscribe-track',
        payload: {
          id: uid,
          audioMuted,
          videoMuted,
          audioTrack,
          videoTrack,
          channel: this._params.channel,
          type: mediaType
        }
      })
    }
  }
  // 取消订阅
  async unsubscribe (uid, mediaType) {
    let remoteUser = this._remotePublishUser[uid]
    if (remoteUser && this._client) {
      if (mediaType == 'audio') {
        await this._client.unsubscribe(remoteUser, 'audio')
      }
      if (mediaType == 'video') {
        await this._client.unsubscribe(remoteUser, 'video')
      }
      if (mediaType == 'all') {
        await this._client.unsubscribe(remoteUser)
      }

      let { uid, audioTrack, videoTrack } = remoteUser

      if (!audioTrack && !videoTrack) {
        delete this._subscribeTrack[uid]
      } else {
        this._subscribeTrack[uid] = {
          audioTrack,
          videoTrack,
          channel: this._params.channel
        }
      }
      this._callBack({
        action: 'unsubscribe-track',
        payload: {
          id: uid,
          audioTrack,
          videoTrack,
          channel: this._params.channel,
          type: mediaType
        }
      })
    }
  }
  // 是否启用/禁用音视频轨道
  async setEnabled (status, type, fun, info = {}) {
    // status: Boolean  true:启用 false：禁用
    // type: video audio
    const { controlsMedia } = info
    let flag = true
    if (this._role != 'host') return
    if (status == false && type == 'video' && this._localVideoTrack && this._localVideoTrack.getMediaStreamTrack().readyState !== 'live') {
      // 如果当前视频设备不可用，则直接返回，不进行任何操作
      if (fun) fun('failed')
      return
    }
    if (type == 'video' && !this._localVideoTrack && !controlsMedia) {
      if (fun) fun('failed')
      return
    }
    if (type == 'audio' && !this._localAudioTrack && !controlsMedia) {
      if (fun) fun('failed')
      return
    }
    if (type == 'video' && this._localVideoTrack) {
      if (this._hasPublishVideo) {
        this._localVideoTrack.setEnabled(status).then(res => {
          console.info('setEnabled video', this._params.channel, status)
        }).catch((error) => {
          flag = false
          if (fun) fun('failed')
        })
      } else {
        // 如果没有发布过流，直接调用 setEnable 是无效的
        if (status) {
          this.publish(['video'], 'notHasPush', status)
        } else {
          await this.unpublish(['video'])
          flag = false
        }
      }
    }
    if (type == 'audio' && this._localAudioTrack) {
      if (this._hasPublishAudio) {
        await this._localAudioTrack.setEnabled(status).then(res => {
          console.info('setEnabled audio', this._params.channel, status)
        }).catch((error) => {
          flag = false
          if (fun) fun('failed')
        })
      } else {
        if (status) {
          this.publish(['audio'], 'notHasPush', status)
        } else {
          await this.unpublish(['audio'])
          flag = false
        }
      }
      if (fun) fun()
    }
  }
  // 动态切换视频的编码参数
  setEncoderConfiguration (config) {
    this._localVideoTrack.setEncoderConfiguration({
      // 视频分辨率
      width: config.width,
      height: config.height,
      // 视频编码帧率。通常建议是 15 帧，不超过 30 帧
      frameRate: config.fps,
      bitrateMin: config.bitrateRange[0],
      bitrateMax: config.bitrateRange[1]
    })
    this.setSolution()
  }
  // 获取当前通话的统计信息
  getRtcStats () {
    if (this._client) {
      // setInterval(() => {
      let status = this._client.getRTCStats()
      return status
      // }, 1000);
    }
  }
  // 获取当前这一帧的画面
  getCurrentFrameData (videoTrack) {
    return videoTrack ? videoTrack.getCurrentFrameData() : ''
  }
  // 创建共享屏幕的track
  async createShareTrack () {
    AgoraRTC.createScreenVideoTrack({
      encoderConfig: '1080p_1'
    })
      .then(async (res) => {
        this._shareTrack = res
        this.shareEventListener()
        await this._client.setClientRole('host') //不需要推流的不用设置host
        this._role = 'host'
        this._client.publish(this._shareTrack)
      })
      .catch(() => {
        this._client.leave()
        this._callBack({
          action: 'stop-share',
          payload: {
            id: this._params.uid,
            channel: this._params.channel
          }
        })
      })
  }
  addEventsListener () {
    let { uid, channel } = this._params //本地参数

    //加入/离开频道时，本地触发（SDK 与服务器的连接状态）
    this._client.on('connection-state-change', (curState, revState, reason) => {
      this._callBack({
        action: 'connection-state-change',
        payload: {
          id: uid,
          channel,
          curState,
          revState,
          reason
        }
      })
    })
    //主播加入频道 推流的uid才可以被监听到
    this._client.on('user-joined', (user) => {
      let { uid, videoMuted, audioMuted } = user
      /* 该回调在以下情况下会被触发：
      直播场景的远端观众加入频道后调用 setClientRole 将用户角色改变为主播。
      主播通过调用 addInjectStreamUrl 方法成功输入在线媒体流。 */
      this._callBack({
        action: 'user-joined',
        payload: {
          id: uid,
          videoMuted,
          audioMuted,
          channel
          // ...user
        }
      })
    })
    //远端用户离线回调 推流的uid才可以被监听到
    this._client.on('user-left', (user, reason) => {
      let { uid, videoMuted, audioMuted } = user
      /*  正常离开：用户会收到类似“再见”的消息，接收此消息后，判断对方离开频道。
      超时掉线：在 20 秒内，用户没有收到对方的任何数据包，则判定为对方掉线。在网络较差的情况下，有可能会误报。
      用户角色从主播变为观众 */
      let action = null
      if (reason == 'Quit') {
        //用户调用了 leave 主动离开频道
        action = 'user-left'
      }
      if (reason == 'ServerTimeOut') {
        //因过长时间收不到对方数据包，超时掉线
        action = 'user-left-timeout'
      }
      if (reason == 'BecomeAudience') {
        //用户角色从主播切换为观众
        action = 'user-left-audience'
      }
      this._callBack({
        action: 'user-left',
        payload: {
          id: uid,
          videoMuted,
          audioMuted,
          channel
          // ...user
        }
      })
    })
    // 监听到远端用户发布了新的音频轨道或者视频轨道
    this._client.on('user-published', async (remoteUser, mediaType) => {
      // mediaType : audio video all
      let { uid } = remoteUser
      this._remotePublishUser[uid] = remoteUser //存储所有发布track的远端
      if (this._params.subscribeRemote !== false) {
        this.subscribe(uid, mediaType)
      }
      this._callBack({
        action: 'user-published',
        payload: {
          id: uid,
          remoteUser,
          mediaType,
          channel,
          type: mediaType
        }
      })
    })
    // 通知远端用户取消发布了音频或视频轨道
    this._client.on('user-unpublished', (remoteUser, mediaType) => {
      let { uid } = remoteUser
      // 删除存储的所有推流的集合(由于关闭mic，进行talkBack后不能正常取消订阅，暂时删除这行代码）
      // delete this._remotePublishUser[uid]
      this._callBack({
        action: 'user-unpublished',
        payload: {
          id: uid,
          remoteUser,
          mediaType,
          channel,
          type: mediaType
          // ...user
        }
      })
    })
    //接收远端说话的音量
    this._client.on('volume-indicator', (evt) => {
      this._callBack({
        action: 'volumeList',
        payload: evt
      })
    })
    //接收metadata
    this._client.on('receive-metadata', (uid, uint8array) => {
      let id = uid
      // let info = new TextDecoder().decode(uint8array)
      let info = uint8array
      this._callBack({
        action: 'receiveMetadata',
        payload: {
          id,
          metadataInfo: info,
          channel
        }
      })
    })
    //返回本地用户的上下行网络质量
    this._client.on('network-quality', info => {
      const { downlinkNetworkQuality, uplinkNetworkQuality } = info
      Bus.$emit('downlinkNetworkQuality', downlinkNetworkQuality)
      Bus.$emit('uplinkNetworkQuality', uplinkNetworkQuality)
      //为了显示网络状态用
      let { channel, uid, getRemoteNetworks } = this._params
      if (getRemoteNetworks) {
        this._callBack({
          action: 'unlinkQuality',
          payload: {
            id: uid,
            channel,
            uplinkNetworkQuality
          }
        })
      }
    })
  }
  shareEventListener () {
    let { uid, channel } = this._params //本地参数
    // 通知远端用户取消发布了音频或视频轨道
    this._shareTrack.on('track-ended', (ee) => {
      this.leave()
      this._callBack({
        action: 'stop-share',
        payload: {
          id: uid,
          channel
        }
      })
    })
  }
  // 获取远端的网络状态
  getRemoteNetworksFn () {
    this.networksInterval = setInterval(() => {
      let networks = this._client ? this._client.getRemoteNetworkQuality() : ''
      if (networks) {
        Object.keys(networks).forEach(id => {
          this._callBack({
            action: 'unlinkQuality',
            payload: {
              id,
              channel: this._params.channel,
              uplinkNetworkQuality: networks[id].uplinkNetworkQuality,
              downlinkNetworkQuality: networks[id].downlinkNetworkQuality,
              type: 'remoteNetWorkQuality'
            }
          })
        })
      }
    }, 2000)
  }
}
/* eslint-disable no-new */
