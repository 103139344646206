<template>
  <el-dialog
    :visible.sync="info.show"
    top="10px"
    :close-on-click-modal="false"
    :append-to-body="true"
    width="450px"
  >
    <div slot="title">
      Tag name setting <span class="gray">Tag name is only visible to you</span>
    </div>
    <div class="content">
      <div class="nickname">
        <span>Tag name:</span>
        <el-input
          :maxlength="32"
          v-model="tagName"
          placeholder="Add tag name"
        ></el-input>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button class="cancel" type="primary" @click="deleteFn"
        >Delete</el-button
      >
      <el-button
        type="primary"
        @click="save"
        :disabled="
          !tagName || (tagName.replace(/(^\s*)|(\s*$)/g, '') ? false : true)
        "
        >Submit</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: {
    info: Object
  },
  computed: {
    ...mapState({
      State: state => state
    })
  },
  data () {
    return {
      tagName: ''
    }
  },
  created () {
    this.tagName = this.info.tagName
  },
  methods: {
    cancel () {
      this.info.show = false
    },
    deleteFn () {
      const { originRtilCode, partyCode, targetRtilCode } = this.info
      if (!partyCode) return
      const params = {
        mark: targetRtilCode + '',
        annotation: partyCode,
        key: 'tagName',
        notify: true,
        initiator: originRtilCode
      }
      this.$http
        .post(`${this.State.config.urlInfo.partyline}/partyline-backend/behavior/noLogin/delete`, params)
        .then((res) => {
          if (res.data.errorCode == '0x0') {
            this.info.show = false
            this.$emit('saveTagName', { rtilCode: targetRtilCode, tagName: '' })
          }
        })
    },
    save () {
      const tagName = this.tagName.replace(/(^\s*)|(\s*$)/g, '')
      if (!tagName) {
        // this.$message.error('Tag name is required')
        return
      }
      const { originRtilCode, partyCode, targetRtilCode } = this.info

      if (!partyCode) return

      const params = [
        {
          mark: targetRtilCode + '',
          annotation: partyCode,
          key: 'tagName',
          value: tagName,
          notify: true,
          initiator: originRtilCode
        }
      ]

      this.$http
        .post(`${this.State.config.urlInfo.partyline}/partyline-backend/behavior/noLogin/setList`, params)
        .then((res) => {
          if (res.data.errorCode == '0x0') {
            this.info.show = false
            this.$emit('saveTagName', {
              rtilCode: targetRtilCode,
              tagName: this.tagName
            })
          }
        })
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.el-dialog {
  .el-button {
    border: 0;
    background: none;
    color: #fff;
    padding: 9px 20px;
    border-radius: 20px;
  }
  .el-button--primary {
    background: linear-gradient(
      270deg,
      rgba(3, 161, 118, 1) 0%,
      rgba(113, 186, 42, 1) 59%,
      rgba(208, 217, 0, 1) 100%
    );
    &.is-disabled {
      background: #ddd;
    }
  }
  .gray {
    color: #999;
    font-size: 14px;
  }
  .el-dialog__header {
    color: #000;
  }
  .cancel {
    border: none;
    background: none;
    color: #555661;
    padding: 9px 20px;
    width: auto;
    border-radius: 20px;
  }
}
.content {
  font-size: 16px;
  color: #28282e;
  .nickname {
    margin-bottom: 10px;
    label {
      color: #ff4b4b;
      margin-right: 5px;
    }
    span {
      display: inline-block;
      width: 110px;
      text-align: right;
    }
    /deep/.el-input {
      margin-left: 5px;
      width: 230px;
      .el-input__inner {
        background: #f5f5f5;
        border: none;
        height: 36px;
        line-height: 36px;
        color: #28282e;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  i {
    font-style: normal;
  }
}
</style>
