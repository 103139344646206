import {
  commentator
} from './eventAnalyze.config.js'

export function myCommentatorAnalyze (str) {
  try {
    ga('send', 'event', commentator[str], 'click') // 谷歌统计
  } catch (err) {
  }
}
