/* eslint-disable space-before-function-paren */
import Utils from '@/assets/js/utils.js'
class websocket {
  constructor(data) {
    this.$ws = null
    this.lockReturn = false
    this.timeout = 1000
    this.timeoutObj = null
    this.timeoutNum = null
    this.serverTimeoutObj = null
    this.isClose = false
    this.reconnectSend = false
    this.sendHeart = data.sendHeart || false
    this.pairNumber = data.pairNumber || null
    this.wsType = data.wsType || null
    this.taskid = data.taskid || null
    this.url = data.url
    this.meaasge = data.meaasge
    this.time = data.time
    this.key = data.key
    this.once = data.once
    this.success = data.success
    this.onMessage = data.onMessage
    this.successObj = {}
    this.rInfo = {}
    this.sendMsg = {}
  }

  // 初始化webSocket长连接
  initWebSocket () {
    this.interval = {}
    this.heartInterval = {}
    if (this.wsType) {
      this.$ws = new WebSocket(this.url, ['graphql-ws'])
    } else {
      this.$ws = new WebSocket(this.url)
    }
    this.$ws.onopen = this.open.bind(this)
    this.$ws.onclose = this.close.bind(this)
    this.$ws.onmessage = this.message.bind(this)
    this.$ws.onerror = this.error.bind(this)
  }
  open () {
    // 可用于重连后的send
    if (this.reconnectSend) {
      console.info('reconnectSend', this.sendMsg)
      Object.keys(this.sendMsg).forEach((key) => {
        // 用于重联之后的定时发送
        this.sendMsg[key].once = false
        this.send(this.sendMsg[key])
        console.log('websocket重连成功！')
      })
      this.reconnectSend = false
    }
    //用来判断是否发心跳
    if (this.sendHeart) {
      const _this = this
      this.heartInterval[this.key] = setInterval(() => {
        _this.$ws.send(1)
      }, 3000)
    }
    if (this.wsType && this.wsType === 'graphql') {
      this.$ws.send(JSON.stringify({ type: 'connection_init' }))
      for (let pairId = 1; pairId <= this.pairNumber; pairId++) {
        const params = {
          id: Utils.getUUID(),
          type: 'start'
        };
        // Setting the actual payload data
        params.payload = {
          data: `{\"query\":\"subscription getPair($pairId: Int!, $taskid: String!) {\\n  onUpdatedPair(ID: $pairId, taskid: $taskid) {\\n    clientid\\n    AFV\\n    ID\\n    outputData {\\n      previewID\\n      mute\\n      sliderPosition\\n      __typename\\n    }\\n    taskid\\n    inputDatas {\\n      ID\\n      previewID\\n      index\\n      RTILCode\\n      living\\n      slotNo\\n      partyCode\\n      sourceName\\n      sourceType\\n      description\\n      sequence\\n      visible\\n      audioStreams {\\n        ID\\n        mode\\n        sliderPosition\\n        balance\\n        leftChannelSliderPosition\\n        rightChannelSliderPosition\\n        channelDatas {\\n          ID\\n          mute\\n          output\\n          pan\\n          sliderPosition\\n          commentratorMute\\n          commentratorMicMute\\n          __typename\\n        }\\n        description\\n        visible\\n        stereoBalance\\n        __typename\\n      }\\n      AFV\\n      __typename\\n    }\\n    timestamp\\n    __typename\\n  }\\n}\\n\","variables":{"taskid":"${this.taskid}","pairId":${pairId}}}`,
          extensions: {
            authorization: {
              "host": "audty5w6z5dgdaua2j4hgm4rqy.appsync-api.us-west-2.amazonaws.com",
              "x-amz-date": "20240226T080957Z",
              "x-api-key": "da2-jsjid2spirgava7hvuos2rsvaq",
              "X-Api-Key": "da2-jsjid2spirgava7hvuos2rsvaq",
              "x-amz-user-agent": "aws-amplify/2.0.8"
            }
          }
        }
        this.$ws.send(JSON.stringify(params))
      }
    }
  }

  send (data) {
    let { key, time, success, message, once } = data
    // console.info('time',time)
    if (message.categoryId && message.operationType) {
      const rkey = 'key' + message.categoryId + message.operationType
      this.rInfo[rkey] = rkey
      key = rkey
    }
    if (!once && Object.keys(this.interval).includes(key)) {
      // key值相同，重复发送
      console.log(key, 'Current key  exist!')
      return
    }
    if (!key) {
      console.info(data, 'key is not exist')
      return
    }
    this.sendMsg[key] = data
    if (once) {
      // this.interval[key] = setTimeout(()=> {
      if (this.$ws && this.$ws.readyState === 1) {
        this.$ws.send(JSON.stringify(this.sendMsg[key].message))
      }
      // }, time);
    } else {
      this.interval[key] = setInterval(() => {
        if (this.$ws && this.$ws.readyState === 1) {
          this.$ws.send(JSON.stringify(this.sendMsg[key].message))
        }
      }, time)
    }
    if (typeof success === 'function') this.successObj[key] = success
  }

  stop (key) {
    // 从当前连接中，删除指定的信息传递
    if (this.interval[key] !== undefined) {
      clearInterval(this.interval[key])
      delete this.interval[key]
      delete this.successObj[key]
      delete this.sendMsg[key]
      if (this.rInfo[key]) delete this.rInfo[key]
    }
  }

  close (e) {
    console.log('websocket 断开：', e)
    if (!this.isClose) {
      // 非手动关闭
      setTimeout(() => {
        this.reconnect(e)
      }, 2000)
    }
  }

  off () {
    // 手动关闭websoket
    this.isClose = true
    if (this.interval) {
      Object.keys(this.interval).forEach((k) => {
        clearInterval(this.interval[k])
      })
      this.interval = null
      this.sendMsg = null
    }
    if (this.sendHeart) {
      clearInterval(this.heartInterval[this.key])
    }
    if (this.$ws) {
      this.$ws.close()
      this.$ws = null
    }
  }

  message (msg) {
    // 每次接收到服务端消息后
    // if (msg.data !== 'heartbeat') {
    let data = JSON.parse(msg.data)
    if (data == 2) return

    if (this.key == 'pageSerBusRequestByPartyCode') {
      Utils.websocketMessage(data, this.key)
      return
    } else if (this.key === 'pageSerBusRequestByUseId' || this.key == 'graphql') {
      // 单独处理用户在mainWindowInfo里面更新eventInfo是推送过来的ws消息
      Utils.websocketMessage(data, this.key)
    }
    data = data.result
    /* let key;
    if (data.categoryId && data.operationType) {
      var rkey = "key" + data["categoryId"] + data["operationType"];
      Object.keys(this.rInfo).forEach((v) => {
        if (this.rInfo[v] === rkey) {
          key = v;
          return;
        }
      });
    }
    if (!data.module) return;
    key = key || data.module; */
    const key = data && data.module
    /* key = key ? key : JSON.parse(msg.data);  //获取后台返回的key 以便响应对应的response 注意这个key要和后台返回的关键字相同
    var success = this.successObj['eventPage'] */ const success = this
      .successObj[key]
    if (success) success(data)

    /* }
    else{
      var success = that.successObj['heart'];
      if (success) success(e);
    } */
    if (this.onMessage && typeof data != 'number') {
      this.onMessage(data, msg.target.url)
    }
  }

  error (err) {
    console.log('连接失败', this.key, err)
    // this.reconnect()
  }

  // 重启websocket
  reconnect (msg) {
    console.info('reconnect', msg)
    if (this.interval) {
      Object.keys(this.interval).forEach((k) => {
        clearInterval(this.interval[k])
      })
      this.interval = null
    }
    if (this.sendHeart) {
      clearInterval(this.heartInterval[this.key])
    }
    this.reconnectSend = true
    if (msg.code === 3001 || msg.code === 3000) return // 3001代表session过期，不用重连了.
    this.initWebSocket()
  }
}
// 抛出websocket对象
export default websocket
