<template>
  <div class="volumeBox" :style="{ height: height - 10 + 'px' }">
    <canvas :class="rtilCode + 'Left'"></canvas>
    <canvas :class="rtilCode + 'Right'"></canvas>
    <!-- <div class="content">
      <div>+4</div>
      <div>0</div>
      <div>-6</div>
      <div>-12</div>
      <div>-18</div>
      <div>-36</div>
    </div> -->
  </div>
</template>
<script>
export default {
  data () {
    return {}
  },
  props: {
    rtilCode: [String, Number],
    height: Number
  }
}
</script>
<style scoped lang="less">
.volumeBox {
  padding: 4px 0 4px 4px;
  background: #17171A;
  border: 1px solid #28282E;
  margin-left: 3px;
  border-radius: 4px;
  canvas {
    width: 5px;
    height: 100%;
    margin-right: 5px;
    background: rgb(61, 63, 64);
  }
  .content {
    color: #fff;
    opacity: 0.6;
    float: right;
    font-size: 12px;
    height: 100%;
    margin-right: 5px;
    overflow: hidden;
    div {
      height: 18%;
      text-align: center;
    }
  }
}
</style>
