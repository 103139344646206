<template>
  <!-- 显示本地网络质量，在头部一直显示 -->
  <div class="networksQuality">
    <span class="level1" :class="bgColor"></span>
    <span class="level2" :class="bgColor"></span>
    <span class="level3" :class="bgColor"></span>
  </div>
</template>

<script>
import Bus from '@/assets/js/bus.js'
export default {
  data () {
    return {
      // 网络质量
      netWork: {
        upLinkNetWork: '',
        downLinkNetWork: ''
      },
      netWorkIconColor: '',
      remoteNetWork: {},
      remoteNetWorkIconColor: ''
    }
  },
  computed: {
    bgColor () {
      if (this.netWorkIconColor === 'Green') {
        return 'bgGreen'
      } else if (this.netWorkIconColor === 'Orange') {
        return 'bgOrange'
      } else if (this.netWorkIconColor === 'Red') {
        return 'bgRed'
      } else {
        return 'bgGreen'
      }
    }
  },
  created () {
    // 本地用户网络质量
    Bus.$on('downlinkNetworkQuality', (downlinkNetworkQuality) => {
      if (downlinkNetworkQuality < 3) {
        this.netWork.downLinkNetWork = 'Good'
      } else if (downlinkNetworkQuality > 4) {
        this.netWork.downLinkNetWork = 'Poor'
      } else {
        this.netWork.downLinkNetWork = 'Mid'
      }
      this.dealNetWorkQuality(this.netWork)
      this.$emit('callback', this.netWork)
    })
    Bus.$on('uplinkNetworkQuality', (uplinkNetworkQuality) => {
      if (uplinkNetworkQuality < 3) {
        this.netWork.upLinkNetWork = 'Good'
      } else if (uplinkNetworkQuality > 4) {
        this.netWork.upLinkNetWork = 'Poor'
      } else {
        this.netWork.upLinkNetWork = 'Mid'
      }
      this.dealNetWorkQuality(this.netWork)
      this.$emit('callback', this.netWork)
    })
  },
  methods: {
    dealNetWorkQuality (netWork) {
      if (
        netWork.upLinkNetWork === 'Good' &&
        netWork.downLinkNetWork === 'Good'
      ) {
        this.netWorkIconColor = 'Green'
      } else if (
        netWork.upLinkNetWork === 'Good' &&
        netWork.downLinkNetWork === 'Mid'
      ) {
        this.netWorkIconColor = 'Orange'
      } else if (
        netWork.upLinkNetWork === 'Mid' &&
        netWork.downLinkNetWork === 'Good'
      ) {
        this.netWorkIconColor = 'Orange'
      } else if (
        netWork.upLinkNetWork === 'Mid' &&
        netWork.downLinkNetWork === 'Mid'
      ) {
        this.netWorkIconColor = 'Orange'
      } else if (
        netWork.upLinkNetWork === 'Poor' ||
        netWork.downLinkNetWork === 'Poor'
      ) {
        this.netWorkIconColor = 'Red'
      }
    }
  }
}
</script>

<style scoped lang="less">
  .networksQuality {
    display: inline-block;
    margin: 0 10px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    background-color: #232327;
    border-radius: 4px;
    border: 1px solid #303035;
    box-sizing: border-box;
    text-align: left;
    span {
      width: 3px;
      background: #F6445A;
      border-radius: 1px;
      display: inline-block;
      margin-right: 2px;
      &:nth-child(1) {
        height: 5px;
        margin-left: 4px;
      }
      &:nth-child(2) {
        height: 9px;
      }
      &:nth-child(3) {
        height: 13px;
      }
      &.bgRed {
        background-color: #F6445A;
      }
      &.bgOrange {
        background-color: #F5A623;
      }
      &.bgGreen {
        background-color: #33AB4F;
      }
    }
  }
</style>
