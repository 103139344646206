import $ from 'jquery'
import Store from '@/store/index.js'
import Bus from '@/assets/js/bus.js'
import Cookies from 'js-cookie'
import axios from 'axios'
import { $http } from '@/assets/js/axiosExtend.js'
// import Router from '@/router'
import { audioContextObj } from '@/assets/js/audioContext'
const moment = require('moment')
/* 时间转换
2017 ===> 2017-12-31 23:59:59;
2017-5 ===> 2017-5-31 23:59:59
timeString: 时间字符串
isStart: 是否开始时间，否,则是结束时间
separator: 字符串分隔符 */
const formatTimeStartEnd = (timeString, isStart, separat) => {
  if (!timeString) return
  timeString = moment(timeString).format('YYYY-MM-DD')
  let arr = []
  let target = 0
  let monthNum = ''
  let separator = ''
  separator = separat || '-'

  arr = timeString.split(separator)
  if (arr.length === 1) {
    // 年：2017
    if (isStart) {
      // 开始时间
      timeString = timeString + '/01/01 00:00:00'
    } else {
      // 结束时间
      timeString = timeString + '/12/31 23:59:59'
    }
  } else if (arr.length === 2) {
    // 年月:2017-05

    if (isStart) {
      timeString = timeString + separator + '01 00:00:00'
    } else {
      monthNum = new Date(arr[0], arr[1], 0).getDate() + ''
      timeString = timeString + separator + monthNum + ' 23:59:59'
    }
  } else if (arr.length === 3) {
    // 年月日:2017-05-25

    if (isStart) {
      timeString = timeString + ' 00:00:00'
    } else {
      timeString = timeString + ' 23:59:59'
    }
  } else {
    return false
  }
  target = new Date(timeString).getTime()
  return target
}
const dynamicLoadJs = function (url, callbacks, id) {
  const head = document.getElementsByTagName('body')[0]
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.id = id || ''
  script.src = url
  if (typeof callbacks == 'function') {
    script.onerror = () => {
      // eslint-disable-next-line node/no-callback-literal
      callbacks && callbacks('failed')
    }
    script.onload = () => {
      if (
        !this.readyState ||
        this.readyState === 'loaded' ||
        this.readyState === 'complete'
      ) {
        callbacks && callbacks('true')
        script.onload = script.onreadystatechange = null
      }
    }
  }
  head.appendChild(script)
}

const timeFormat = (time) => {
  return time < 10 ? `0${time}` : time
}

const getDateTime = (time) => {
  time = time || new Date()
  const year = time.getFullYear()
  const month = this.timeFormat(time.getMonth() + 1)
  const day = this.timeFormat(time.getDate())
  const hour = this.timeFormat(time.getHours())
  const min = this.timeFormat(time.getMinutes())
  const s = this.timeFormat(time.getSeconds())
  return { year: year, month: month, day: day, hour: hour, min: min, s: s }
}

// 获取间隔时间
const getIntervalTime = (start, end) => {
  start = typeof start == 'object' ? start.getTime() : start
  end = typeof end == 'object' ? end.getTime() : end
  const interval = end - start
  if (interval <= 0) {
    return '00:00:00'
  }
  const d = Math.floor(interval / (1000 * 60 * 60 * 24))
  const h = Math.floor((interval / (1000 * 60 * 60)) % 24) + d * 24
  const m = Math.floor((interval / (1000 * 60)) % 60)
  const s = Math.floor((interval / 1000) % 60)
  return `${this.timeFormat(h)}:${this.timeFormat(m)}:${this.timeFormat(s)}`
}

const getUTCTime = (date) => {
  // 返回UTC时间
  return date.getTime() + date.getTimezoneOffset() * 60000
}

// 随机生成32位字符串
const getRandomStr = function (length = 32) {
  const chars = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z'
  ]
  let num = ''
  for (let i = 0; i < length; i++) {
    const id = parseInt(Math.random() * 61)
    num += chars[id]
  }
  return num
}
/**
 * 获取UUID
 * @returns {string}
 */
export function getUUID () {
  function S4 () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }

  return (S4() + S4() + S4() + S4() + S4() + S4() + S4())
}


const setTagCookie = (tagKey, tagValue, exdays) => {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  const expires = d.toUTCString()
  let domain = document.domain.split('.')
  domain = `${domain[1]}.${domain[2]}`
  if (tagKey && tagValue) {
    // 字符串拼接cookie
    window.document.cookie =
      tagKey +
      '=' +
      tagValue +
      ';path=/;domain=' +
      domain +
      ';expires=' +
      expires
  }
}

const getSession = function () {
  return Cookies.get('SID') || localStorage.getItem('commentatorSession') || ''
}

const Tool = {
  dynamicLoadJs (url, callbacks, id) {
    const head = document.getElementsByTagName('body')[0]
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.id = id || ''
    script.src = url
    if (typeof callbacks === 'function') {
      script.onerror = () => {
        callbacks && callbacks('failed')
      }
      script.onload = () => {
        if (
          !this.readyState ||
          this.readyState === 'loaded' ||
          this.readyState === 'complete'
        ) {
          callbacks && callbacks('true')
          script.onload = script.onreadystatechange = null
        }
        try {
          mediaObj.platform = 'Commentator' // 集成项目名称
          mediaObj.shareModule = 'Studio'
          mediaObj.page = 'Commentator'
          mediaObj.supportPlatform = Store.state.config.cc.demandPlatform
          window.mediaObj.loginUrl = `${Store.state.config.urlInfo.userservice}/?url=${location.protocol}//${location.host}/commentator&serviceName=${Store.state.config.serverName}`
        } catch (e) {
          console.log('mediaObj is not defined')
        }
      }
    }
    head.appendChild(script)
  }
}

// 加载mediaCommon
function loadMediaJs (mediaServerUrl, callback) {
  if ($('#mediaCommon').length > 0) {
    typeof callback === 'function' && callback()
  } else {
    // const url = 'https://local.tvunetworks.com:8888/js/mediaCommon.js'
    const url = `${window.location.protocol}//${mediaServerUrl}/js/mediaCommon.js`
    Tool.dynamicLoadJs(
      url,
      function () {
        if (typeof callback === 'function') {
          callback()
        }
      },
      'mediaCommon'
    )
  }
}

const websocketMessage = (event, key) => {
  let module = ''
  let result = {}
  if (key == 'graphql') {
    if (event.payload && event.type == 'data') {
      Bus.$emit('GrapqQLDataChange', event.payload.data)
    }
  } else {
    if (typeof event === 'object') {
      module = event.module
      result = event.result || event.msg || JSON.parse(event.data)
    } else {
      module = JSON.parse(event).module
      result = JSON.parse(event).result
    }
  }
  switch (module) {
    case 'pgmChange':
      Bus.$emit('pgmChange', result);
      break;
    case 'button':
      Bus.$emit('setLoginScreenButton', result);
      break;
    case 'listCurrentDevice':
      Bus.$emit('refreshSourceStatus', result)
      break
    case 'setCrew':
      Bus.$emit('setCrew', result)
      break
    case 'cancelCrew':
      Bus.$emit('cancelCrew', result)
      break
    case 'startPrivateConversation':
      Bus.$emit('startPrivateConversation', result)
      break
    case 'cancelPrivateConversation':
      Bus.$emit('cancelPrivateConversation', result)
      break
    case 'shareScreen':
      Bus.$emit('shareScreen', result)
      break
    case 'cancelShareScreen':
      Bus.$emit('cancelScreen', result)
      break
    case 'setOutput':
      Bus.$emit('setOutput', result)
      break
    case 'cancelOutput':
      Bus.$emit('cancelOutput', result)
      break
    case 'removeParticipant':
      Bus.$emit('removeParticipant', result)
      break
    case 'switchParty':
      Bus.$emit('switchParty', result)
      break
    case 'groupRtilCodes':
      Bus.$emit('groupRtilCodes', result)
      break
    case 'setBehaviors':
      Bus.$emit('setBehaviors', result)
      break
    case "controlBehavior":
      Bus.$emit('controlBehavior', result)
      break;
    case 'endParty':
      Bus.$emit('endParty', result)
      break
    case 'netStatus':
      Bus.$emit('netStatus', result)
      break
    case 'raiseHand':
      Bus.$emit('raiseHand', result)
      break
    case 'denyRaiseHand':
      Bus.$emit('denyRaiseHand', result)
      break
    case 'joinStatus':
      Bus.$emit('joinStatus', result)
      break
    case 'videoQuality':
      Bus.$emit('videoQuality', result)
      break
    case 'setSpeaker':
      Bus.$emit('setSpeaker', result)
      break
    case 'cancelSpeaker':
      Bus.$emit('cancelSpeaker', result)
      break
    case 'setQuestioner':
      Bus.$emit('setQuestioner', result)
      break
    case 'cancelQuestioner':
      Bus.$emit('cancelQuestioner', result)
      break
    case 'setQuestionSpeaker':
      Bus.$emit('setQuestionSpeaker', result)
      break
    case 'cancelQuestionSpeaker':
      Bus.$emit('cancelQuestionSpeaker', result)
      break
    case 'online':
      Bus.$emit('online', result)
      break
    case 'offline':
      Bus.$emit('offline', result)
      break
    case 'setPartyTitle':
      Bus.$emit('setPartyTitle', result)
      break
    case 'rejoin':
      Bus.$emit('rejoin', result)
      break
    case 'setNoSubscribeRtil':
      Bus.$emit('setNoSubscribeRtil', result)
      break
    case 'questionerList':
      Bus.$emit('questionerList', result)
      break
    case 'changeLayout':
      Bus.$emit('changeLayout', result)
      break
    case 'notify':
      Bus.$emit('notify', result)
      break
    case 'managerOnline':
      Bus.$emit('managerOnline', result)
      break
    case 'rtilLiveStatus':
      Bus.$emit('rtilLiveStatus', result)
      break
    case 'showSlotText':
      Bus.$emit('showSlotText', result)
      break
    case 'cancelShowSlotText':
      Bus.$emit('cancelShowSlotText', result)
      break
    case 'slotChange':
      Bus.$emit('slotChange', result)
      break
    case 'sectionSlotNum':
      Bus.$emit('sectionSlotNum', result)
      break
    case 'unlock':
      Bus.$emit('unlock', result)
      break
    case 'bindCohost':
      Bus.$emit('bindCohost', result)
      break
    case 'cancelPreview':
      Bus.$emit('cancelPreview', result)
      break
    case 'lockName':
      Bus.$emit('lockName', result)
      break
    case 'unlockName':
      Bus.$emit('unlockName', result)
      break
    case 'moveParticipants':
      Bus.$emit('moveParticipants', result)
      break
    case 'cancelParticipantTalkBack':
      Bus.$emit('cancelParticipantTalkBack', result)
      break
    case 'setParticipantTalkBack':
      Bus.$emit('setParticipantTalkBack', result)
      break
    case 'cancelTalkBack':
      Bus.$emit('cancelTalkBack', result)
      break
    case 'setTalkBack':
      Bus.$emit('setTalkBack', result)
      break
    case 'setCopyButton':
      Bus.$emit('setCopyButton', result)
      break
    case 'cancelCopyButton':
      Bus.$emit('cancelCopyButton', result)
      break
    case 'setFreeTalk':
      Bus.$emit('setFreeTalk', result)
      break
    case 'cancelFreeTalk':
      Bus.$emit('cancelFreeTalk', result)
      break
    case 'startVoIP':
      Bus.$emit('startVoIP', result)
      break
    case 'stopVoIP':
      Bus.$emit('stopVoIP', result)
      break
    case 'setPrivateTextChat':
      Bus.$emit('setPrivateTextChat', result)
      break
    case 'cancelPrivateTextChat':
      Bus.$emit('cancelPrivateTextChat', result)
      break
    case 'setPublicTextChat':
      Bus.$emit('setPublicTextChat', result)
      break
    case 'cancelPublicTextChat':
      Bus.$emit('cancelPublicTextChat', result)
      break
    case 'partyOnlineStatus':
      Bus.$emit('partyOnlineStatus', result)
      break
    case 'setBackground':
      Bus.$emit('setBackground', result)
      break
    case 'setLogos':
      Bus.$emit('setLogos', result)
      break
    case 'coordination':
      Bus.$emit('coordination', result)
      break
    case 'viewPosition':
      Bus.$emit('viewPosition', result)
      break
    case 'eventUpdate':
      if (key == 'pageSerBusRequestByUseId') {
        Bus.$emit('eventInfoUpdate', result)
      }
      break
    case 'addPipSuccess':
      Bus.$emit('addPipSuccess', result)
      break
    case 'statusChanged':
      Bus.$emit('eventInfoStatus', result)
      break
    case 'eventPage':
      Bus.$emit('eventPageUpdate', result)
      break
    case 'receiverOccupied':
      setTimeout(() => {
        Bus.$emit('receiverOccupied', result)
      }, 500)
      break
    case 'inputChanged':
      Bus.$emit('inputChanged', result)
      break
  }
}
export function showVolume (track, id) {
  if (!track) return
  audioContextObj.closeAudioContext(id)
  const media = new MediaStream()
  media.addTrack(track._mediaStreamTrack)
  audioContextObj.audioTrack(media, id)
  setTimeout(() => {
    audioContextObj.resumeAudio(id)
  }, 1000)
}

const countDown = function (endTimeStamp) {
  // 获取当前时间戳
  const nowTimeStamp = new Date().getTime()
  // 获取时间差值
  const times = (endTimeStamp - nowTimeStamp) / 1000

  // 获取剩余分钟
  let mins = parseInt(times / 60)

  mins = mins < 10 ? '0' + mins : mins

  let seconds = parseInt(times % 60)

  seconds = seconds < 10 ? '0' + seconds : seconds

  return {
    mins,
    seconds
  }
}

export function addTabTitle (info) {
  const { userInfo, useTime } = info
  let name = ''
  if (userInfo.title || userInfo.partyName) {
    name = userInfo.title || userInfo.partyName
  } else {
    if (useTime) {
      name = new Date(userInfo.startPartyTime || userInfo.createTime).toLocaleDateString() + '   ' + new Date(userInfo.startPartyTime || userInfo.createTime).toLocaleTimeString()
    } else {
      name = returnStrByInfo(userInfo.joinCode, userInfo.joinCode.length === 9 ? 3 : 4)
    }
  }
  document.title = i18n.t('common.partyTitle', { name })
}

// 每几位中间加个字符
export const returnStrByInfo = function (currentStr, lengths = 4, addStr = ' ') {
  if (currentStr && typeof currentStr === 'number') { currentStr = String(currentStr) }
  if (!currentStr || typeof currentStr !== 'string') return
  const array = currentStr.split('')
  let _str = ''
  array.forEach((v, i) => {
    if ((i + 1) % lengths === 0 && i != 0) {
      _str += v + addStr
    } else {
      _str += v
    }
  })
  return _str
}
export function updateAmSourceName (info) {
  const { partyCode, coordinationPartyCode, rtilCode, rtilName, eventId, toRtilCode } = info
  if (!partyCode || !rtilCode || !rtilName || !eventId || !toRtilCode) return
  const url = '/commentator-backend/commentator/party/noLogin/updateParticipantName'
  let params = {}
  if (toRtilCode == rtilCode) {
    params = {
      partyCode,
      rtilCode,
      rtilName: rtilName.replace(/(^\s*)|(\s*$)/g, ''), // 去掉首尾空格
      eventId
    }
  } else {
    params = {
      partyCode: coordinationPartyCode,
      rtilCode: toRtilCode,
      rtilName: rtilName.replace(/(^\s*)|(\s*$)/g, ''), // 去掉首尾空格
      eventId
    }
  }
  $http
    .post(url, params, { errorTips: false })
    .then(res => {
      if (res.data.errorCode === '0x0') {
      }
    })
}
export function changeNickname (info, callback) {
  const { senderRtilCode, toRtilCode, nickName, partyCode } = info
  if (!senderRtilCode || !toRtilCode || !partyCode) return
  let params = {}
  let url = ''
  if (senderRtilCode == toRtilCode) {
    params = [
      {
        mark: toRtilCode + '',
        annotation: partyCode,
        key: 'nickName',
        notify: true,
        value: nickName.replace(/(^\s*)|(\s*$)/g, ''), // 去掉首尾空格
        initiator: senderRtilCode
      }
    ]
    url = Store.state.config.urlInfo.partyline + '/partyline-backend/behavior/noLogin/setList'
  } else {
    params = {
      mark: toRtilCode + '',
      annotation: partyCode,
      key: 'nickName',
      notify: true,
      value: nickName.replace(/(^\s*)|(\s*$)/g, ''), // 去掉首尾空格
      initiator: senderRtilCode,
      durable: true
    }
    url = Store.state.config.urlInfo.partyline + `/partyline-backend/partyAction/controlBehavior?partyCode=${partyCode}`
  }
  axios
    .post(url, params)
    .then(res => {
      if (callback && typeof callback == 'function') callback(res)
    })
}

export default {
  formatTimeStartEnd,
  getUUID,
  timeFormat,
  getDateTime,
  getIntervalTime,
  getUTCTime,
  getRandomStr,
  setTagCookie,
  getSession,
  dynamicLoadJs,
  loadMediaJs,
  websocketMessage,
  countDown,
  addTabTitle
}
