export default {
  HostCommon: {
    transceiver: '物理接收机',
    cloudR: '云接收机',
    totalConsumedTime: '当月总用时',
    hs: '时',
    min: '分',
    timeRemain: '剩余时间',
    purchase: '购买',
    productTitle: '云解说',
    notConnect: '无法建立链接,正在尝试中...',
    receiverBackupTips: '（为了保持输出源同步）,请在版本低于7.9的收发器上设置（相同）延迟。',
    participateSetting: '参会设置',
    partyName: '会议名称',
    nickName: '用户名',
    startNow: '开始',
    installAnyWhereText: '打开/安装 TVU Anywhere 应用程序',
    testMic: '测试你的麦克风',
    MicTest: '麦克风测试',
    MicrophoneSelection: '麦克风选项',
    startTesting: '开始测试',
    stopTesting: '停止测试',
    note: '注意:如果你能听到自己的声音，那么麦克风工作正常',
    inputLevel: '输入水平',
    noPermission: '无授权',
    participantPassCode: '参会密码',
    enterPassWord: '输入参会密码',
    caseSensitive: '密码区分大小写',
    accountNotAllowed: '您的账号尚未得到主持人的授权。请先联系主持人。',
    backHome: '回到首页',
    joinParty: '加入',
    incorrectPassword: '密码错误',
    notLogin: '当前会议模式需要被邀请，请先登入.',
    initCameraTips: '当前的设备 {name} 不能正常工作, 请切换其他设备',
    initVideoTrack: '摄像头连接中请等待',
    switchFailed: '切换当前设备失败.',
    reNameSuccess: '重命名成功',
    renameParticipantSuceess: '重命名与会者成功',
    rejoinR: '重新加入R',
    rejoinRText: '此评论者已断开连接。',
    operateSucces: '操作成功'
  },
  operationBtn: {
    AudioOnlyEnabled: '仅音频模式已启用',
    on: '开启',
    off: '关闭',
    camera: '摄像头',
    microphone: '麦克风',
    speaker: '扬声器',
    selectMicrophone: '选择一个麦克风',
    selectCamera: '选择一个摄像头',
    selectSpeaker: '选择一个扬声器',
    shareScreen: '屏幕分享',
    stopShareScreen: '停止屏幕分享',
    chat: '文字聊天',
    tagExplain: '收到全局控制的命令后,带标签的与会者将上主屏幕',
    notAllowed: '声音矩阵开启的时候不允许用私聊功能.'
  },
  personalInfo: {
    usagePage: '使用统计',
    usage: '用户',
    Good: '良好',
    Poor: '较差',
    Mid: '一般',
    helpCenter: '帮助',
    bandWidth: '带宽质量',
    cloudProxy: '云代理',
    onCloud: '云代理已开启',
    offCloud: '云代理已关闭',
    lang: '语言',
    logOut: '退出登录',
    login: '登录',
    register: '注册'
  },
  leftPane: {
    input: '输入源',
    ppPgm: '云导播 PGM',
    ppSlot: '云导播 Slot',
    transGrid: 'TVU Grid',
    upLoadFile: '上传文件',
    extIpSource: '外部  IP源',
    tmpPush: 'RTMP Push',
    pack: '背包',
    sdiIn: 'SDI输入',
    add: '添加',
    inputSelectInputSource: '请选择云导播的输入源。',
    note: '注：',
    noteWords: '活动的输入源始终是被选中的该路源，不论其是否是云导播的输出源。',
    rename: '重命名',
    more: '更多',
    del: '删除',
    upLoadOneFile: '1个文件上传中',
    upLoadFileComplete: '上传完成',
    fileSynchronizedTo: '上传的文件已同步至',
    tvuDriver: 'TVU 云盘',
    renameFileSuccess: '文件重命名成功',
    delTips: '您确定想要删除它吗？',
    cancel: '取消',
    delSuccessTips: '删除成功',
    extIpSourceSetting: '外部IP 源设置',
    sourceName: '源名称',
    lessThanWords: '少于100个字符',
    supportMov: '支持MOV，MP4格式的文件',
    clickBtn: '点击添加按钮，选择',
    fileUpload: '上传文件'
  },
  leftPaneOutPut: {
    output: '输出',
    receiver: '接收机',
    receiverSetting: '接收机设置',
    receiverName: '接收机名字',
    issLive: '接收机直播地址',
    followEvent: '采用事件设置',
    ipOut: 'IP 输出',
    searchReceiver: '搜索接收机',
    searchOutputName: '搜索输出名称',
    selected: '个已选中',
    noAvailableIpout: '没有可用的IP 输出',
    clickAddBtn: '点击添加按钮，添加',
    destination: '输出目的地。',
    noResult: '无结果',
    changeKeyWords: '请修改关键字',
    save: '保存',
    edit: '编辑',
    gridSetting: 'TVU Grid 设置',
    outputName: '输出名称',
    inputName: '请填写输出名称',
    bitrate: '码率',
    resolution: '清晰度',
    streamType: '推流类型',
    all: '全部',
    todo: '待开始',
    preparing: '准备中',
    inprogress: '进行中',
    stopping: '停止中',
    stop: '已停止',
    failed: '已失败',
    done: '已完成',
    preparingFiveMin: '准备中（5 分钟）',
    programFeed: '制作流（包含叠加效果）',
    cleanFeed: '清流（不包含叠加效果）',
    outputNameRequired: '输出名字必填',
    isspNote: '如果要修改ISSP模,可以在输出ISSP中对其进行编辑.'
  },
  eventList: {
    copy: '复制成功',
    eventId: '事件id:',
    advanced: '高级设置',
    advancedSettings: '高级设置',
    rtmpPush: 'RTMP Push',
    pageSize: '页码：',
    create: '创建',
    central: '混音台中心',
    cloudReceiver: '云接收机',
    physicalReceiver: '物理接收机',
    cloudRecord: '云录制',
    eventName: '活动名称',
    time: '时间',
    status: '状态',
    log: '日志',
    input: '输入',
    output: '输出',
    groupNum: '分组数量',
    operate: '操作',
    stop: '停止',
    view: '查看',
    noData: '无信息',
    stopEvent: '停止活动',
    deleteEvent: '删除活动',
    delTips: '删除成功。',
    stopTips: '确定要停止活动吗？',
    deleteTips: '确定要删除活动',
    theEvent: '活动',
    onGoing: '正在进行中',
    isPreparing: '已准备好',
    allProgramsShutDown: '活动停止后，所有的项目都会终止。',
    stopTipSuccess: '已停止该活动',
    createEvent: '创建活动',
    editEvent: '编辑活动',
    viewAnEvent: '查看活动',
    addTitle: '添加标题',
    startTime: '开始时间',
    endTime: '结束时间',
    now: '此刻',
    duration: '时长',
    reset: '重置',
    pleaseSelect: '请选择源',
    ReceiverSelect: '请选择物理机',
    noneData: '无数据',
    loading: '加载中...',
    selectFile: '选择文件',
    backUp: '备用',
    bkpInput: '备用输入',
    inputSelect: '输入选择',
    mainIn: '主输入',
    backUpIn: '备用输入',
    pgm_out: 'PGM输出',
    apply: '应用',
    outputs: '输出',
    search: '搜索',
    delay: '延时',
    delayTips: '对于良好的网络条件，建议使用低延迟。',
    outputFormat: '输出格式',
    region: '地区',
    remark: '备注',
    addDescription: '添加描述',
    group: '分组',
    groupTips: '分组添加名称后方可生效。',
    addNew: '新增分组',
    addGroupName: '添加本组名称',
    copyGroupLink: '复制组链接',
    advancedAudioMixer: '高级混音操作台',
    copyLink: '复制链接',
    doneN: '完成',
    reuse: '重用',
    createEventTips: '请填写所需文件事件名称/时间/输入/地区/组名称',
    createEventSuccess: '活动创建成功',
    viewEvent: '查看',
    eventTimeTips: '活动时长必须大于10分钟',
    groupLink: '分组链接',
    switchInputSuccess: '切换输入成功',
    serviceEntry: '服务入口',
    countDown: '倒计时',
    stoppedTime: '事件已手动停止于',
    countDownEvent: '活动结束倒计时',
    eventRemaining: '活动持续时间超过剩余时间，请选择合适的时间或续费',
    notSupportSdi: '当前的接收机不支持此功能',
    eventINProgress: '事件进行中，并且处于倒计时中',
    ParticipationSettings: '与会者设置',
    backupReceiver: '备用接收机',
    invited: '邀请模式'
  },
  eventRegions: {
    seoul: '亚太区域（首尔）',
    mumbai: '亚太区域（孟买）',
    paulo: '南美洲（圣保罗）',
    parise: '欧洲（巴黎）',
    frankfurt: '欧洲（法兰克福）',
    tokyo: '亚太区域（东京）',
    virginia: '美国东部（佛吉尼亚北部）',
    sydney: '亚太地区（悉尼）',
    oregon: '美国西部（俄勒冈）',
    bahrain: '中东（巴林）'
  },
  otherWords: {
    viewSetting: '查看设置',
    eventId: '活动Id',
    copySuccess: '复制成功',
    copyLinkTips: '请点击链接加入TVU Remote Commentator: \n',
    endTimeGreater: '结束时间必须大于开始时间',
    min: '分钟',
    hour: '小时',
    selectRegion: '请选择',
    upLoadFileContinueTips: '上传未完成，是否确认取消上传？',
    cancelTheUpload: '取消上传',
    continueToUpload: '继续上传',
    cancelToUpload: '取消上传',
    addExtIpSourceSuccess: '外部 IP 源添加成功',
    nameIsRequired: '源名称必填',
    urlIsRequired: 'URL必填',
    urlValidTips: '您输入的URL不合法，开头必须是',
    fileAndSlotOnlyOne: '上传文件和slot5不能一起选择',
    onlySelectedOnce: '只能被选择一次',
    partyId: '会议ID',
    elasticIpExplain: '弹性IP是为活动分配一个固定的IPv4地址。',
    elasticIp: '弹性IP',
    contactSupport: '请联系 TVU SUPPORT 申请权限',
    selectIp: '选择IP',
    pleaseSelect: '请选择',
    ipOccupiedByProducer: '这个ip已被',
    ofProducer: '云导播占用',
    receiverPlay: '所选的接收机会播放这个视频',
    editSetting: '修改设置',
    copyLink: '粘贴链接'
  },
  coordinationRoom: {
    repeatJoin: '重复加入',
    ok: '确认',
    repeatContent: '当前账号已经在其他地方登入，如果想保留页面控制权，请刷新页面.',
    mainWindow: '主屏',
    talkToAllGroups: '和所有成员通话',
    talkToGroup: '和本组成员通话',
    notJoinR: '未加入R',
    onAir: '播出中',
    offAir: '未播出',
    muted: '静音',
    mutedOff: '评论员麦克风关闭',
    onAirEnabled: '评论员麦克风已启用，混音器输出中的音频已启用',
    offAirEnabled: '评论员麦克风已启用，但混音器输出中的音频已禁用',
    startPlaying: '开始播放',
    eventNameInfo: '活动名称',
    eventTimeInfo: '活动时间',
    groupNumInfo: '分组数量',
    eventInfoInput: '主输入',
    inputRType: 'R 类型',
    cloudReceiver: '云接收机',
    tvuTransceiver: '物理接收机',
    listVersion: '当前列表只展示你账户下的所有接收机',
    bufferSize: '延时时长',
    eventReceiver: '对于事件的开始，接收机需要满足:',
    unavailable: '此R不可用',
    online: '在线',
    receiverVersion: '版本大于等于7.8',
    ableDelyMessage: '高级设置中的“延迟”可用',
    currentVersion: '当前版本:',
    detail: '详情',
    remoteCommentator: '"远程评论员" 功能开启',
    remoteCommentatorErrorInfo: '请在接收器的web配置页面上启用此功能',
    hardwareVersion: '硬件版本vs3500v3+',
    hardwareVersionWarning: '低于要求的硬件版本可能会出现问题',
    hardwareVersionUnknown: '未知：没有结果',
    hardwareVersionNote: '注：建议使用单R',
    ipAndPort: '启用IP和端口映射',
    notFoundIpAndPort: '找不到IP和端口映射',
    mainInput: '主输入',
    backUpInput: '备用输入',
    inputSelectInfo: '输入选择',
    mainIn: '主输入',
    eventBackUpIn: '备用输入',
    eventInfoOutPut: '输出',
    coordinationInputSource: '输入源',
    me: '我',
    isSharing: '正在分享屏幕',
    live: '直播',
    pictureInPicture: '画中画',
    name: '名称',
    corporation: '公司',
    on: '开',
    off: '关',
    cancel: '取消',
    submit: '提交',
    producer: '云导播',
    on_air: '开启后，向评论员显示“播出中“标签',
    settingName: '设置',
    forHostAndCommon: '对于协调室和评论员',
    forHost: '对于协调室',
    PgmOutInput: 'PGM输出',
    AudioChannelSetting: '音频通道设置',
    AudioChannelSettings: '该设置对所有评论员生效',
    mutiple: '多声道',
    stereo: '立体声',
    previewSourceType: '预览类型',
    unavailableSource: '这个源不可预览',
    couldNotAccessCoordination: '进入协调室失败',
    exitCoordinationRoom: '退出协调室',
    accountAccessed: '您的账号已在他处进入协调室。',
    closeOtherAccount: '您是否需要在此处进入，覆盖他处进程？',
    agree: '确认',
    no: '取消',
    nameEmpty: '用户名不能为空.',
    groupDisplay: '分组显示',
    main: '主输入',
    backup: '备用输入',
    pgm: '主输出',
    backupPgm: '备用输出'
  },
  coordinationParticipant: {
    audioSetting: '音频设置',
    reJoin: '重新加入',
    unableJoin: '无法加入',
    partyDisabled: '当前可能已经被删除或者不存在.',
    talkBackFail: '请点击并且长按',
    talkBackConflict: '不能同时使用“全部私聊、协调室私聊、组内私聊”的功能',
    commentatorAbnormalNet: '网络不稳定！由于网络不稳定的原因，您目前没有和项目同步，请刷新或检查您的网络。',
    refresh: '刷新',
    theEventTimeIs: '活动时间是',
    gettingReady: '正在准备...',
    leaveParty: '离开会议',
    turnOffCamera: '关闭摄像头',
    turnOnCamera: '打开摄像头',
    talkBack: '所有人交流',
    talkHostBack: '协调室交流',
    talkPipBack: '本组评论员交流',
    talkBackEnabled: '评论员对讲已启用',
    leavePartyTitle: '离开会议',
    cancel: '取消',
    leave: '离开',
    leavePartyContent: '你确定要离开吗？这将清除输出上的音频分配状态？',
    operationFailed: '启动音/视频失败，请检查话筒/摄像头',
    turnOffSpeaker: '关闭扬声器',
    turnOnSpeaker: '打开扬声器',
    websiteSoundOff: '网站声音关闭',
    notOperateFrequery: '请勿频繁操作。',
    websiteSoundOn: '网站声音开启',
    releaseToEndTalk: '松开按键，退出内部交流',
    clikAndHold: '长按(或长按空格键)与主持人、组聊天',
    clikAndHostHold: '长按(或长按“C”键)与主持人聊天',
    clikAndPipHold: '长按(或长按“G”键)与组聊天',
    turnOnMicPhone: '开启麦克风（加入混音）',
    turnOffMicPhone: '关闭麦克风（退出混音)',
    turnOnHostMicPhone: '开启麦克风',
    turnOffHostMicPhone: '关闭麦克风',
    talentCommentator: '专业解说员',
    mic: '麦克风',
    selectCamera: '选择摄像头',
    selectMicrophone: '选择麦克风',
    selectSpeaker: '选择扬声器',
    bottomDown: '隐藏底部菜单栏',
    bottomUp: '显示底部菜单栏',
    openAEC: '回声',
    openANS: '降噪',
    openAGC: '麦克风增强',
    aecDetail: '回声抵消器',
    ansDetail: '有源噪声消除',
    agcDetail: '自动增益控制',
    autoSwitchNewCameraSuccess: '自动切换摄像头成功',
    autoSwitchNewCameraFailed: '自动切换摄像头失败',
    autoSwitchNewMicSuccess: '自动切换麦克风成功',
    autoSwitchNewMicFailed: '自动切换麦克风失败',
    autoPlayFailed: '谷歌浏览器暂停了视频自动播放是否要恢复播放'
  },
  showAirTag: '展示“播出中”标签',
  HideAirTag: '隐藏“播出中”标签',
  btnText: {
    rename: {
      text: '重命名'
    },
    removeParticipant: {
      text: '移除参会者'
    },
    setCrew: {
      text: '导播沟通员'
    },
    cancelCrew: {
      text: '参会者'
    },
    startPrivateConversation: {
      text: '创建私聊'
    },
    cancelPrivateConversation: {
      text: '挂断私聊'
    },
    replace: {
      text: '替换'
    },
    roleSetting: {
      text: '指定角色'
    },
    setSpeaker: {
      text: '主讲人'
    },
    cancelSpeaker: {
      text: '参会者'
    },
    cancelQuestionSpeaker: {
      text: '参会者'
    },
    hangUpInterview: {
      text: '挂断面谈'
    },
    setQuestionSpeaker: {
      text: '面谈'
    },
    interviewer: {
      text: '面谈'
    },
    setPublicTalk: {
      text: '创建公聊'
    },
    cancelQuestioner: {
      text: '参会者'
    },
    cancelQuestionerRemove: {
      text: '移除队列'
    },
    setQuestioner: {
      text: '参会者'
    },
    addQuestioner: {
      text: '加入提问者队列'
    },
    toTop: {
      text: 'To top'
    },
    participant: {
      text: '参会者'
    },
    denyRaiseHand: {
      text: '移出举手队列'
    },
    setBroadcast: {
      text: '广播员'
    },
    setPublicSpeaker: {
      text: '公共发言人'
    },
    editTagName: {
      text: '编辑标签名'
    },
    fullScreen: {
      text: '全屏'
    },
    moveParty: {
      text: '移动至'
    }
  },
  producer: '云导播',
  mixer: '混音台',
  coordiRoom: '协调室',
  tudn: 'TUDN',
  coordination: '协调室',
  leaveParty: {
    notExist: '会议ID不存在',
    leave: '离开会议',
    removeFromParty: '你已经被主持人移除会议',
    backHome: '回到首页',
    enterPassWord: '输入参会密码',
    alert: '警告',
    eventOverText: '事件已经结束了',
    eventDeleteText: '事件已经被删除了',
    invalidPartyIdText: '会议ID不合法',
    leavePartyText: '你已经离开会议了',
    unableToJoin: '不能加入会议'
  },
  joinPartyWays: {
    clickMenu: '点击上方的菜单',
    openWays: '请使用Safari或者Google浏览器去打开它',
    joinPartyWithBrowser: '如果下载和通过Anywhere加入有问题',
    joinByBrowser: '通过浏览器加入',
    accessByBrowser: '通过浏览器访问',
    or: '或者',
    installAnyWhere: '已安装TVU Anywhere',
    openAppJoinPartyLine: `点击“加入${$cm.joinPartyTitle}”，即可打开应用程序，加入${$cm.joinPartyTitle}.`,
    joinPartyLine: `加入${$cm.joinPartyTitle}`,
    noApplication: '未安装TVU Anywhere',
    toInstallAnyWhere: '点击“前往应用商店下载”，即可下载安装TVU Anywhere',
    downLoadFromStore: '前往应用商店下载'
  },
  advancedSettings: {
    linuxRrequired: '· 此设置仅对7.9+版本的接收器有效。',
    requiredBack: '· 如果您将备份与7.9+版本的接收器一起使用，此设置将对主接收器和备份接收器生效。',
    delayRequired: '· 如果其中任何一个接收器的版本低于7.9，您需要联系支持部门更改该接收器的延迟，以避免不同步。',
    required: '你可以根据需要进行更改。',
    mainWindowSetting: '主屏设置',
    mainWindowSettingNote: '以下设置将对协调室的主屏（包括主输入、备份输入和PGM OUT源）和评论员的主屏生效。',
    bitrate: '比特率',
    resolution: '分辨率',
    framerate: '帧率',
    defaultBitrate: '30(默认)',
    OtherFeatures: '其他功能',
    inputSourceRecordingTitle: '选定的源将被记录在录音服务中',
    SourceNum: '源 {num}',
    outPutBufferFourth: '并能够切换到一个替代的视频/图像',
    outPutBufferThird: '· 如果发生问题，你有时间作出反应',
    outPutBufferSecond: '这主要是为了使操作人员能够审查节目中的任何问题（如亵渎）',
    outPutBufferOne: '· 输出缓冲区允许在实时输出程序中查看的内容和正在编码输出的实际视频之间设置一个时间缓冲区（最长300秒)',
    outPutBufferTitle: '什么是"输出缓冲区"?',
    replayAppTitle: 'Replay app将会获取所有输入源包括评论员',
    pgmRecordingTitle: 'PGM将被记录在录音服务中',
    rpsTwo: '输入源必须为PP slot或PP PGM',
    rpsOne: '必须在RPS获得授权',
    rpsTitle: '必备条件:',
    allowCommentator: 'Allow Commentator to define audio output pair',
    replayApp: '回放',
    pgmRecording: '直播录制',
    inputSourceRecording: '源录制',
    outPutBuffer: '输出缓冲区',
    rps: '远程制作系统',
    enableFeatures: '启用功能',
    pleaseSelect: '如果您需要任何功能，请选择它们',
    encoderTitle: '云解说视频编码设置',
    encoderTips: '当评论者设置为输入源时，将使用所选的分辨率配置。当选择720P及以上的分辨率时，系统将使用双流模式，较小的流为180P用于网络预览，较大的流为以下选择的流用于制作和广播。'
  },
  audioSettings: {
    title: '音频高级设置',
    cancel: '取消',
    save: '刷新以保存'
  },
  mutedPop: {
    title: '你已静音',
    notes: '按"麦克风"按钮混合音频.',
    detailNotes: '或按下“全部对讲”按钮（按住空格键）/“对讲协调”按钮（按下“C”）/“通话组”按钮（单击“G”）开始内部通话。'
  },
  rtmpPush: {
    name: '源名称',
    server: 'Server',
    key: 'Key',
    generate: '获取',
    copy: '复制',
    copyUrl: '复制连接',
    rtmpName: '请输入源名称',
    serverInput: '在程序开始时自动生成',
    keyInput: '请输入key',
    saveTips: '源名称/Key不能为空'
  },
  receiverConflictTips: {
    outPutTitle: '输出-接收机使用时间冲突',
    inPutTitle: '输入-接收机使用时间冲突',
    eventTime: '本次活动时间为',
    receiver: '接收机',
    scheduled: '已',
    by: '被',
    from: '预约使用于',
    coordinateTime: '如有需要，请先联系 ',
    coordinateTimeInput: '如有需要，请先协调接收机的使用时间。',
    goIt: '确认'
  },
  sourceConflictTips: {
    title: 'IP 输出使用时间冲突',
    eventTime: '本次活动时间为',
    receiver: 'IP 输出',
    scheduled: '已',
    by: '被',
    from: '预约使用于',
    coordinateTime: '如有需要，请先协调源的使用时间。',
    goIt: '确认'
  },
  preview: {
    settingTitle: '解说员设置',
    groupName: '组名称',
    joinNow: '加入',
    partyId: 'Party ID',
    noPartyCode: '没有可以加入会议的ID',
    nameCannotEmpty: '请在继续之前输入您的昵称和会议名称.',
    partyDisabled: '事件未运行，会议被禁用',
    partyHasDelete: '当前会议已经被删除.',
    partyIdNotExist: '会议ID不存在'
  },
  invite: {
    receivedCohost: '收到的邀请',
    invite: '邀请',
    getLink: '链接',
    shareTo: '分享给',
    copyLink: '复制链接',
    saveInvite: '保存',
    anyoneJoin: '任何人可通过链接加入会议',
    onlyUserJoin: '仅已添加至列表的用户可加入会议',
    onlyByPasscode: '通过密码加入会议',
    emailNotation: '邮件通知',
    emailCorrectly: '请输入正确的邮箱地址。',
    remove: '移除',
    inviteCohoster: '添加主持人搭档',
    invitePlaceholder: '添加参会者的邮箱',
    inviteSetSuccess: '设置成功',
    inviteSetCopySuccess: '设置成功,密码已复制',
    inviteDeleteSuccess: '删除成功',
    passCode: '密码',
    copyPassCode: '复制密码',
    passWordText: '要求6位密码',
    plsInpPasscode: '请输入密码',
    setPasscodeSuccess: '设置密码成功',
    wrongPasscodeFormat: '请输入正确的密码格式',
    alreadyInvited: ' 已经邀请过了'
  },
  receiverControl: {
    title: '远程评论员/评论员连接状态',
    refreshTips: 'T这些状态每5s更新一次.',
    eventName: '事件名称',
    eventNameTips: '显示正在进行中的事件名称',
    groupName: '组的名字',
    commentatorName: '与会者名字',
    statusPartyline: '和会议的链接状态',
    mainInStatusReceiver: '和主接收机的连接状态',
    mainIn: '(主输入)',
    backupIn: '(备用输入)',
    mainReceiverStatusTips: '仅适用于TVU接收机模式',
    backupInStatusReceiver: '和备用接收机的链接状态',
    backupReceiverStatusTips: '当备用模式开启时显示',
    operations: '操作',
    operationsTips: '同时对所有接收机生效',
    addToR: '添加到接收机',
    removeFromR: '从接收机中移除'
  }
}
