import { render, staticRenderFns } from "./pageLoading.vue?vue&type=template&id=619ddd97&scoped=true&"
var script = {}
import style0 from "./pageLoading.vue?vue&type=style&index=0&id=619ddd97&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "619ddd97",
  null
  
)

export default component.exports