<template>
  <div class="input" v-if="info.url == '/commentator-backend/receiver/rtmp'">
    <el-input
      v-model="rtmpName"
      :placeholder="$t('rtmpPush.rtmpName')"
      @focus="openDialog"
      :disabled="disabled"
      :maxlength="32"
      class="sourceName"
    ></el-input>
  </div>
  <div class="select" v-else>
    <el-select
      v-model="selectSource"
      :placeholder="$t('eventList.pleaseSelect')"
      v-scroll="scroll"
      popper-class="black"
      filterable
      :clearable="info.clear ? true : false"
      remote
      :no-match-text="$t('eventList.noneData')"
      :no-data-text="$t('eventList.noneData')"
      :disabled="disabled"
      @change="changeR"
      @visible-change="getList"
      :remote-method="filterFn"
      ref="selectScroll"
      :loading="loading"
      :popper-append-to-body="false"
    >
      <el-option
        v-for="item in list"
        :key="item.peerId"
        :disabled="item.disabled && backupBtnVal"
        :label="item.name"
        :value="item.peerId"
      >
        <div @click="clickItem(item)">{{ item.name }}</div>
      </el-option>
      <p class="loading-txt" v-if="scroll.loading" style="text-align:center;font-size:15px;color:#909090;">
        <i class="el-icon-loading" style="color:#909090;"></i>
            {{$t('eventList.loading')}}
      </p>
      <div slot="empty" v-if="!scroll.loading && !loading">
        <div class="no-data">
          <p class="gray999">{{$t('leftPaneOutPut.noResult')}}</p>
          <p class="gray666">{{$t('leftPaneOutPut.changeKeyWords')}}</p>
        </div>
      </div>
    </el-select>
    <i
      class="icon el-icon-arrow-up as"
      :class="{ 'is-reverse': reverse, hoverHide: info.clear && selectSource }"
    ></i>
  </div>
</template>
<script>
import { findIndex } from 'lodash'
import { mapState } from 'vuex'
export default {
  props: {
    backupBtnVal: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    eventInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    selectBackupRecord: {
      type: Object,
      default: () => {
        return {}
      }
    },
    backType: {
      type: String,
      default: ''
    },
    receiverInputType: {
      type: String,
      default: () => {
        return ''
      }
    },
    receiverPeerId: {
      type: String,
      default: () => {
        return ''
      }
    },
    info: {
      type: Object,
      default: () => {
        return {}
      }
    },
    type: {
      type: String,
      default: () => {
        return ''
      }
    },
    disabled: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    disabledList: {
      type: Array,
      default: () => {
        return []
      }
    },
    disabledMessage: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  computed: {
    ...mapState({
      State: (state) => state
    })
  },
  data () {
    return {
      loading: false,
      rtmpName: '',
      selectSource: '',
      list: [],
      // selectId: '',
      reverse: false,
      scroll: {
        onScroll: this.getNextPage,
        loading: false,
        noMore: false,
        directive: '.el-select-dropdown__wrap'
      },
      params: {
        pageNum: 1,
        pageSize: 20,
        condition: '',
        living: true,
        online: true,
        offline: true,
        noCamera: true,
        receiver: true
      }
    }
  },
  async created () {
    await this.getList()
  },
  methods: {
    clickItem (item) {
      if (item.disabled && !this.disabledMessage && this.backupBtnVal) {
        this.$message.error(`${item.name} ` + this.$t('otherWords.onlySelectedOnce'))
      } else if (this.disabledMessage && item.peerId === 's5' && this.backupBtnVal) {
        this.$message.error(this.disabledMessage)
      }
    },
    changeR () {
      this.info.id = this.selectSource
      this.list.forEach((v) => {
        if (v.peerId == this.selectSource) {
          this.$emit('callback', { id: this.selectSource, name: v.name })
        }
      })
    },
    getNextPage () {
      if (this.scroll.loading || this.scroll.noMore) return
      this.info.params.pageNum += 1
      this.scroll.loading = true
      this.getList('', 'nextPage')
    },
    filterFn (val) {
      this.info.params.condition = val
      this.info.params.pageNum = 1
      this.scroll.noMore = false
      this.getList()
    },
    openDialog () {
      if (this.backType != 'backup') {
        this.$emit('openRtmp', this.backType)
      } else {
        this.$emit('openRtmpBackUp', this.backType)
      }
    },
    async getList (val, nextPage) {
      if (this.info.url == '/commentator-backend/receiver/rtmp') {
        if (this.backType != 'backup') {
          this.rtmpName = this.State.rtmpName
        } else {
          this.rtmpName = this.State.rtmpNameBackup
        }
        return
      }
      if (val === false) {
        this.reverse = false
        return
      }
      if (!nextPage) {
        this.loading = true
      } else {
        this.loading = false
      }
      if (val === true) {
        this.info.params.condition = ''
        this.info.params.pageNum = 1
        this.scroll.noMore = false
        this.list = []
        this.reverse = true
      }
      if (!this.receiverPeerId && this.receiverInputType === 'sdi') return
      await this.$http.post(this.info.url, this.info.params).then((res) => {
        this.scroll.loading = false

        let list = []
        if (this.type === 'sdi') {
          list = res.data.result
        } else {
          if (res.data.result) {
            list = res.data.result.list
          }
        }
        if (res.data.errorCode == '0x0') {
          // slot类型 特殊处理
          if (this.type == 'slot') {
            this.list = res.data.result && res.data.result.map((v, index) => {
              return {
                name: `Slot${index + 1}`,
                peerId: v,
                disabled: !!this.disabledList.includes(v)
              }
            })
            this.getSourceId()
            return
          }
          if (list) {
            list.forEach((v) => {
              if (this.info.label) {
                v.name = v[this.info.label]
              }
              if (this.info.value) {
                v.peerId = v[this.info.value]
              }
            })
          }
          if (this.info.params.pageNum >= res.data.result.pages) {
            this.scroll.noMore = true
          }
          if (this.info.params.pageNum > 1) {
            // if (this.selectSource) {
            const index = findIndex(list, { peerId: this.selectSource })
            if (index >= 0) {
              list.splice(index, 1)
            }
            // }
            this.list = this.list.concat(list)
          } else {
            this.list = list
          }
          this.getSourceId(val)
          // 1.给pack和grid添加disabled
          // 2.pack和grid和slot类型一样不能选择同一个源创建事件
          // 3.file同种类型的都不可创建
          // 4.剩余类型可以使用重复的源创建事件
          if (this.type == 'vfb' || this.type == 'pack') {
            if (res.data.result) {
              this.list = this.list && this.list.map((v, index) => {
                return {
                  ...v,
                  disabled: !!this.disabledList.includes(v.peerId)
                }
              })
            }
          }
        }
      })
      if (!nextPage) {
        this.loading = false
      } else {
        this.loading = false
      }
    },
    getSourceId (val) {
      // 当选中的source在第二页之后会显示id，这是需要重新赋值
      let sourceBtn = false
      if (this.list) {
        this.list.forEach((v) => {
          if (v.peerId == this.info.id) {
            sourceBtn = true
          }
        })
      }
      if (val !== true) {
        let changeName = ''
        this.eventInfo.inputList && this.eventInfo.inputList.forEach((v) => {
          if (v.value.id && v.value.id == this.info.id) {
            changeName = v.value && v.value.changeName
          }
        })
        if (!sourceBtn) {
          this.selectSource = changeName || this.info.name || this.info.id
        } else {
          this.selectSource = changeName || this.info.id
        }
      }
    },
    setSelectSourceDefault () {
      this.selectSource = ''
    }
  }
}
</script>
<style lang="less" scoped>
.select {
  display: inline-block;
  width: 100%;
  position: relative;
  .el-select {
    width: 100%;
  }
  &:hover {
    .hoverHide {
      display: none;
    }
  }
  /deep/.el-select-dropdown {
    .el-select-dropdown__item {
      white-space: unset !important;
      &.is-disabled {
        color: #999;
        &:hover {
          background: none !important;
        }
      }
    }
  }

  .icon {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #c0c4cc;
    font-size: 14px;
    transition: transform 0.3s;
    transform: rotateZ(180deg);
    cursor: pointer;
    &.is-reverse {
      transform: rotateZ(0);
    }
  }
  .no-data {
    padding: 10px 0;
    text-align: center;
    font-size: 14px;
    .gray999 {
      color: #999;
    }
    .gray666 {
      color: #666;
    }
  }
}
</style>
