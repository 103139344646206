<template>
  <div class="type-select-box">
    <!-- <i class="el-icon-caret-bottom"></i> -->
    <el-select
      v-model="val"
      :disabled="disabled"
      :clearable="clearable"
      :placeholder="placeholder"
      :filterable="filterable"
      @change="change"
      :popper-append-to-body="false"
      @visible-change="visibleChange"
    >
      <el-option-group
        v-if="type == 'group'"
        v-for="(item, index) in data"
        :key="index"
        :label="item.label"
      >
        <el-option
          v-for="item in item.options"
          :key="item.value"
          :label="item.label | showSelected"
          :disabled="item.disabled"
          :value="item.value"
        >
          <div @click="clickItem(item)">{{ item.label | showSelected}}</div>
        </el-option>
      </el-option-group>
      <el-option
        v-else
        v-for="item in data"
        :key="item.value"
        :label="item.label"
        :value="item.value"
        :disabled="item.disabled"
      ></el-option>
    </el-select>
  </div>
</template>
<script>
/* data 列表数据，必须包含label value [{label:'summer',value:2}], label用来显示， value选中的值
placeholder 文案
callback 筛选后的回调
selectVal 默认选中值,可用来记录用户行为,默认不传为空
filterable 是否可搜索 默认不传为false
noTranslate 是否需要翻译 默认需要 */
import i18n from '@/lang/index.js'
export default {
  props: {
    type: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    visible: {
      // 下拉显示隐藏
      type: Function,
      default: function () {}
    },
    callback: {
      // 筛选后的回调
      type: Function,
      default: function () {}
    },
    placeholder: String,
    data: Array,
    selectVal: [String, Number],
    filterable: {
      type: Boolean,
      default: false
    },
    noTranslate: {
      type: Boolean,
      default: false
    },
    disabledMessage: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  data () {
    return {
      val: this.selectVal || ''
    }
  },
  watch: {
    selectVal: function (val) {
      this.val = val
    }
  },
  created () {
  },
  filters: {
    showSelected (name) {
      if (!name) return ''
      if (name === 'PP PGM') {
        return i18n.t('leftPane.ppPgm')
      } else if (name === 'PP Slot') {
        return i18n.t('leftPane.ppSlot')
      } else if (name === 'TVU Transceiver - Grid') {
        return i18n.t('leftPane.transGrid')
      } else if (name === 'Upload files') {
        return i18n.t('leftPane.upLoadFile')
      } else if (name === 'EXT IP Source') {
        return i18n.t('leftPane.extIpSource')
      } else if (name === 'Pack') {
        return i18n.t('leftPane.pack')
      } else if (name === 'RTMP Push') {
        return i18n.t('leftPane.tmpPush')
      } else {
        return name
      }
    }
  },
  methods: {
    clickItem (item) {
      // // 再次点击也执行回调
      // this.$emit('callBack', item.value)
      if (item.disabled && !this.disabledMessage) {
        // :disabledMessage="`${sourceTypePamrs[backupType].label} can only be selected once`"
        this.$message.error(`${item.label} ` + this.$t('otherWords.onlySelectedOnce'))
      } else if (this.disabledMessage && item.value === 'file') {
        this.$message.error(this.disabledMessage)
      }
    },
    change (item) {
      this.callback(this.val)
    },
    visibleChange (val) {
      this.visible(val)
    }
  }
}
</script>
<style lang="less" scoped>
.m-b-20 {
  margin-bottom: 20px;
  text-align: left;
  span {
    display: inline-block;
    width: 100px;
    text-align: right;
  }
}
.type-select-box {
  /deep/.el-select {
    width: 100%;
    .el-scrollbar__wrap {
      overflow:hidden !important;
      margin-bottom: 0px !important;
      margin-right: 0px !important;
    }
  // ::-webkit-scrollbar {
  //   /*滚动条整体样式*/
  //   width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
  //   height: 10px;
  // }
  // ::-webkit-scrollbar-thumb {
  //   /*滚动条里面小方块*/
  //   border-radius: 10px;
  //   background: rgba(255, 255, 255, 0.3);
  // }
  // ::-webkit-scrollbar-track {
  //   /*滚动条里面轨道*/
  //   border-radius: 10px;
  //   background: none;
  // }
    .el-input__inner {
      background: #010101;
      border: 1px solid #010101;
      color: #fff;
      height: 30px;
      line-height: 30px;
    }
  }
  /deep/.el-select-dropdown {
    background: #010101;
    border: none;
    text-align: left;
    left: 0 !important;
    position: absolute !important;
    top: 20px;
    .el-select-dropdown__item {
      color: #fff;
      font-weight: 700;
      &.selected {
        color: #03897b;
        background: none;
      }
      &.hover {
        background: rgba(3, 137, 123, 0.25) !important;
      }
      &.is-disabled {
        color: #999;
        &:hover {
          background: none;
        }
      }
    }
    .el-select-group__wrap {
      &:not(:last-of-type) {
        &::after {
          background: #28282b;
        }
      }
    }
  }
  /deep/ .el-popper .popper__arrow {
    display: none;
  }
  /deep/ .el-input__icon {
    line-height: 30px;
  }
}
</style>
