<template>
  <div class="header">
    <tvu-top-header
      projectName="Remote Commentator"
      :globalFunList="['waffle','account', 'helpCenter' ]"
      :multiAccountList="multiAccountList"
      :userAuthUrl="Config.urlInfo.userAuthUrl"
      theme="dark"
      :versionServiceNameList="serviceNameList"
      :serviceVesion="[{
        serviceName: 'Remote Commentator',
        frontendVersion: fontendVersion,
        backendVersion: backendVersion,
        tagName: $store.state.config.applicationName
      }]"
      @callback="topHeaderCallback">
      <!-- 自定用账号下拉的中间模块 -->
      <template #account-center-module>
        <div class="contaniner login-info">
          <!-- 云代理按钮 -->
          <div class="cloudProxy" v-if="$route.name == 'commentator'" >
            <div class="left">{{$t('personalInfo.cloudProxy')}}</div>
            <div class="right">
              <el-switch
                v-if="loadingShow"
                v-model="cloudProxy"
                @change="changeCloudProxy"
                active-color="#03897B"
                inactive-color="#9F9F9F">
              </el-switch>
              <el-button v-else size="mini" round :loading="true" style="width:40px !important;height:20px !important;border-color: rgb(3, 137, 123) !important;background-color: rgb(3, 137, 123); !important"></el-button>
            </div>
          </div>
          <div class="language" v-if="State.switchLang">
            <div class="left">
              {{$t('personalInfo.lang')}}
            </div>
            <div class="right">
              <span
                :class="{ zhColor: $i18n.locale === 'zh'}"
                @click="changeLanguage('en')"
                >EN</span
              >
              <span class="second">/</span>
              <span
                :class="{ zhColor: $i18n.locale !== 'zh' }"
                @click="changeLanguage('zh')"
                >中文</span
              >
            </div>
          </div>
          <div class="usagePage" v-if="$route.name!= 'usage'">
            <a  class="left" style="width: 270px;" :href="`${protocol}//${domain}/commentator/usage`" target="_blank">
              {{$t('personalInfo.usagePage')}}
            </a>
          </div>
        </div>
      </template>
      <template #top-header-middle>
        <div class="countDown" v-if="isShowCountDown">
          <p class="title">{{$t('eventList.countDown')}}</p>
          <p class="digitalCount">
            <span class="mins">{{countDownTime.mins }}</span>
            <span class="colon">:</span>
            <span class="seconds">{{countDownTime.seconds}}</span>
          </p>
        </div>
      </template>
      <!-- 自定义整个服务的私有功能（可以包括或者不包括多账号ui， 提供默认样式） -->
      <template #right-app-module v-if="$route.name != 'usage'">
         <!-- 显示网络状况 -->
        <div class="item-fun" v-if="$route.name == 'coordination'">
        <div class="netWorkTip">
          <el-popover popper-class="netWorkTips" effect="dark" placement="bottom-start" :visible-arrow="false">
            <div>
              <h6>{{$t('personalInfo.bandWidth')}}</h6>
              <p class="upLink">
                <i class="el-icon-top" :class="colorUp"></i>
                <span :class="colorUp">{{$t(`personalInfo.${netWork.upLinkNetWork}`)}}</span>
              </p>
              <p class="downLink">
                <i class="el-icon-bottom" :class="colorDown"></i>
                <span :class="colorDown">{{$t(`personalInfo.${netWork.downLinkNetWork}`)}}</span>
              </p>
            </div>
            <NetWorkQuality slot="reference" @callback="showNetWork"></NetWorkQuality>
          </el-popover>
        </div>
        </div>
        <div class="item-fun">
           <!-- 新增需求云R和linuxR -->
          <div class="customTime">
            <el-popover
              placement="bottom-start"
              trigger="click"
              popper-class="count-content"
              class="contaniner"
              v-model="showCountContent"
            >
              <div class="contaniner">
                <div @click="selectCount('pp')" class="ppCount"  :class="{selectContent: selectPpCount}">
                  <div class="titleContent">
                    <div>{{$t('HostCommon.cloudR')}}</div>
                    <i class="el-icon-check" v-show="selectPpCount"></i>
                  </div>
                  <div class="timeContent">
                    <li class="first">
                      <div class="top">{{$t('HostCommon.totalConsumedTime')}}</div>
                      <div class="buttom">
                        <span class="time">{{ totalConsumedTimeHours }}</span
                        >&nbsp;<span class="unit">&nbsp;{{$t('HostCommon.hs')}}</span>&nbsp;
                        <span class="time">{{ totalConsumedTimeMin }}</span
                        >&nbsp;<span class="unit">{{$t('HostCommon.min')}}</span>
                      </div>
                    </li>
                    <li class="Remaining" v-if="userTypeInfo !== 3">
                    <div class="top">{{$t('HostCommon.timeRemain')}}</div>
                    <div class="buttom">
                      <span class="time">{{ remainingTimeHours }}</span
                      >&nbsp;<span class="unit">{{$t('HostCommon.hs')}}</span>&nbsp;
                      <span class="time">{{ remainingTimeMin }}</span
                      >&nbsp;<span class="unit">{{$t('HostCommon.min')}}</span>
                    </div>
                  </li>
                </div>
                </div>
                <el-divider/>
                <div @click="selectCount('linuxR')" class="linuxRCount"   :class="{selectContent:!selectPpCount}" >
                  <div class="titleContent">
                    <div>{{$t('HostCommon.transceiver')}}</div>
                    <i class="el-icon-check" v-show="!selectPpCount"></i>
                  </div>
                  <div class="timeContent">
                    <li class="linuxFirst">
                      <div class="top">{{$t('HostCommon.totalConsumedTime')}}</div>
                      <div class="buttom">
                        <span class="time">{{ totalConsumedLinuxRTimeHours }}</span
                        >&nbsp;<span class="unit">&nbsp;{{$t('HostCommon.hs')}}</span>&nbsp;
                        <span class="time">{{ totalConsumedLinuxRTimeMin }}</span
                        >&nbsp;<span class="unit">{{$t('HostCommon.min')}}</span>
                      </div>
                    </li>
                  </div>
                </div>
              </div>
              <div class="ppCount"  v-if="selectPpCount" slot="reference">
                <i v-if="!showCountContent" class="el-icon-arrow-down"></i>
                <i v-else class="el-icon-arrow-up"></i>
                <li class="first">
                  <div class="top">{{$t('HostCommon.totalConsumedTime')}}</div>
                  <div class="buttom">
                    <span class="time">{{ totalConsumedTimeHours }}</span
                    >&nbsp;<span class="unit">&nbsp;{{$t('HostCommon.hs')}}</span>&nbsp;
                    <span class="time">{{ totalConsumedTimeMin }}</span
                    >&nbsp;<span class="unit">{{$t('HostCommon.min')}}</span>
                  </div>
                </li>
                <li class="Remaining" v-if="userTypeInfo !== 3">
                  <div class="top">{{$t('HostCommon.timeRemain')}}</div>
                  <div class="buttom">
                    <span class="time">{{ remainingTimeHours }}</span
                    >&nbsp;<span class="unit">{{$t('HostCommon.hs')}}</span>&nbsp;
                    <span class="time">{{ remainingTimeMin }}</span
                    >&nbsp;<span class="unit">{{$t('HostCommon.min')}}</span>
                  </div>
                </li>
                <li class="purchase">
                  <el-button size="mini" type="warning" @click.stop="goPurchase"
                    >{{$t('HostCommon.purchase')}}</el-button
                  >
                </li>
              </div>
              <div class="linuxRCount" v-else slot="reference">
                <i v-if="!showCountContent" class="el-icon-arrow-down"></i>
                <i v-else class="el-icon-arrow-up"></i>
                <li class="first">
                  <div class="top">{{$t('HostCommon.totalConsumedTime')}}</div>
                  <div class="buttom">
                    <span class="time">{{ totalConsumedLinuxRTimeHours }}</span
                    >&nbsp;<span class="unit">&nbsp;{{$t('HostCommon.hs')}}</span>&nbsp;
                    <span class="time">{{ totalConsumedLinuxRTimeMin }}</span
                    >&nbsp;<span class="unit">{{$t('HostCommon.min')}}</span>
                  </div>
                </li>
              </div>
            </el-popover>
          </div>
        </div>
      </template>
    </tvu-top-header>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Cookie from 'js-cookie'
import NetWorkQuality from '@/components/common/netWorkQuality'
import { loadSudoku } from '@/assets/js/loadSudoku.js'
import Tools from '@/assets/js/utils.js'
import Bus from '@/assets/js/bus.js'
export default {
  components: {
    NetWorkQuality
  },
  data () {
    return {
      protocol: window.location.protocol,
      domain: document.domain,
      multiAccountList: [],
      showCountContent: false,
      selectPpCount: true,
      netWork: {
        upLinkNetWork: 'Good',
        downLinkNetWork: 'Good'
      },
      loadingShow: true,
      serviceNameList: [],
      partyCode: '',
      eventId: '',
      cloudProxy: false,
      AudioMixer: {
        tagName: '',
        backendVersion: '',
        frontendVersion: ''
      },
      Producer: {
        tagName: '',
        backendVersion: '',
        frontendVersion: ''
      },
      MediaService: {
        tagName: '',
        backendVersion: '',
        frontendVersion: ''
      },
      Partyline: {
        tagName: '',
        backendVersion: '',
        frontendVersion: ''
      },
      fontendVersion: '',
      userId: '',
      totalConsumedTimeHours: '',
      totalConsumedTimeMin: '',
      totalConsumedLinuxRTimeHours: '',
      totalConsumedLinuxRTimeMin: '',
      remainingTimeHours: '',
      remainingTimeMin: '',
      backendVersion: '',
      countDownTime: {
        mins: '',
        seconds: ''
      },
      isShowCountDown: false,
      timeIntervals: null,
      startCountDownStamp: 20 * 60 * 1000,
      logoSvg: ''
    }
  },
  computed: {
    ...mapState({
      State: (state) => state,
      Config: (state) => state.config,
      userInfo: (state) => state.userInfo,
      userTypeInfo: (state) => state.userTypeInfo,
      websocket: (state) => state.socket
    }),
    colorUp () {
      if (!this.netWork.upLinkNetWork) return ''
      if (this.netWork.upLinkNetWork === 'Good') {
        return 'colorGreen'
      } else if (this.netWork.upLinkNetWork === 'Mid') {
        return 'colorOrange'
      } else if (this.netWork.upLinkNetWork === 'Poor') {
        return 'colorRed'
      } else {
        return 'colorGreen'
      }
    },
    colorDown () {
      if (!this.netWork.downLinkNetWork) return ''
      if (this.netWork.downLinkNetWork === 'Good') {
        return 'colorGreen'
      } else if (this.netWork.downLinkNetWork === 'Mid') {
        return 'colorOrange'
      } else if (this.netWork.downLinkNetWork === 'Poor') {
        return 'colorRed'
      } else {
        return 'colorGreen'
      }
    }
  },
  async created () {
    // 获取cloudProxy按钮状态
    this.$http.get('/commentator-backend/userSetting/list')
      .then(res => {
        if (res.data.errorCode === '0x0') {
          if (res.data.result) {
            this.cloudProxy = res.data.result.cloudProxy;
          }
        }
      })

    // 显示各个依赖服务的版本号
    const that = this
    this.serviceNameList = this.$store.state.config.serviceNames
    this.$backAndFrontVersion({
      ...this.$TVU,
      params: {
        serviceNameList: this.serviceNameList
      },
      success: function (val) {
        val.forEach((vals, index, val_arr) => {
          if (val_arr[index].serviceName == 'Producer') {
            that.Producer = val_arr[index]
          } else if (val_arr[index].serviceName == 'Partyline') {
            that.Partyline = val_arr[index]
          } else if (val_arr[index].serviceName == 'Audio Mixer') {
            that.AudioMixer = val_arr[index]
          } else {
            that.MediaService = val_arr[index]
          }
        });
      },
      error: function (val) {
      }
    })
    loadSudoku()
    this.getRegion()
    this.userId = await this.$store.dispatch('userInfo')
    await this.$store.dispatch('getUserType')
    // 显示前端版本号
    let frontVersion = localStorage.getItem('partylineCommentatorVersion')
    if (frontVersion.includes('_')) {
      frontVersion = frontVersion.substring(0, frontVersion.indexOf('_'))
    }
    this.fontendVersion = 'Front ' + frontVersion
    // 获取后端版本号
    await this.$store.dispatch('getBackEndVersion')
    let backVersion = localStorage.getItem('backendVersion')
    if (backVersion.includes('_')) {
      backVersion = backVersion.substring(0, backVersion.indexOf('_'))
    }
    this.backendVersion = 'Backend ' + backVersion
    this.getComuseHours()
    this.getLinuxRComuseHours()
    // 创建Websocket
    this.createWebSocket()
    this.send()

    Bus.$on('startCountDown', (val) => {
      this.startCountDown(val)
    })
  },
  mounted () {
    this.logoSvg = `#${this.State.commonLogo}`
  },
  methods: {
    topHeaderCallback (info) {
      const { type } = info
      if (type === 'signOut') {
        this.logout()
      }
    },
    selectCount (type) {
      if (type == 'pp') {
        this.selectPpCount = true
      } else {
        this.selectPpCount = false
      }
      this.showCountContent = false
    },
    showNetWork (netWork) {
      this.netWork.upLinkNetWork = netWork.upLinkNetWork
      this.netWork.downLinkNetWork = netWork.downLinkNetWork
    },
    // 切换按钮开启/关闭云代理
    changeCloudProxy (value) {
      this.loadingShow = false
      const params = {
        key: 'cloudProxy',
        value: value
      };
      setTimeout(() => {
        this.$http
          .post('/commentator-backend/userSetting/setUserSetting', params)
          .then((res) => {
            if (res.data.errorCode === '0x0') {
              let message = this.$t('personalInfo.onCloud');
              if (this.cloudProxy) {
                //   Cookie.set('initOperateDatas', value, { domain: '.tvunetworks.com' })
              } else {
                //   Cookie.set('initOperateDatas', false, { domain: '.tvunetworks.com' })
                message = this.$t('personalInfo.offCloud');
              }
              this.$message.success(message);
            }
            this.loadingShow = true
          });
      }, 2000)
    },
    changeLanguage (lang) {
      Cookie.set('userLanguage', lang, { domain: '.tvunetworks.com' })
      this.$i18n.locale = lang
      this.State.browserLanguage = lang
    },
    sendWebsocketMessage (once) {
      try {
        this.websocket && this.websocket.send({
          key: 'ppHours',
          time: 60000,
          once: once || false,
          message: {
            serverName: JSON.parse(
              localStorage.getItem('partylineCommentatorConfig')
            ).wsInvokeServerName,
            data: JSON.stringify({
              module: 'ppHours',
              data: JSON.stringify({
                createTimeTo: new Date().getTime(),
                createTimeFrom:
                  new Date().setHours(0, 0, 0, 0) -
                  (new Date().getDate() - 1) * 24 * 60 * 60 * 1000,
                userId: this.userId,
                type: null
              })
            })
          },
          success: (data) => {
            const timeData = data.data
            this.handlePPhours(timeData)
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    createWebSocket () {
      this.sendWebsocketMessage()
    },
    send () {
      this.sendWebsocketMessage(true)
    },
    getRegion () {
      const region = localStorage.getItem('region')
      if (region) return
      this.$http.get('/commentator-backend/user/region').then((res) => {
        if (res.data.errorCode == '0x0') {
          localStorage.setItem('region', res.data.result)
        }
      })
    },
    logout () {
      let domain = '.tvunetworks.com'
      if (!location.host.includes(domain)) {
        domain = '.tvunetworks.cn'
      }
      this.$http.get('/commentator-backend/user/logout').then((res) => {
        if (res.data.errorCode == '0x0') {
          localStorage.removeItem('commentatorSession')
          window.location.href = `${this.Config.urlInfo.userservice}/?url=${location.protocol}//${location.host}/commentator&serviceName=${this.Config.serverName}`
        }
      })
    },
    convertMillisecondsToHoursAndMinutes (milliseconds) {
      let minutes = Math.floor(milliseconds / 60000);
      const hours = Math.floor(minutes / 60);
      minutes = minutes % 60;
      return [
        hours,
        minutes
      ]
    },
    // 处理后台返回的时间
    handleTime (time) {
      if (!time) return ['00', '00']
      const hours = parseInt(time / 3600)
      const usedSeconds = hours * 3600
      const minutes = parseInt((time - usedSeconds) / 60)
      return [
        hours.toString().padStart(2, '0'),
        minutes.toString().padStart(2, '0')
      ]
    },
    // 跳转pp购买页面
    goPurchase () {
      const link = JSON.parse(
        localStorage.getItem('partylineCommentatorConfig')
      ).urlInfo.purchaseLink
      window.open(link, '_blank')
    },
    // 处理websocket通道的ppHours
    handlePPhours (timeData) {
      if (!timeData) return
      this.totalConsumedTimeHours = this.handleTime(timeData.totalConsumeTime)
        ? this.handleTime(timeData.totalConsumeTime)[0]
        : ''
      this.totalConsumedTimeMin = this.handleTime(timeData.totalConsumeTime)
        ? this.handleTime(timeData.totalConsumeTime)[1]
        : ''
      this.remainingTimeHours = this.handleTime(timeData.availableAmount)
        ? this.handleTime(timeData.availableAmount)[0]
        : ''
      this.remainingTimeMin = this.handleTime(timeData.availableAmount)
        ? this.handleTime(timeData.availableAmount)[1]
        : ''
    },
    // 处理websocket通道的LinuxRHours
    handleLinuxRhours (timeData) {
      this.totalConsumedLinuxRTimeHours = this.convertMillisecondsToHoursAndMinutes(timeData.usageDuration)
        ? this.convertMillisecondsToHoursAndMinutes(timeData.usageDuration)[0]
        : ''
      this.totalConsumedLinuxRTimeMin = this.convertMillisecondsToHoursAndMinutes(timeData.usageDuration)
        ? this.convertMillisecondsToHoursAndMinutes(timeData.usageDuration)[1]
        : ''
    },
    // 初始化页面获取ppHours
    async getComuseHours () {
      try {
        const { data } = await this.$http.post('/commentator-backend/event/v2/duration/query', {
          createTimeFrom: new Date().setHours(0, 0, 0, 0) - (new Date().getDate() - 1) * 24 * 60 * 60 * 1000,
          createTimeTo: new Date().getTime(),
          type: null
        })
        this.handlePPhours(data.result)
      } catch (err) {
        console.log(err);
      }
    },
    // 初始化页面获取linuxRHours
    async getLinuxRComuseHours () {
      try {
        const oneMonthAgo = new Date().setHours(0, 0, 0, 0) - (new Date().getDate() - 1) * 24 * 60 * 60 * 1000;
        const { data } = await this.$http.get(`/commentator-backend/eventUsage/myUsageStatistics?startTime=${oneMonthAgo}&endTime=${new Date().getTime()}&platform=${1}`);
        this.handleLinuxRhours(data.result)
      } catch (err) {
        console.log(err);
      }
    },
    startCountDown (endTimeStamp) {
      let currentTime
      if (this.timeIntervals) {
        clearInterval(this.timeIntervals)
      }
      this.timeIntervals = setInterval(() => {
        currentTime = new Date().getTime()
        if ((endTimeStamp - currentTime) < this.startCountDownStamp) {
          this.countDownTime = { ...Tools.countDown(endTimeStamp) }
          this.isShowCountDown = true
          if (typeof this.countDownTime.seconds === 'string') {
            if (this.countDownTime.seconds.indexOf('-') > -1) {
              this.isShowCountDown = false
            }
          }
        } else {
          this.isShowCountDown = false
        }
      }, 1000)
    }
  },
  beforeDestroy () {
    if (this.timeIntervals) {
      clearInterval(this.timeIntervals)
    }
    this.websocket && this.websocket.stop('ppHours')
  }
}
</script>
<style lang="less" scoped>
/deep/.tvu-iconfont .i-help .icon-a-help1{
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/.el-drawer__body{
     &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 6px;
    }
}
  .cloudProxy .right .el-button--mini.is-round{
      padding:3px 14px;
      color:#FFF !important;
 }
.Version :not(:last-child){
  padding-top:3px;
  border-bottom:1px solid #555555;
  padding-bottom:5px;
  }
  .MediaServiceVersion{
    padding-top:3px;
  }
.header {
  .left {
    display: flex;
    align-items: center;
    .logoSvg {
      width: 32px;
      height: 32px;
      margin-right: 16px;
      float: left;
    }
    .logoText {
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      color: #DBDBDB;
    }
  }
  .right {
    display: flex;
    align-items: center;
    .accountDivider, .netWorkTipDivider {
      height: 24px;
      background: #444;
      margin: 0 4px;
    }
    .helpCenter {
      height: 40px;
      width: 40px;
      justify-content: center;
    }
    .serviceList {
      margin-right: 26px;
      #tvu-service-jump-collect {
        width: 40px;
        height: 40px;
        line-height: 40px;
        justify-content: center;
        display: flex;
        align-items: center;
        /deep/.iconBtn {
          width: 44px;
          height: 44px;
        }
      }
    }
  }
}
</style>
