<template>
  <div id="videoControlBox">
    <div class="controlBar">
      <div class="playTime visiable"></div>
      <span class="speakerBox" v-if="userInfo.rtilCode != singChannel.id && singChannel.audioTrack">
        <i
          v-if="!mute"
          class="voiceIcon iconfont icon-speakeron pointer"
          @click="setAudio('mute')"
        ></i>
        <i
          v-else
          class="iconfont icon-speakeroff pointer"
          :class="{ notAllowed: isMute }"
          @click="setAudio('unmute')"
        ></i>
        <el-slider
          class="voiceSlider"
          @input="setVideoAudio"
          v-model="voiceVal"
          :disabled="isMute"
          :min="0"
          :max="100"
        ></el-slider>
      </span>
      <span>
        <i
          v-if="!isFullScreen"
          class="iconfont icon-full_screen pointer"
          @click="fullScreen"
        ></i>
        <i
          v-else
          class="iconfont icon-ExitFullScreen pointer"
          @click="fullScreen"
        ></i>
        <!-- <el-popover
          placement="top-start"
          popper-class="center"
          width="200"
          trigger="click"
          v-model="showPip"
        >
          <div class="pointer" @click="setPictureInpicture">
            {{$t('coordinationRoom.pictureInPicture')}}
          </div>
          <i class="iconfont icon-universal_3-dots pointer" slot="reference"></i>
        </el-popover> -->
        <i class="iconfont icon-huazhonghua-01 pointer" :title="$t('coordinationRoom.pictureInPicture')" @click="setPictureInpicture"></i>
      </span>
      <span v-show="State.fullScreen">
        <el-tooltip  v-if="showFootBar" effect="dark" :content="$t('coordinationParticipant.bottomDown')" placement="top">
          <i class="iconfont icon-down" @click="controlFootBar"></i>
        </el-tooltip>
        <el-tooltip v-else effect="dark" :content="$t('coordinationParticipant.bottomUp')" placement="top">
          <i class="iconfont icon-up" @click="controlFootBar"></i>
        </el-tooltip>
      </span>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      voiceVal: 100,
      showPip: false,
      mute: false,
      isFullScreen: false,
      showFootBar: true
    }
  },
  props: {
    videoObj: {
      type: HTMLDivElement
    },
    singChannel: {
      type: Object,
      default: function () {
        return {}
      }
    },
    userInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    pgmMute: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      isMute: state => state.isMute,
      State: state => state,
      controlMutes: state => state.controlMutes
    })
  },
  watch: {
    isMute: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          this.mute = true
          this.voiceVal = 0
        } else {
          if (!this.pgmMute) {
            this.mute = false
            this.voiceVal = 100
          }
        }
      }
    },
    pgmMute: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          this.mute = true
          this.voiceVal = 0
        } else {
          this.mute = false
          this.voiceVal = 100
        }
      }
    }
  },
  created () {
    Object.keys(this.controlMutes).forEach(value => {
      if (value == this.singChannel.id) {
        this.$nextTick(() => {
          this.mute = this.controlMutes[value]
          if (this.mute) {
            this.voiceVal = 0
            this.singChannel.audioTrack.setVolume(0)
          } else {
            this.voiceVal = 100
            this.singChannel.audioTrack.setVolume(100)
          }
        })
      }
    })
    document.addEventListener('fullscreenchange', (e) => {
      if (!document.fullscreenElement) {
        this.isFullScreen = false
        this.showFootBar = true
        this.State.fullScreen = false
        this.State.showFootBar = true
        this.$emit('callback', { type: 'fullScreen', val: false })
      }
    })
  },
  methods: {
    controlFootBar () {
      this.showFootBar = !this.showFootBar
      this.State.showFootBar = this.showFootBar
      if (!this.showFootBar) {
        this.$emit('callback', { type: 'mouseMove' })
      } else {
        this.$emit('callback', { type: 'removeMouseMove' })
      }
    },
    setVideoAudio (audioVal) {
      if (this.singChannel.audioTrack) {
        audioVal == 0 ? (this.mute = true) : (this.mute = false)
        if (!isNaN(audioVal)) {
          this.singChannel.audioTrack.setVolume(audioVal)
        }
        this.$emit('callback', { type: 'controlAudio', val: this.voiceVal })
      }
    },
    setAudio (key) {
      if (this.isMute) return
      if (this.singChannel.audioTrack) {
        if (key === 'mute') {
          this.mute = true
          this.voiceVal = 0
          this.singChannel.audioTrack.setVolume(0)
        } else {
          this.mute = false
          this.voiceVal = 100
          this.singChannel.audioTrack.setVolume(100)
        }
      }
      this.$emit('callback', { type: 'controlAudio', val: this.voiceVal, newType: 'control' })
    },
    setPictureInpicture () {
      this.showPip = false
      // 如果是自己的话需要单独处理
      if (this.singChannel.id == this.userInfo.rtilCode) {
        document.getElementById('local_stream') && document.getElementById('local_stream').children[0].children[0].requestPictureInPicture()
      } else {
        this.videoObj.children[0] && this.videoObj.children[0].children[0].requestPictureInPicture()
      }
    },
    changeVolumeVal (voiceVal) {
      this.voiceVal = voiceVal
    },
    fullScreen () {
      this.isFullScreen = !this.isFullScreen
      this.$emit('callback', { type: 'fullScreen', val: this.isFullScreen })
    },
    changeTime (time) {
      let s = time
      let m = 0
      let h = 0
      if (s >= 60) {
        m = parseInt(time / 60)
        s = parseInt(time % 60)
        if (m >= 60) {
          h = parseInt(m / 60)
          m = parseInt(m % 60)
        }
      }
      h = h < 10 ? '0' + h : h
      m = m < 10 ? '0' + m : m
      s = s < 10 ? '0' + s : s
      return h + ':' + m + ':' + s
    }
  },
  beforeDestroy () {
    if (this.getTimeTimer) clearInterval(this.getTimeTimer)
  }
}
</script>
<style scoped lang="less">
#videoControlBox {
  opacity: 0;
  // transition: opacity 1s;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: linear-gradient(360deg, black, rgba(0, 0, 0, 0.5), transparent);
  height: 40px;
  line-height: 40px;
  display: flex;
  align-items: center;
  .speakerBox {
    display: flex;
    align-items: center;
  }
  .controlBar {
    display: flex;
    width: 100%;
    color: #fff;
    align-items: center;
    .icon-speakeron {
      font-size: 22px;
      margin-right: 12px;
      margin-top: 2px;
    }
    .icon-speakeroff {
      font-size: 22px;
      margin-right: 12px;
      &.notAllowed {
        cursor: not-allowed;
        color: #999;
      }
    }
    .playTime {
      margin-right: 5px;
      flex: 0.97;
      text-align: left;
    }
    .icon-full_screen {
      margin-right: 20px;
    }
    .icon-huazhonghua-01 {
      margin-right: 20px;
      font-size: 20px;
    }
    .icon-ExitFullScreen {
      font-size: 20px;
      font-weight: bold;
      margin-right: 20px;
    }
    .icon-down, .icon-up {
      font-weight: bold;
      cursor: pointer;
    }
    .voiceSlider {
      width: 54px;
      margin-right: 35px;
      ::v-deep .el-slider__runway {
        height: 4px;
        background-color: #999;
        .el-slider__bar {
          background-color: #fff;
          height: 4px;
          border-radius: 2px;
          opacity: 0.6;
        }
        .el-slider__button-wrapper {
          // transform: translateX(-55%);
          top: -16px;
          left: 40%;
          z-index: 1;
          .el-slider__button {
            width: 12px;
            height: 12px;
            border: none;
          }
        }
      }
    }
    .icon-universal_3-dots {
      position: relative;
      top: -2px;
    }
  }
}
</style>
