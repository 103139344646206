<template>
  <div id="app" :style="{background: appBg}" v-loading="State.newPageLoading"  :class="{enLang: State.browserLanguage === 'en', zhLang: State.browserLanguage === 'zh'}">
    <router-view name="head"></router-view>
    <NetworkConnect v-show="!State.networkConnect"></NetworkConnect>
    <div class="contentBox">
      <router-view />
    </div>
  </div>
</template>
<script>
import Utils from '@/assets/js/utils.js'
import Websocket from '@/assets/js/websocket.js'
import { mapState } from 'vuex'
import Cookie from 'js-cookie'
import NetworkConnect from '@/components/common/network'
export default {
  name: 'App',
  components: {
    NetworkConnect
  },
  data () {
    return {
      appBg: ''
    }
  },
  async created () {
    // 生成clientId
    this.State.clientId = Math.random().toString(36).slice(2);
    // ga('create', Store.state.config.gaCode, 'auto')
    // ga('send', 'pageview')

    this.userId = await this.$store.dispatch('userInfo')
    if (this.userId) {
      this.initWebsocket()
    }
  },
  computed: {
    ...mapState({
      State: state => state
    })
  },
  watch: {
    '$route' (to, from) {
      if (to.name == 'leaveParty' || to.name == 'joinParty' || to.name === 'remove') {
        this.appBg = '#fff'
      } else {
        this.appBg = ''
      }
    }
  },
  methods: {
    // 初始化websocket
    initWebsocket () {
      const row = window.location.protocol === 'http' ? 'ws' : 'wss'
      if (!JSON.parse(localStorage.getItem('partylineCommentatorConfig')) || !JSON.parse(localStorage.getItem('partylineCommentatorConfig')).urlInfo) return
      const ccWebsocket = JSON.parse(
        localStorage.getItem('partylineCommentatorConfig')
      ).urlInfo.ccWebsocket
      const random32Str = Utils.getRandomStr()
      const ws = new Websocket({
        url: `${row}://${ccWebsocket}/pageSerBusRequest?taskId=${random32Str}&businessId=${this.userId}&notCheckUser=true`,
        sendHeart: true,
        key: 'pageSerBusRequestByUseId'
      })
      ws.initWebSocket()
      this.$store.commit('setWebsocket', ws)
    }
  },
  mounted () {
    // if (Cookie.get('SID') && !window.location.href.includes('coordinationParticipate')) {
    //   NineSquared()
    // }
    this.$nextTick(() => {
      const language = Cookie.get('userLanguage') || 'en'
      this.State.browserLanguage = language
    })
  }
}
</script>

<style lang="less">
body {
  // background: #010101;
  padding-right: 0px !important;
}
#app {
  &.enLang {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    input, button {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    }
  }
  &.zhLang {
    font-family: PingFangSC,Microsoft Yahei,Helvetica,Arial,sans-serif;
    input, button {
      font-family: PingFangSC,Microsoft Yahei,Helvetica,Arial,sans-serif;
    }
  }
  background: #010101;
}
.contentBox {
  height: 100vh;
  padding-top:5px;
  background: #0f0f0f;
}
</style>
