/* eslint-disable no-unused-vars */
const mediaStreamSource = {}
const audioContext = {}
const meter = {}
const rafID = {}
const merger1 = {}
const merger2 = {}
const pannerLeft = {}
const pannerRight = {}
const splitter = {}
const inputNode = {}
const gainNodeLeft = {}
const gainNodeRight = {}
const percentageLeft = {}
const percentageRight = {}
const hasResumeAudio = []// 已经Resume()的audio
const currentCreateArr = [] // 当前已经使用audiocontext创建了对象的
const isHadTimer = false
const lastCallbackTimes = {}
window.audioContextDebug = false

/**
 *
 * @param {stream} stream from webrtc or rtil
 * @param remoteKey get stream preview id
 * @param volumeBarIdArr canvas id array  index -0 => left canvas id;  index -1 => right canvas id
 */
function audioTrack (stream, remoteKey, callBack) {
  // drawLoop(remoteKey)
  console.info(30, stream, stream.getAudioTracks())
  if (!stream) return
  const audioStream = new MediaStream()
  audioStream.addTrack(stream.getAudioTracks()[0])
  window.AudioContext = window.AudioContext || window.webkitAudioContext
  audioContext[remoteKey] = new AudioContext()
  mediaStreamSource[remoteKey] = audioContext[
    remoteKey
  ].createMediaStreamSource(audioStream)

  merger1[remoteKey] = audioContext[remoteKey].createChannelMerger(2)
  merger2[remoteKey] = audioContext[remoteKey].createChannelMerger(2)
  inputNode[remoteKey] = audioContext[remoteKey].createGain()
  splitter[remoteKey] = audioContext[remoteKey].createChannelSplitter(2)

  const pannerOptions = { pan: 0 }
  pannerLeft[remoteKey] = new StereoPannerNode(
    audioContext[remoteKey],
    pannerOptions
  )
  pannerRight[remoteKey] = new StereoPannerNode(
    audioContext[remoteKey],
    pannerOptions
  )
  mediaStreamSource[remoteKey].connect(inputNode[remoteKey])

  inputNode[remoteKey].connect(splitter[remoteKey])
  gainNodeLeft[remoteKey] = audioContext[remoteKey].createGain()
  gainNodeRight[remoteKey] = audioContext[remoteKey].createGain()

  splitter[remoteKey].connect(gainNodeLeft[remoteKey], 0)
  splitter[remoteKey].connect(gainNodeRight[remoteKey], 1)

  // 静音,将左右声道的声音置为0  取值范围为 0 - 1;
  gainNodeLeft[remoteKey].gain.setValueAtTime(0, audioContext[remoteKey].currentTime)
  gainNodeRight[remoteKey].gain.setValueAtTime(0, audioContext[remoteKey].currentTime)

  gainNodeLeft[remoteKey].connect(merger1[remoteKey], 0, 0)
  gainNodeRight[remoteKey].connect(merger2[remoteKey], 0, 1)

  merger1[remoteKey]
    .connect(pannerLeft[remoteKey])
    .connect(audioContext[remoteKey].destination)
  merger2[remoteKey]
    .connect(pannerRight[remoteKey])
    .connect(audioContext[remoteKey].destination)

  meter[remoteKey] = createAudioMeter(audioContext[remoteKey])
  mediaStreamSource[remoteKey].connect(meter[remoteKey])
  audioContext[remoteKey].suspend()// suspend audiocontext  because of chrome not allowed audiocontext auto live
  drawLoop(remoteKey)
  return drawLoop(remoteKey, callBack)
  // if (!isHadTimer) {
  //   // 由于 使用webrtc播放视频的时候,不管在没在live都会去获取视频  但是使用agora播放的时候是
  //   // 只有live的时候去获取视频流, 因此这个地方不太好做统一的处理  只是延迟了3000后再让弹窗出现
  //   setTimeout(function () {
  //     store.dispatch('showAllowAudio', true)// 通知producer.vue 去弹出声音允许弹窗
  //   }, 2000)
  //   isHadTimer = true
  // }
}

// 静音
function muteVolume (remoteKey, isMute) {
  if (!gainNodeLeft || !gainNodeLeft[remoteKey]) return
  if (isMute) {
    gainNodeLeft[remoteKey].gain.setValueAtTime(0, audioContext[remoteKey].currentTime)
    gainNodeRight[remoteKey].gain.setValueAtTime(0, audioContext[remoteKey].currentTime)
  } else {
    gainNodeLeft[remoteKey].gain.setValueAtTime(1, audioContext[remoteKey].currentTime)
    gainNodeRight[remoteKey].gain.setValueAtTime(1, audioContext[remoteKey].currentTime)
  }
}

// Restore audiocontext
function resumeAudio (remoteKey) {
  // if(hasResumeAudio.includes(remoteKey)) return; //已经resume()的key不在resume
  if (audioContext[remoteKey] && audioContext[remoteKey].state === 'suspended') {
    audioContext[remoteKey].resume()
    muteVolume(remoteKey, true)
    hasResumeAudio.push(remoteKey)
    if (window.audioContextDebug) console.log(remoteKey, audioContext[remoteKey])
  }
}

function drawLoop (remoteKey, callBack) {

  let floorValue =
    Math.pow(10, (500 + 100) / 100) *
    0.04093171969
  percentageLeft[remoteKey] = Math.floor(floorValue)

  floorValue =
    Math.pow(10, (500 + 100) / 100) *
    0.04093171969
  percentageRight[remoteKey] = Math.floor(floorValue)

  const left = meter[remoteKey] ? (meter[remoteKey].volume[0] * percentageLeft[remoteKey]) : 1
  let volumeLeft = left / 100

  const right = meter[remoteKey] ? (meter[remoteKey].volume[1] * percentageRight[remoteKey]) : 1
  let volumeRight = right / 100

  // condition applied for to set volume as zero, if the volume is in exponential number
  if (volumeLeft.toString().indexOf('e') > -1) {
    volumeLeft = 0
  }
  if (volumeRight.toString().indexOf('e') > -1) {
    volumeRight = 0
  }
  canvasDrap(remoteKey + 'Right', volumeRight)
  canvasDrap(remoteKey + 'Left', volumeLeft)
  // 使用时间戳来保证每秒执行一次回调函数
  if (!lastCallbackTimes[remoteKey]) {
    lastCallbackTimes[remoteKey] = Date.now();
  }
  if (callBack && Date.now() - lastCallbackTimes[remoteKey] >= 1000) {
    callBack(volumeLeft, volumeRight, remoteKey);
    lastCallbackTimes[remoteKey] = Date.now();
  }
  window.audioContext = audioContext
  rafID[remoteKey] = window.requestAnimationFrame(() => drawLoop(remoteKey, callBack))
}

// volume-meter.js;
function createAudioMeter (audioContext, clipLevel, averaging, clipLag) {
  const processor = audioContext.createScriptProcessor(null)
  processor.onaudioprocess = volumeAudioProcess
  processor.clipping = false
  processor.lastClip = 0
  processor.volume = [0, 0]
  processor.clipLevel = clipLevel || 0.98
  processor.averaging = averaging || 0.95
  processor.clipLag = clipLag || 750
  processor.connect(audioContext.destination)
  processor.checkClipping = function () {
    if (!this.clipping) return false
    if (this.lastClip + this.clipLag < window.performance.now()) { this.clipping = false }
    return this.clipping
  }
  processor.shutdown = function () {
    this.disconnect()
    this.onaudioprocess = null
  }
  return processor
}

function volumeAudioProcess (event) {
  const self = this
  let buf = event.inputBuffer.getChannelData(0)
  let bufLength = buf.length
  let x = 0
  for (let i = 0; i < event.inputBuffer.numberOfChannels; i++) {
    buf = event.inputBuffer.getChannelData(i)
    bufLength = buf.length
    for (let j = 0; j < bufLength; j++) {
      var sum = 0
      x = buf[j]
      if (Math.abs(x) >= self.clipLevel) {
        self.clipping = true
        self.lastClip = window.performance.now()
      }
      sum += x * x
    }
    const rms = Math.sqrt(sum / bufLength)
    self.volume[i] = Math.max(rms, self.volume[i] * self.averaging)
  }
}

function canvasDrap (id, data) {
  // let canvas = document.getElementById(id);
  const canvas = document.getElementsByClassName(id)
  if (!canvas || canvas.length == 0) return
  if (canvas && canvas.length > 0) {
    for (let i = 0; i < canvas.length; i++) {
      const currentCanvas = canvas[i]
      const w = currentCanvas.width
      const h = currentCanvas.height
      const currentPercent = data / 2// 计算占比   因为获取的volume声音的值范围是  0 - 2
      const ctx = currentCanvas.getContext('2d')
      currentCanvas.style.transform = 'rotate(180deg)'
      currentCanvas.style.background = '#3d3f40'
      // 填充渐变色, fillRect 是从左上角开始 即(0,0)坐标开始.
      // 因此注意,canvas显示的时候旋转 canvas 180度 是个好的解决方案
      const gradient = ctx.createLinearGradient(0, 0, 0, h)
      gradient.addColorStop(0, '#5DC045')
      gradient.addColorStop(0.29, '#5DC045')
      gradient.addColorStop(0.8, '#F1D737')
      gradient.addColorStop(1, '#F6445A')
      ctx.clearRect(0, 0, w, h)
      ctx.fillStyle = gradient
      ctx.fillRect(0, 0, w, currentPercent * h)
    }
  }
}

function resumeAudioBar () {
  const audioContext = window.audioContext || {}
  if (!audioContext) return
  Object.keys(audioContext).map(key => {
    console.log(2222222222)
    if (audioContext[key] && audioContext[key].state === 'suspended') {
      audioContext[key].resume()
      if (window.audioContextDebug) console.log('*****resume****', key + ' : ' + audioContext[key].state)
    }
  })
  if (window.audioContextDebug) console.log('current audioContext status: ', audioContext)
}

function closeAudioContext (remoteKey) {
  if (audioContext[remoteKey]) {
    audioContext[remoteKey].suspend()
    audioContext[remoteKey].destination.disconnect()
    audioContext[remoteKey].close()
  }
}

/*
* audioTrack => 实例化audiocontext
* muteVolume => 静音与开音
* resumeAudio => 恢复挂起的audiocontext.
* */
export const audioContextObj = {
  audioTrack,
  muteVolume,
  resumeAudio,
  resumeAudioBar,
  closeAudioContext
}
