import { render, staticRenderFns } from "./micphone.vue?vue&type=template&id=2b37f0de&scoped=true&"
import script from "./micphone.vue?vue&type=script&lang=js&"
export * from "./micphone.vue?vue&type=script&lang=js&"
import style0 from "./micphone.vue?vue&type=style&index=0&id=2b37f0de&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b37f0de",
  null
  
)

export default component.exports