<template>
  <div class="voipBox">
    <i class="iconfont" :class="VoIPStatusClass" @click="configVoIP"></i>
    <audio controls autoplay ref="voipAudio" class="hide"></audio>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Bus from '@/assets/js/bus.js'
export default {
  data () {
    return {
      sourceId: '',
      voIPRtilCode: '',
      VoIPStatus: 0 // 0: not connect 1: conncting 2: connected
    }
  },
  props: {
    selectRid: {
      type: String,
      default: ''
    },
    userInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    rtilCode: {
      default: ''
    },
    nickName: {
      type: String,
      default: ''
    },
    memberId: {
      default: ''
    }
  },
  computed: {
    ...mapState({
      State: (state) => state,
      Webrtc: (state) => state.webrtc
    }),
    VoIPStatusClass () {
      let _str = ''
      switch (this.VoIPStatus) {
        case 0:
          _str = 'icon-dadianhua1'
          break
        case 1:
          _str = 'icon-connecting'
          break
        case 2:
          _str = 'icon-dadianhua1 connected'
          break
      }
      return _str
    }
  },
  created () {
    Bus.$on('notify', (v) => {
      const { key, rtilCode } = v
      if (key === 'asyncHost' && rtilCode == this.rtilCode) {
        this.VoIPStatus = 2
      }
    })
  },
  methods: {
    changeSourceId (val) {
      this.sourceId = val
    },
    changeVoIPStatus (val) {
      this.VoIPStatus = val
    },
    configVoIP () {
      // 判断是否为host，只有host才能停止VoIP
      if (this.State.initDateInfo.managerRtil) {
        const type = typeof this.State.initDateInfo.managerRtil
        if (type == 'number' || type == 'string') {
          if (this.State.initDateInfo.managerRtil != this.userInfo.rtilCode) { return }
        } else {
          if (
            !this.State.initDateInfo.managerRtil.includes(
              this.userInfo.rtilCode
            )
          ) { return }
        }
      }

      if (this.VoIPStatus) {
        this.sendStopVoIP()
      } else {
        this.sendStartVoIP()
      }
    },
    sendStartVoIP () {
      const params = {
        rid: this.selectRid,
        sourceId: this.rtilCode,
        sourceType: 'rtil',
        partyCode: this.State.partyCode,
        rtilCode: this.rtilCode,
        memberId: this.memberId,
        sourceName: this.nickName
          ? `PL_${this.nickName}_${this.rtilCode}_${this.State.partyCode}`
          : `${this.rtilCode}_${this.State.partyCode}`,
        needAudio: true
      }
      this.axios
        .post(`${this.State.config.urlInfo.partyline}/partyline-backend/receiver/startVoIP`, params)
        .then((res) => {})
    },
    sendStopVoIP () {
      const params = {
        rtilCode: this.rtilCode,
        partyCode: this.State.partyCode
      }

      this.axios
        .post(`${this.State.config.urlInfo.partyline}/partyline-backend/receiver/stopVoIP`, params)
        .then((res) => {})
    },
    startVoIP () {
      if (!this.selectRid) {
        this.$message.error('Operation failed, please select Receiver first.')
        return
      }
      this.VoIPStatus = 1
      this.Webrtc.startCall(
        [`0x${this.selectRid.toUpperCase()}`],
        'voip',
        (info) => {
          const type = info.type
          if (type === 'success') {
            this.VoIPStatus = 2
            this.$refs.voipAudio.srcObject = info.msg.mediaStream
            // 判断当前是否是host,与会者需要发给host同步状态
            if (this.State.initDateInfo.managerRtil) {
              const type = typeof this.State.initDateInfo.managerRtil
              if (type == 'number' || type == 'string') {
                if (
                  this.State.initDateInfo.managerRtil != this.userInfo.rtilCode
                ) {
                  this.sendResultToHost()
                }
              } else {
                if (
                  !this.State.initDateInfo.managerRtil.includes(
                    this.userInfo.rtilCode
                  )
                ) {
                  this.sendResultToHost()
                }
              }
            }
          } else if (type === 'userNotExists') {
            this.$message.error('Current user does not exist')
          }
        }
      )
    },
    stopVoIP () {
      this.VoIPStatus = 0
      this.Webrtc.stopCall([`0x${this.selectRid.toUpperCase()}`], true)
    },
    sendResultToHost () {
      const params = {
        partyCode: this.State.partyCode,
        module: 'notify',
        msg: {
          key: 'asyncHost',
          rtilCode: this.rtilCode
        }
      }
      this.axios
        .post(`${this.State.config.urlInfo.partyline}/partyline-backend/party/noLogin/command`, params)
        .then(async (res) => {})
    }
  }
}
</script>

<style lang="less" scoped>
.voipBox {
  display: inline;
  padding-left: 7px;
  color: #fff;
  .icon-dadianhua1 {
    font-size: 17px;
    &.connected {
      color: #f45d24;
    }
  }
  .icon-connecting {
    color: #f45d24;
  }
}
</style>
