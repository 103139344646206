<template>
  <div id="micBox">
    <div class="audioValBox" ref="audioValBox">
      <div class="level" ref="level"></div>
    </div>
    <p class="line"></p>
    <div class="verticalDivider"></div>
    <div class="footLine"></div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      audioValBoxHeight: 0
    }
  },
  props: {
    level: {
      type: Number,
      default: 0
    }
  },
  watch: {
    level (newVal, oldVal) {
      if (newVal > 20) newVal = 20
      this.$refs.level.style.height = (newVal / 20).toFixed(2) * 100 + '%'
    }
  },
  mounted () {
    this.audioValBoxHeight = this.$refs.audioValBox.clientHeight
  }
}
</script>
<style scoped lang="less">
#micBox {
  display: inline-block;
  position: relative;
  top: 1px;
  .audioValBox {
    width: 4px;
    height: 8px;
    border-radius: 38%;
    border: 2px solid #fff;
    margin: 0 auto;
    position: relative;
    .level {
      background: #33aa50;
      bottom: 0;
      width: 100%;
      position: absolute;
    }
  }
  .line {
    border: 2px solid #fff;
    width: 10px;
    height: 7px;
    border-radius: 28px;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 50px;
    border-top-color: transparent;
    margin: -8px auto 0;
  }
  .verticalDivider {
    width: 2px;
    height: 1px;
    background: #fff;
    margin: 0 auto;
  }
  .footLine {
    width: 8px;
    background: #fff;
    height: 2px;
    margin: 0 auto;
  }
}
</style>
