<template>
  <div class="operateBtn" :class="{ asRole: type == 'asRole' }">
    <el-popover
      title=""
      width="330"
      popper-class="operateByCommon"
      placement="bottom-start"
      v-model="showPopover"
      trigger="click"
    >
      <i
        slot="reference"
        :class="
          `icon ${
            type == 'asRole'
              ? selectItem.icon
              : 'iconfont icon-universal_3-dots'
          } ${iconColor}`
        "
      ></i>
      <div class="btnBox">
        <!-- {{selectItem}},{{activeRole}} -->
        <p class="title" v-if="title">{{ title }}</p>
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          @select="handleSelect"
          background-color="#454549"
          text-color="#F1F1F1"
          :collapse="true"
          :popper-append-to-body="false"
          active-text-color="#F1F1F1"
        >
          <div v-for="(val, key) in btnList" :key="key">
            <el-menu-item
              :class="
                `${activeRole == val ? activeBg : ''} ${praivateBg[val]} ${
                  greenBg[val]
                }`
              "
              v-if="typeof val == 'string'"
              :index="val"
            >
              <i :class="btnText[val].icon"></i>{{ $t(`btnText.${val}.text`)}}
            </el-menu-item>
            <el-submenu popper-class="menuByCommon" :index="val.key" v-else>
              <template slot="title"
                ><i :class="btnText[val.key].icon"></i
                >{{ btnText[val.key].text }}</template
              >
              <el-menu-item
                :class="
                  `${activeRole == value ? activeBg : ''}  ${
                    praivateBg[value]
                  } ${greenBg[value]}`
                "
                :index="value"
                v-for="(value, index) in val.children"
                :key="index"
              >
                <i :class="btnText[value].icon"></i>{{ $t(`btnText.${value}.text`) }}
              </el-menu-item>
            </el-submenu>
          </div>
        </el-menu>
      </div>
    </el-popover>
    <!-- rename 操作 -->
    <user-setting
      @setNickName="setNickName"
      v-if="setNameInfo.show"
      :info="setNameInfo"
    ></user-setting>
    <!-- 设置crew -->
    <TipsDailog
      v-if="crewInfoTips.show"
      :info="crewInfoTips"
      @save="crewFn('setCrew', 'command')"
    ></TipsDailog>
    <!-- 私聊提示框 -->
    <TipsDailog
      v-if="praivateInfoTips.show"
      :info="praivateInfoTips"
      @save="privateConversationFn('startPrivateConversation', 'command')"
    ></TipsDailog>
    <!-- remove提示框 -->
    <TipsDailog
      v-if="removeInfoTips.show"
      :info="removeInfoTips"
      @save="removeFn('removeParticipant', 'command')"
    ></TipsDailog>
  </div>
</template>
<script>
import UserSetting from '@/components/common/userSetting'
import Command from '@/assets/js/command.js'
import TipsDailog from '@/components/common/tipsDailog.vue'
import { mapState } from 'vuex'
import Bus from '@/assets/js/bus.js'
export default {
  props: {
    btnList: {
      type: Array,
      default: () => {
        return []
        // ['setCrew','cancelCrew']
        // ['rename', {key: 'roleSetting', children: ['replace','startPrivateConversation']}, 'removeParticipant']
      }
    },
    userInfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    singChannel: {
      type: Object,
      default: () => {
        return {}
      }
    },
    nickName: String,
    organization: String,
    sourceLiveStatus: {
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    activeBg: {
      type: String,
      default: 'green'
    },
    type: {
      type: String,
      default: 'operate'
    },
    currentRole: {
      type: String,
      default: ''
    }
  },
  components: {
    UserSetting,
    TipsDailog
  },
  computed: {
    ...mapState({
      State: (state) => state
    }),
    activeRole () {
      let res = ''
      if (
        this.type == 'asRole' ||
        JSON.stringify(this.btnList).indexOf('roleSetting') > -1
      ) {
        if (
          this.State.crewInfo.rtilCode &&
          this.State.crewInfo.rtilCode == this.singChannel.id
        ) {
          res = 'setCrew' // 当前是crew角色，并选择crew
        } else {
          res = 'cancelCrew'
        }
        if (this.currentRole) {
          res = this.currentRole
        }
        this.selectItem = { ...this.btnText[res], type: res }
      }
      return res
    },
    praivateBg () {
      // 私聊状态
      if (
        this.State.praivateInfo.rtilCode &&
        this.State.praivateInfo.rtilCode == this.singChannel.id
      ) {
        return {
          // 'startPrivateConversation': 'red',
          cancelPrivateConversation: 'red'
        }
      } else {
        return {}
      }
    },
    greenBg () {
      // 角色相关，统一角色，不同的操作对应统一的角色
      if (this.currentRole == 'interviewer') {
        return {
          hangUpInterview: 'green',
          setQuestionSpeaker: 'green'
        }
      } else {
        return {}
      }
    },
    iconColor () {
      let str = ''
      const { type } = this.selectItem
      const array = [
        'setCrew',
        'setSpeaker',
        'interviewer',
        'setQuestionSpeaker',
        'setPublicSpeaker'
      ]
      if (array.includes(type)) {
        str = 'green'
      }
      return str
    }
  },
  data () {
    return {
      btnText: {
        // key值最好小写开头，避免与自定义的重复
        rename: {
          icon: 'el-icon-edit',
          text: 'Rename'
        },
        removeParticipant: {
          icon: 'el-icon-delete',
          text: 'Remove from program'
        },
        setCrew: {
          icon: 'iconfont icon-Crew',
          text: 'Production Crew'
        },
        cancelCrew: {
          icon: 'iconfont icon-universal_role',
          text: 'Participant'
        },
        startPrivateConversation: {
          icon: 'iconfont earphoneIcon',
          text: 'Set private chat'
        },
        cancelPrivateConversation: {
          icon: 'iconfont icon-privatechat',
          text: 'Hang up private chat'
        },
        replace: {
          icon: 'iconfont icon-replace',
          text: 'Replace'
        },
        roleSetting: {
          icon: 'iconfont icon-universal_role',
          text: 'Role setting'
        },
        setSpeaker: {
          icon: 'iconfont icon-universal_speaker',
          text: 'Speaker'
        },
        cancelSpeaker: {
          icon: 'iconfont icon-universal_role',
          text: 'Participant'
        },
        cancelQuestionSpeaker: {
          icon: 'iconfont icon-universal_role',
          text: 'Participant'
        },
        hangUpInterview: {
          icon: 'iconfont icon-universal_role',
          text: 'Hang up interview'
        },
        setQuestionSpeaker: {
          icon: 'iconfont icon-universal_interviewer',
          text: 'Interviewer'
        },
        interviewer: {
          icon: 'iconfont icon-universal_interviewer',
          text: 'Interviewer'
        },
        setPublicTalk: {
          icon: 'iconfont icon-universal_interviewer',
          text: 'Set public talk'
        },
        cancelQuestioner: {
          icon: 'iconfont icon-universal_role',
          text: 'Participant'
        },
        cancelQuestionerRemove: {
          icon: 'iconfont icon-universal_role',
          text: 'Remove from Question queue'
        },
        setQuestioner: {
          icon: 'iconfont icon-universal_role',
          text: 'Participant'
        },
        addQuestioner: {
          icon: 'iconfont icon-questionerqueue',
          text: 'Add in questioner queue'
        },
        toTop: {
          icon: 'iconfont icon-totop',
          text: 'To top'
        },
        participant: {
          icon: 'iconfont icon-universal_role',
          text: 'Participant'
        },
        denyRaiseHand: {
          icon: 'iconfont icon-universal_role',
          text: 'Remove from Raise hand queue'
        },
        setBroadcast: {
          icon: 'iconfont icon-universal_speaker',
          text: 'Broadcaster'
        },
        setPublicSpeaker: {
          icon: 'iconfont icon-universal_interviewer',
          text: 'Public speaker'
        },
        editTagName: {
          icon: 'iconfont icon-tagname',
          text: 'Edit tag name'
        },
        fullScreen: {
          icon: 'iconfont icon-full_screen',
          text: 'Full screen'
        },
        moveParty: {
          icon: 'iconfont icon-movepip',
          text: 'Move to'
        }
      },
      setNameInfo: {
        show: false
      },
      showPopover: false,
      loading: {},
      dialogTableVisible: false,
      crewType: '',
      praivateInfoTips: {
        show: false,
        title: 'Switch Private Conversation',
        content:
          'After opening a new session, the current private conversation will be hung up. Do you confirm to go on?',
        save: 'Go on'
      },
      crewInfoTips: {
        show: false,
        title: '',
        content: ''
      },
      removeInfoTips: {
        title: 'Remove the source',
        content:
          '<div class="end">Do you really want to remove the source? <p>After remove, live feeds will be terminated.</p></div>',
        cancel: 'Cancel',
        save: 'Remove',
        show: false
      },
      selectItem: {},
      activeIndex: ''
    }
  },
  created () {
    Bus.$on('changeItmeVal', (val) => {
      this.selectItem = { ...this.btnText[val], type: val }
    })
  },
  methods: {
    handleSelect (val, keyPath) {
      this.showPopover = false
      if (val != 'setCrew' && val != 'cancelCrew') {
        // setCrew设置成功后在标记高亮
        this.selectItem = { ...this.btnText[val], type: val }
      }
      if (val == 'rename') {
        this.setNameInfo.show = true
        this.setNameInfo.hideBtn = true
        this.setNameInfo.userInfo = {
          nickName: this.nickName,
          organization: this.organization,
          partyCode: this.userInfo.joinCode,
          rtilCode: this.singChannel.id,
          userRtilCode: this.userInfo.rtilCode
        }
      } else {
        switch (val) {
          case 'removeParticipant':
            this.removeShowTip(val)
            break
          case 'setCrew':
          case 'cancelCrew':
            this.crewFn(val)
            break
          case 'startPrivateConversation':
          case 'cancelPrivateConversation':
            this.privateConversationFn(val)
            break
          case 'replace':
          case 'setSpeaker':
          case 'cancelSpeaker':
          case 'cancelQuestionSpeaker':
          case 'toTop':
          case 'setPublicTalk':
          case 'cancelQuestionerRemove':
          case 'setQuestionSpeaker':
          case 'addQuestioner':
          case 'denyRaiseHand':
          case 'hangUpInterview':
          case 'setBroadcast':
          case 'setPublicSpeaker':
          case 'participant':
          case 'editTagName':
          case 'moveParty':
          case 'fullScreen':
            this.sendEmitFn(val)
            break
        }
      }
    },
    sendEmitFn (val) {
      if (val == 'setPublicTalk' && this.State.praivateInfo.rtilCode) {
        this.privateConversationFn('cancelPrivateConversation')
      }
      // 组件内单独处理
      this.$emit('callback', {
        ...this.singChannel,
        type: val,
        nickName: this.nickName,
        rtilCode: this.singChannel.id,
        organization: this.organization
      })
    },
    // rename
    setNickName (obj) {
      console.info(this.nickName, this.organization, obj)
      // this.userInfo.behavior.nickName = obj.nickName
      // this.userInfo.behavior.organization = obj.organization
    },
    // 私聊
    async privateConversationFn (val, str) {
      if (
        this.$route.name == 'party' ||
        this.$route.name == 'partyParticipate'
      ) {
        this.$emit('callback', {
          ...this.singChannel,
          type: val,
          nickName: this.nickName,
          rtilCode: this.singChannel.id,
          organization: this.organization
        })
        console.info(378, {
          ...this.singChannel,
          type: val,
          nickName: this.nickName,
          rtilCode: this.singChannel.id,
          organization: this.organization
        })
        return
      }
      const params = {
        partyCode: this.State.partyCode,
        module: val,
        msg: {
          rtilCode: this.singChannel.id,
          crewRtilCode: this.State.crewInfo.rtilCode,
          realPartyCode: this.singChannel.channel
        }
      }
      if (val == 'startPrivateConversation') {
        if (this.State.praivateInfo.rtilCode) {
          // 已经存在私聊
          this.praivateInfoTips.show = true
        } else {
          Command.fn(params).then((data) => {})
        }
        if (str == 'command') {
          if (this.State.praivateInfo.rtilCode) {
            await Command.fn({
              partyCode: this.State.partyCode,
              module: 'cancelPrivateConversation',
              msg: {
                rtilCode: this.State.praivateInfo.rtilCode,
                crewRtilCode: this.State.crewInfo.rtilCode,
                realPartyCode: this.singChannel.channel
              }
            }).then((data) => {
              if (data.errorCode === '0x0') {
                this.State.praivateInfo = {}
              }
            })
          }
          Command.fn(params).then((data) => {
            if (data.errorCode === '0x0') {
              this.State.praivateInfo = {
                crewRtilCode: this.State.crewInfo.rtilCode,
                rtilCode: this.singChannel.id
              }
            }
          })
        }
      }
      if (val == 'cancelPrivateConversation') {
        Command.fn(params).then((data) => {
          if (data.errorCode === '0x0') {
            this.State.praivateInfo = {}
          }
        })
      }
    },
    // crew
    async crewFn (type, str) {
      if (!this.singChannel.info) return
      if (type == 'setCrew' && this.activeRole == 'setCrew') return
      if (type == 'cancelCrew' && this.activeRole == 'cancelCrew') return
      const params = {
        partyCode: this.State.partyCode,
        module: type,
        msg: {
          rtilCode: this.singChannel.info.rtilCode
        }
      }
      if (!this.State.crewInfo.rtilCode) {
        this.crewInfoTips.title = 'Authorize operation'
        this.crewInfoTips.content = `With ${this.nickName} set to "Production Crew", he/she will be able to establish private conversations.`
        this.crewInfoTips.hideCancel = true
        this.crewInfoTips.hideSave = false
        this.crewInfoTips.save = 'Ok'
      } else {
        this.crewInfoTips.title = 'Switch "Production Crew" role'
        this.crewInfoTips.content = `Are you sure to switched the Production Crew permissions to ${this.nickName}?`
        this.crewInfoTips.hideCancel = false
        this.crewInfoTips.cancel = 'Cancel'
        this.crewInfoTips.hideSave = false
        this.crewInfoTips.save = 'Switch'
      }
      if (this.State.praivateInfo.rtilCode) {
        this.crewInfoTips.title = 'Operation failed'
        this.crewInfoTips.content = 'Because the Production Crew is working.'
        this.crewInfoTips.hideCancel = false
        this.crewInfoTips.cancel = 'Ok'
        this.crewInfoTips.hideSave = true
      }
      if (type == 'cancelCrew') {
        if (this.State.praivateInfo.rtilCode) {
          this.crewInfoTips.show = true
        } else {
          Command.fn(params).then((res) => {
            if (res.errorCode == '0x0') {
              this.State.crewInfo = {}
            }
          })
        }
      } else {
        this.crewInfoTips.show = true
        if (this.State.crewInfo.rtilCode) {
          const data = {
            partyCode: this.State.partyCode,
            module: 'cancelCrew',
            msg: {
              rtilCode: this.State.crewInfo.rtilCode
            }
          }
          str == 'command' && (await Command.fn(data, false))
        }
        str == 'command' &&
          Command.fn(params).then((res) => {
            if (res.errorCode == '0x0') {
              this.State.crewInfo = { rtilCode: this.singChannel.info.rtilCode }
            }
          })
      }
    },
    removeShowTip () {
      this.removeInfoTips.show = true
    },
    // 移除
    removeFn (type, str) {
      // 先判断当前的source是否在live
      // if (this.sourceLiveStatus == 2 && str != 'command') {
      //   this.removeInfoTips.show = true
      //   return
      // }
      if (this.loading[type]) return
      this.loading[type] = true
      const params = {
        partyCode: this.singChannel.channel,
        rtilCode: this.singChannel.id
      }
      this.$http
        .post(`${this.State.config.urlInfo.partyline}/partyline-backend/partyAction/removeParticipant?partyCode=${this.singChannel.channel}`, params)
        .then(res => {
          this.loading[type] = false
          if (res.data.errorCode === '0x0') {
            // 在gallery view模式下需要 注意添加
            this.$emit('callback', {
              type: 'removePepole',
              rtilCode: this.singChannel.id,
              partyCode: this.singChannel.channel
            })
          }
        })
        .catch(() => {
          this.loading[type] = false
        })
    },
    removeV3 (item) {
      const params = {
        peerId: item.peerId,
        partyCode: this.singChannel.channel
      }
      this.axios
        .post(`${this.State.config.urlInfo.partyline}/partyline-backend/party/packLeave`, params)
        .then((res) => {
          if (res.data.errorCode != '0x0') {
            this.$message({
              type: 'error',
              message: res.data.result.errorIfno
            })
          }
        })
    }
  }
}
</script>
<style lang="less" scoped>
.operateBtn {
  display: inline-block;
  &.asRole {
    margin-right: 5px;
  }
  .icon {
    cursor: pointer;
    color: #fff;
    font-size: 22px;
    &.icon-universal_3-dots {
      font-size: 14px;
    }
    &.icon-Crew {
      font-size: 18px;
      margin-left: 2px;
    }
    &.green {
      color: #00cc66;
    }
  }
}
</style>
