<template>
  <el-dialog
    :title="$t('eventList.ParticipationSettings')"
    :visible.sync="info.show"
    :show-close="info.userInfo.nickName ? true : false"
    top="10px"
    :close-on-click-modal="false"
    :append-to-body="true"
    width="450px"
  >
    <div class="content">
      <div class="nickname" :class="{ red: redTips }">
        <span><label>*</label>{{$t('coordinationRoom.name')}}:</span>
        <el-input
          :maxlength="32"
          v-model="nickName"
          @blur="blurFn('nickName')"
        ></el-input>
        <p v-if="redTips">{{ redTips }}</p>
      </div>
      <div
        class="nickname"
        style="margin-bottom: 20px"
        :class="{ red: redTips1 }"
        v-if="
          $route.name == 'reporterParticipate' || $route.name == 'coordinator'
        "
      >
        <span><label>*</label>{{$t('coordinationRoom.corporation')}}:</span>
        <el-input
          :maxlength="256"
          v-model.trim="organization"
          @blur="blurFn('corporation')"
        ></el-input>
        <p v-if="redTips1">{{ redTips1 }}</p>
      </div>
      <div v-if="!info.hideBtn">
        <span class="aduio">
          <el-tooltip
            class="item"
            effect="dark"
            content="Sound of the site"
            placement="bottom"
          >
            <i class="iconfont icon-speakeron"></i>
          </el-tooltip>
          <span class="btn"
            ><i :class="{ gray: setInfo.speaker }" @click="setBtn('speaker', false)"
              >{{$t('coordinationRoom.on')}} </i
            >/
            <i :class="{ gray: !setInfo.speaker }" @click="setBtn('speaker', true)"
              >{{$t('coordinationRoom.off')}}</i
            ></span
          >
        </span>
        <span class="aduio">
          <!-- 有摄像头 需要添加下拉 -->
          <el-tooltip
            v-if="info.cameraId"
            class="item"
            effect="dark"
            content="Camera"
            placement="bottom"
          >
            <i class="iconfont icon-universal_cameraon"></i>
          </el-tooltip>
          <!-- 无摄像头 -->
          <el-tooltip
            v-else
            class="item"
            effect="dark"
            content="No access"
            placement="bottom"
          >
            <i class="iconfont">
              <svg class="iconfont carmerHover" aria-hidden="true">
                <use xlink:href="#icon-camerahover"></use>
              </svg>
            </i>
          </el-tooltip>
          <span class="btn"
            ><i
              :class="{
                gray: setInfo.camera || !info.cameraId,
                notAllowed: !info.cameraId
              }"
              @click="setBtn('camera', false)"
              >{{$t('coordinationRoom.on')}}</i
            >/
            <i
              :class="{
                gray: !setInfo.camera && info.cameraId,
                notAllowed: !info.cameraId
              }"
              @click="setBtn('camera', true)"
              >{{$t('coordinationRoom.off')}}</i
            ></span
          >
        </span>
        <span class="aduio">
          <el-tooltip
            v-if="info.microphoneId"
            class="item"
            effect="dark"
            content="Audio"
            placement="bottom"
          >
            <i class="iconfont icon-universal_micon"></i>
          </el-tooltip>
          <!-- 无麦克风 -->
          <el-tooltip
            v-else
            class="item"
            effect="dark"
            content="No access"
            placement="bottom"
          >
            <i class="iconfont">
              <svg class="iconfont audio carmerHover" aria-hidden="true">
                <use xlink:href="#icon-Michover"></use>
              </svg>
            </i>
          </el-tooltip>
          <span class="btn"
            ><i
              :class="{
                gray: setInfo.mic || !info.microphoneId,
                notAllowed: !info.microphoneId
              }"
              @click="setBtn('mic', true)"
              >{{$t('coordinationRoom.on')}}</i
            >/
            <i
              :class="{
                gray: !setInfo.mic && info.microphoneId,
                notAllowed: !info.microphoneId
              }"
              @click="setBtn('mic', false)"
              >{{$t('coordinationRoom.off')}}</i
            ></span
          >
        </span>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button class="gray" v-if="info.userInfo.nickName" @click="cancelFn"
        >{{$t('coordinationRoom.cancel')}}</el-button
      >
      <el-button type="primary" @click="save">{{$t('coordinationRoom.submit')}}</el-button>
    </span>
  </el-dialog>
</template>
<script>
// import DeviceSelect from '@/components/deviceSelect.vue'
// import { getDevices } from '@/assets/js/common.js'
import { mapState } from 'vuex'
import Bus from '@/assets/js/bus.js'
export default {
  props: {
    info: Object
  },
  components: {
    // DeviceSelect
  },
  computed: {
    ...mapState({
      State: (state) => state,
      loginInfo: (state) => state.userInfo
    })
  },
  data () {
    return {
      setInfo: {
        speaker: false,
        camera: false,
        mic: false,
        noHaveCamera: false,
        useCamera: false
      },
      redTips: '',
      redTips1: '',
      nickName: '',
      organization: '',
      audioLoaded: false,
      videoLoaded: false,
      selectCameraId: '',
      selectMicroId: '',
      videoList: [],
      microList: []
    }
  },
  watch: {
    nickName (val) {
      // 过滤emoji表情
      this.nickName = this.nickName.replace(
        /\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g,
        ''
      )
      if (val) this.redTips = false
    },
    organization (val) {
      if (val) this.redTips1 = false
    }
  },
  created () {
    // getDevices()
    this.info.show = true
    this.info.userInfo = this.info.userInfo || {}
    if (this.info.userInfo.nickName) {
      this.nickName = this.info.userInfo.nickName
    } else {
      this.nickName =
        this.loginInfo.firstName ||
        this.loginInfo.userName ||
        this.loginInfo.email ||
        ''
    }
    if (this.info.userInfo.organization) {
      this.organization = this.info.userInfo.organization
    }
  },
  methods: {
    TurnTips (flag, tag) {
      // 无摄像头
      let msg = ''
      if (tag === 'camera' && !this.info.cameraId) {
        msg = 'No access'
      } else {
        const onOroff = flag ? 'on' : 'off'
        msg = `Turn ${onOroff} ${tag}`
      }
      return msg
    },
    blurFn (type) {
      setTimeout(() => {
        if (
          type == 'nickName' &&
          !this.nickName.replace(/(^\s*)|(\s*$)/g, '')
        ) {
          this.redTips = 'Name is required'
        }
        if (type == 'corporation' && !this.organization) {
          this.redTips1 = 'Corporation is required'
        }
      }, 300)
    },
    setBtn (type, btn) {
      if (type === 'camera' && !this.info.cameraId) {
        return
      }
      if (type === 'mic' && !this.info.microphoneId) {
        return
      }
      this.setInfo[type] = btn
    },
    save () {
      this.nickName = this.nickName.replace(/(^\s*)|(\s*$)/g, '')
      let data = []
      this.setInfo.camera = !this.info.cameraId || this.setInfo.camera
      this.setInfo.mic = !this.info.microphoneId || this.setInfo.mic
      this.setInfo.noHaveCamera = !this.info.cameraId
      this.setInfo.useCamera = this.info.cameraId ? '1' : '3'
      if (!this.info.hideBtn) {
        data = [
          {
            mark: this.info.userInfo.rtilCode + '',
            annotation: this.info.userInfo.partyCode,
            key: 'nickName',
            value: this.nickName,
            notify: true,
            initiator: this.info.userInfo.userRtilCode
          },
          {
            mark: this.info.userInfo.rtilCode + '',
            annotation: this.info.userInfo.partyCode,
            key: 'speaker',
            value: this.setInfo.speaker,
            notify: true,
            initiator: this.info.userInfo.userRtilCode
          },
          {
            mark: this.info.userInfo.rtilCode + '',
            annotation: this.info.userInfo.partyCode,
            key: 'camera',
            value: this.setInfo.camera,
            notify: true,
            initiator: this.info.userInfo.userRtilCode
          },
          {
            mark: this.info.userInfo.rtilCode + '',
            annotation: this.info.userInfo.partyCode,
            key: 'useCamera',
            value: this.setInfo.useCamera,
            notify: true,
            initiator: this.info.userInfo.userRtilCode
          },
          {
            mark: this.info.userInfo.rtilCode + '',
            annotation: this.info.userInfo.partyCode,
            key: 'mic',
            value: this.setInfo.mic,
            notify: true,
            initiator: this.info.userInfo.userRtilCode
          },
          {
            mark: this.info.userInfo.rtilCode + '',
            annotation: this.info.userInfo.partyCode,
            key: 'noHaveCamera',
            value: this.setInfo.noHaveCamera,
            notify: true,
            initiator: this.info.userInfo.userRtilCode
          }
        ]
      } else {
        data = [
          {
            mark: this.info.userInfo.rtilCode + '',
            annotation: this.info.userInfo.partyCode,
            key: 'nickName',
            value: this.nickName,
            notify: true,
            initiator: this.info.userInfo.userRtilCode
          }
        ]
      }
      if (
        this.$route.name == 'reporterParticipate' ||
        this.$route.name == 'coordinator'
      ) {
        data.push({
          mark: this.info.userInfo.rtilCode + '',
          annotation: this.info.userInfo.partyCode,
          key: 'organization',
          value: this.organization,
          notify: true,
          initiator: this.info.userInfo.userRtilCode
        })
      }
      if (!this.nickName) {
        this.blurFn('nickName')
      }
      if (this.$route.name == 'reporterParticipate' && !this.organization) {
        this.blurFn('corporation')
      }
      if (
        !this.nickName ||
        ((this.$route.name == 'reporterParticipate' ||
          this.$route.name == 'coordinator') &&
          !this.organization)
      ) { return }

      if (this.info.userInfo.rtilCode) {
        this.$http
          .post(`${this.State.config.urlInfo.partyline}/partyline-backend/behavior/noLogin/setList`, data)
          .then((res) => {
            if (res.data.errorCode === '0x0') {
              this.info.show = false
              if (
                this.info.userInfo.userRtilCode &&
                this.info.userInfo.userRtilCode != this.info.userInfo.rtilCode
              ) {
                // host修改与会者的name
                if (this.$route.name == 'coordinator') {
                  this.$message.success(
                    'Participation Settings have been modified successfully.'
                  )
                } else {
                  this.$message.success(
                    'The participant name has been modified successfully.'
                  )
                }
              } else {
                this.$message.success('Success')
              }
              this.$emit('setNickName', {
                ...this.setInfo,
                nickName: this.nickName,
                organization: this.organization
              })
              this.$emit('setDefaultDevice')
            } else {
              this.redTips = res.data.errorInfo
            }
          })
      } else {
        this.$message.success('Success')
        this.info.show = false
        this.$emit('setNickName', data)
        this.$emit('setDefaultDevice')
      }
    },
    cancelFn () {
      this.info.show = false
    }
  },
  beforeRouteLeave (to, from, next) {
    Bus.$off('hasVideo')
    Bus.$off('hasAudio')
    Bus.$off('initDevices')
  }
}
</script>
<style lang="less" scoped>
/deep/.el-dialog {
  .el-button {
    border: 0;
    background: none;
    color: #fff;
    padding: 9px 20px;
    border-radius: 20px;
  }
  .el-button--primary {
    background: linear-gradient(
      270deg,
      rgba(3, 161, 118, 1) 0%,
      rgba(113, 186, 42, 1) 59%,
      rgba(208, 217, 0, 1) 100%
    );
  }
  .gray {
    color: #666;
    background: none;
    &:hover {
      background: none;
      opacity: 1;
      span {
        color: #666;
      }
    }
  }
}
.content {
  font-size: 16px;
  color: #28282e;
  .nickname {
    margin-bottom: 10px;
    label {
      color: #ff4b4b;
      margin-right: 5px;
    }
    span {
      display: inline-block;
      width: 110px;
      text-align: right;
    }
    /deep/.el-input {
      margin-left: 5px;
      width: 230px;
      .el-input__inner {
        background: #f5f5f5;
        height: 36px;
        line-height: 36px;
        color: #28282e;
        font-size: 16px;
        font-weight: bold;
        border: 0;
      }
    }
  }
  i {
    font-style: normal;
  }
  .setCamera {
    margin-top: 10px;
    .btn {
      margin-left: 10px;
    }
  }
  .aduio {
    margin-right: 25px;
    line-height: 21px;
    .iconfont {
      margin-right: 10px;
      vertical-align: middle;
    }
    .icon-speakeron {
      font-size: 27px;
    }
    .icon-universal_cameraon {
      font-size: 28px;
    }
    .icon-universal_micon {
      font-size: 22px;
    }
    .noCameraIcon {
      color: #999;
    }
    .carmerHover {
      width: 20px;
      height: 19px;
      margin-right: 0;
      margin-top: -7px;
    }
    .btn {
      i {
        cursor: pointer;
        font-weight: bold;
      }
      .gray {
        color: #aaaaaa !important;
      }
      .notAllowed {
        cursor: not-allowed;
      }
    }
  }
  .red {
    /deep/.el-input__inner {
      border: 1px solid #ff4b4b;
    }
    p {
      font-size: 14px;
      color: #ff4b4b;
      margin-top: 3px;
      padding-left: 115px;
    }
  }
}
</style>
