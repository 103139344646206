<template>
  <div class="uploadBox">
    <el-upload
      ref="loadControl"
      class="upload"
      action="#"
      :on-change="handleChange"
      :on-remove="handleRemove"
      :file-list="fileList"
      :multiple="false"
      accept=".mp4,.mov,.ogg,.webm,.ts,.mp3"
      :http-request="uploadFileBySDK"
    >
      <el-button v-if="type === 'btn'" slot="trigger">+{{$t('leftPane.add')}}</el-button>
      <img
        v-else
        class="uploadImg"
        src="@/assets/img/upload.png"
        alt=""
        srcset=""
      />
    </el-upload>
    <div class="list_box" v-if="fileList.length > 0">
      <h3 class="list_header">
        <span>{{ fileNum | uploadingText }}</span>
        <span @click="stopUpload" class="el-icon-close operate_icon"></span>
        <span
          @click="miniFlag = !miniFlag"
          :class="{
            'el-icon-arrow-down': !miniFlag,
            'el-icon-arrow-up': miniFlag
          }"
          class="operate_icon"
        ></span>
      </h3>
      <ul v-show="!miniFlag" class="upload_list scrollbar">
        <li v-for="item in fileList" :key="item.uid">
          <img
            v-if="item.status != 'success'"
            class="readyImg"
            src="@/assets/img/ready.png"
            alt=""
            srcset=""
          />
          <img
            v-else
            class="videoImg"
            src="@/assets/img/complete.png"
            alt=""
            srcset=""
          />
          <span class="overflow filename" :title="item.name">{{
            item.name
          }}</span>
          <el-progress
            v-if="item.status == 'uploading' || item.status == 'prepar'"
            type="circle"
            :percentage="item.progress"
          ></el-progress>
          <i
            v-else-if="item.status == 'success'"
            class="el-icon-circle-check"
          ></i>
          <i v-else-if="item.status == 'failed'" class="el-icon-warning"></i>
        </li>
        <p class="goDriver" v-if="fileNum == 0">
          {{$t('leftPane.fileSynchronizedTo')}}
          <a :href="downloadHref()" target="_blank">{{$t('leftPane.tvuDriver')}}</a>.
        </p>
      </ul>
    </div>
  </div>
</template>

<script>
import AWS from 'aws-sdk'
import Bus from '@/assets/js/bus.js'
// import { setTimeout } from 'timers';
import i18n from '@/lang/index.js'
export default {
  props: {
    type: String
  },
  data () {
    return {
      fileList: [],
      // regionList: [],
      uploadObj: {},
      uploadParams: {},
      miniFlag: false,
      fileNum: 0,
      uploadingFile: null,
      progressHover: false,
      closeTimeout: null
    }
  },
  methods: {
    downloadHref () {
      const protocol = window.location.protocol
      const href = `${protocol}//${this.$store.state.config.urlInfo.driver}`
      return href
    },
    stopUpload () {
      const item = this.fileList.filter(
        (v) => v.status == 'uploading' || v.status == 'prepar'
      )
      if (item.length === 0) {
        this.fileList = []
        if (this.closeTimeout) clearInterval(this.closeTimeout)
        return
      }
      this.$confirm(
        this.$t('otherWords.upLoadFileContinueTips'),
        this.$t('otherWords.cancelTheUpload'),
        {
          confirmButtonText: this.$t('otherWords.cancelToUpload'),
          cancelButtonText: this.$t('otherWords.continueToUpload'),
          cancelButtonClass: 'btn-custom-cancel',
          confirmButtonClass: 'btn-custom-confirm',
          center: true
        }
      ).then(() => {
        this.$refs.loadControl.abort()
        item.forEach((obj) => {
          this.uploadObj[obj.uid].abort()
        })
        this.fileList = []
        this.uploadObj = {}
        this.uploadParams = {}
        this.uploadingFile = null
        this.fileNum = 0
        if (this.closeTimeout) clearInterval(this.closeTimeout)
      })
    },
    handleChange (file) {
      this.uploadingFile = file
      this.uploadingFile.progress = 0
      this.uploadingFile.status = 'prepar'
      this.fileNum += 1
      if (this.closeTimeout) clearInterval(this.closeTimeout)
      this.fileList.push(this.uploadingFile)
    },
    uploadFileBySDK () {
      const params = {
        region: localStorage.getItem('region'), // 选中的region
        service: 'pp',
        directoryName: '/Videos/Commentator',
        fileName: this.uploadingFile.name
      }
      this.$http
        .post('/file/request-upload-for-sdk', params, {
          requestBase: 'GATEWAY'
        })
        .then((res) => {
          if (res.data.errorCode == '0x0') {
            const result = res.data.result
            this.uploadParams[this.uploadingFile.uid] = result
            AWS.config.region = result.identityPoolRegion
            AWS.config.credentials = new AWS.CognitoIdentityCredentials({
              IdentityPoolId: result.identityPoolId
            })
            this.doUploadFile(this.uploadingFile)
          }
        })
    },
    doUploadFile (obj) {
      this.uploadObj[obj.uid] = new AWS.S3.ManagedUpload({
        params: {
          Bucket: this.uploadParams[obj.uid].bucket,
          Key: this.uploadParams[obj.uid].objectKey,
          Body: obj.raw,
          ACL: 'public-read' // 固定
        },
        service: new AWS.S3({
          region: this.uploadParams[obj.uid].region
        })
      })
      this.uploadObj[obj.uid].on('httpUploadProgress', (event) => {
        const item = this.fileList.filter((v) => v.uid === obj.uid)
        const index = this.fileList.findIndex((v) => v.uid === obj.uid)
        item[0].progress = Math.floor((event.loaded * 100) / event.total)
        item[0].status = 'uploading'
        if (item[0].progress == 100) {
          item[0].progress = 99
        }
        this.$set(this.fileList, index, item[0])
      })
      this.uploadObj[obj.uid].promise().then((data) => {
        const item = this.fileList.filter((v) => v.uid === obj.uid)
        const index = this.fileList.findIndex((v) => v.uid === obj.uid)
        this.saveResult(item[0], index)
      })
      /* .catch(err => {
          let item = this.fileList.filter(v => v.uid === obj.uid)
          var index = this.fileList.findIndex(v => v.uid === obj.uid)
          item[0].status = 'failed'
          this.$set(this.fileList, index, item[0])
      }) */
    },
    saveResult (item, index) {
      const params = {
        bucketId: this.uploadParams[item.uid].bucketId,
        directoryId: this.uploadParams[item.uid].directoryId,
        objectKey: this.uploadParams[item.uid].objectKey
      }
      this.$http
        .post('/file/add-file', params, { requestBase: 'GATEWAY' })
        .then((res) => {
          if (res.data.errorCode == '0x0') {
            item.progress = 100
            item.status = 'success'
            if (this.fileNum > 0) {
              this.fileNum -= 1
            }
            this.$set(this.fileList, index, item)
            Bus.$emit('refreshFileList')
            if (this.fileNum == 0) {
              this.closeTimeout = setTimeout(() => {
                this.fileList = []
                clearInterval(this.closeTimeout)
              }, 1000 * 60 * 5)
            }
          } else {
            item.status = 'failed'
            this.$set(this.fileList, index, item)
          }
        })
    },
    handleRemove (file, fileList) {
      this.fileList = fileList
    }
  },
  filters: {
    uploadingText (num) {
      let str = ''
      if (num == 0) {
        str = i18n.t('leftPane.upLoadFileComplete')
        return str
      }
      if (num == 1) {
        str = i18n.t('leftPane.upLoadOneFile')
        return str
      }
      if (num > 1) {
        str = `Uploading ${num} files`
        return str
      }
    }
  },
  beforeDestroy () {
    if (this.closeTimeout) clearInterval(this.closeTimeout)
  }
}
</script>
<style lang="less" scoped>
.uploadBox {
  display: inline-block;
  .el-button {
    margin-top: 20px;
    background: #000;
    border: #000;
    color: #33ab4f;
    padding: 15px 50px;
    font-weight: bold;
    font-size: 18px;
  }
  .uploadImg {
    display: inline-block;
    height: 15px;
    width: 15px;
    margin: 6px 10px;
    cursor: pointer;
  }
  .list_box {
    background: #fff;
    position: fixed;
    width: 420px;
    bottom: 0;
    right: 0;
    font-size: 14px;
    z-index: 2;
    .list_header {
      background: #28282e;
      padding: 15px 15px;
      .operate_icon {
        float: right;
        font-weight: bold;
        margin: 3px 10px 0;
        cursor: pointer;
      }
    }
    .upload_list {
      max-height: 200px;
      overflow-y: auto;
      li {
        text-align: left;
        color: #999;
        line-height: 40px;
        padding: 0 15px;
        height: 40px;
        .videoImg,
        .readyImg {
          // vertical-align: middle;
          margin: 0 3px 10px 0;
        }
        .filename {
          display: inline-block;
          max-width: 290px;
        }
        .readyImg {
          opacity: 0.6;
        }
        .el-icon-circle-check {
          font-size: 30px;
          float: right;
          margin: 3px 10px;
          color: #33ab4f;
        }
        .el-icon-warning {
          font-size: 30px;
          float: right;
          margin: 3px 10px;
          color: #f6445a;
        }
      }
      .goDriver {
        color: #999;
        font-size: 14px;
        padding: 10px 15px;
        a {
          color: #444;
          font-weight: bold;
        }
      }
    }
  }
}
/deep/.el-progress--circle {
  float: right;
  margin: 5px 10px;
  .el-progress-circle {
    height: 30px !important;
    width: 30px !important;
  }
  .el-progress__text {
    font-size: 10px !important;
  }
}
/deep/ .el-upload-list {
  display: none;
  // background: #fff;
}
</style>
