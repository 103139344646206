export const register = {
  addUser: 'Register-addUser',
  setPassword: 'Register-setPassword',
  sendEmailAgain: 'Register-sendEmailAgain',
  updateInfo: 'Register-updateInfo'
}
export const commentator = {
  videoCut: 'Event-videoCut',
  overlayCut: 'Event-overlayCut',
  create: 'Event-create',
  shutDown: 'Event-shutdown',
  goLive: 'Event-pushGoLive'
}
