const moment = require('moment')

export const FormatTime = (_date, _pattern, text) => {
  if (!_date) return text || ''
  _pattern = _pattern || 'YYYY-MM-DD HH:mm:ss'
  return moment(_date * 1).format(_pattern)
}
// 每几位中间加个字符
export const returnStrByInfo = function (currentStr, lengths = 4, addStr = ' ') {
  if (currentStr && typeof currentStr === 'number') { currentStr = String(currentStr) }
  if (!currentStr || typeof currentStr != 'string') return
  // const totalNumber = parseInt(currentStr.length/lengths);
  const array = currentStr.split('')
  let _str = ''

  array.forEach((v, i) => {
    if ((i + 1) % lengths === 0 && i != 0) {
      _str += v + addStr
    } else {
      _str += v
    }
  })
  return _str
}
export const duration = function (startTime) {
  const strDate = new Date(startTime) // 开始时间
  const endDate = new Date() // 结束时间
  const diffDate = endDate.getTime() - strDate.getTime() // 时间差的毫秒数

  // 计算出相差天数
  // const days = Math.floor(diffDate / (24 * 3600 * 1000))
  // 计算出小时数
  const leave1 = diffDate % (24 * 3600 * 1000) // 计算天数后剩余的毫秒数
  // const hours = Math.floor(leave1 / (3600 * 1000))
  // 计算相差分钟数
  const leave2 = leave1 % (3600 * 1000) // 计算小时数后剩余的毫秒数
  const minutes = Math.floor(leave2 / (60 * 1000))
  // 计算相差秒数
  const leave3 = leave2 % (60 * 1000) // 计算分钟数后剩余的毫秒数
  const seconds = Math.round(leave3 / 1000)
  return minutes + ':' + seconds
}
export const millisecondConversion = function (duration) {
  const minutes = parseInt((duration / (1000 * 60)) % 60)
  const seconds = parseInt((duration / 1000) % 60)
  const minute = (minutes < 10) ? '0' + minutes : minutes;
  const second = (seconds < 10) ? '0' + seconds : seconds;
  return minute + ':' + second
}
export default {
  FormatTime,
  returnStrByInfo,
  duration,
  millisecondConversion
}
