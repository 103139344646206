<template>
  <div class="lds-roller" v-if="visible">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      visible: false
    }
  }
}
</script>
<style lang="less" scoped>
.lds-roller {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
}
</style>
