<template>
  <span class="checkRFeature">
    <!--问号的提示信息-->
    <!--默认-->
    <el-tooltip class="item" v-show="!checkSucess && loadingShow" effect="dark" :content="$t('coordinationRoom.receiverVersion')" placement="top">
      <div slot="content">
        <p>{{ $t('coordinationRoom.listVersion') }}</p>
        <p>{{ $t('coordinationRoom.eventReceiver') }}</p>
        <div class="defaultContent">
          <p class="checkrSucess">1. {{ $t('coordinationRoom.online') }}</p>
          <p class="checkrSucess">2. {{ $t('coordinationRoom.receiverVersion') }}</p>
          <p class="checkrSucess">3. {{ $t('coordinationRoom.ipAndPort') }}</p>
          <p class="checkrSucess">4. {{ $t('coordinationRoom.remoteCommentator') }}</p>
          <p class="checkrSucess">5. {{ $t('coordinationRoom.hardwareVersion') }}</p>
        </div>
        <p class="checkrSucess" style="color:#6D6D6D;">{{ $t('coordinationRoom.hardwareVersionNote') }}</p>
      </div>
      <i class="iconfont icon-help2"></i>
    </el-tooltip>
    <!--成功-->
    <el-tooltip class="item" v-show="checkSucess" effect="dark" :content="$t('coordinationRoom.receiverVersion')" placement="top">
      <div slot="content">
        <p>{{ $t('coordinationRoom.listVersion') }}</p>
        <p>{{ $t('coordinationRoom.eventReceiver') }}</p>
        <div class="tipSuceess">
          <div>
            <p>{{ $t('coordinationRoom.online') }}</p>
            <div class="rIcon">
              <i class="el-icon-success"></i>
            </div>
          </div>

          <div class="receiverVersionError" @click="closeContent('receiverVersion')">
            <div>
              <i  style="margin-left:-10px;" v-show="receiverVersionClose" class="el-icon-arrow-right"></i>
              <i  style="margin-left:-10px;" v-show="!receiverVersionClose" class="el-icon-arrow-down"></i>
              <p>
              {{ $t('coordinationRoom.receiverVersion') }}
              </p>
            </div>
            <div class="rIcon">
              <i class="el-icon-success"></i>
            </div>
          </div>

          <div class="receiverVersionErrorContent"  v-show="!receiverVersionClose" style="display: block;">
              <p>{{ $t('coordinationRoom.currentVersion') }}{{callBackReceiverVersion}}</p>
              <p v-if="showAbleDelyMessage">{{ $t('coordinationRoom.ableDelyMessage') }}</p>
          </div>

          <div>
            <p>{{ $t('coordinationRoom.ipAndPort') }}</p>
            <div  class="rIcon">
              <i class="el-icon-success"></i>
            </div>
          </div>

          <div>
            <p>{{ $t('coordinationRoom.remoteCommentator') }}</p>
            <div class="rIcon">
              <i class="el-icon-success"></i>
            </div>
          </div>

          <div class="hardWareWarning" :style="{'cursor':!passedHardWareSucess ? 'pointer':''}"  @click="closeContent('hardWareVersion')">
            <div>
              <i style="margin-left:-10px;" v-show="hardWareClose&&!passedHardWareSucess" class="el-icon-arrow-right"></i>
              <i style="margin-left:-10px;" v-show="!hardWareClose&&!passedHardWareSucess" class="el-icon-arrow-down"></i>
              <p>
                {{ $t('coordinationRoom.hardwareVersion') }}
              </p>
            </div>
            <div>
              <i v-show="passedHardWareSucess" class="el-icon-success"></i>
              <i v-show="passedHardWareWarning" class="el-icon-warning"></i>
              <i v-show="passedHardWareUnkown" class="el-icon-warning"></i>
            </div>
          </div>

          <!-- 下方的提示 -->
          <div class="hardWareWarningContent"  v-show="!hardWareClose&&!passedHardWareSucess">
            <!--warning-->
            <div class="hardWareWarningMessage" v-show="passedHardWareWarning">
              <p>{{$t('coordinationRoom.currentVersion')}}{{callBackHardWareVersion}}</p>
              <p>{{$t('coordinationRoom.hardwareVersionWarning')}}</p>
            </div>
            <!--unknow-->
            <p v-show="passedHardWareUnkown">{{$t('coordinationRoom.hardwareVersionUnknown')}}</p>
          </div>

          <div>
            <p style="color:#6D6D6D;">{{ $t('coordinationRoom.hardwareVersionNote') }}</p>
          </div>

        </div>
      </div>
      <i class="iconfont icon-help2"></i>
    </el-tooltip>
    <!--loadingIcon-->
    <i v-show="!loadingShow && hasFeature" class="el-icon-loading"></i>
    <!--失败-->
    <el-tooltip  placement="top">
      <div slot="content" style='width:254px;'>
        <p>{{ $t('coordinationRoom.listVersion') }}</p>
        <p>{{ $t('coordinationRoom.eventReceiver') }}</p>
        <div class="tip" >
            <div class="onlineError">
              <p>
              {{ $t('coordinationRoom.online') }}
              </p>
              <div>
                <i v-show="PassedOne" class="el-icon-success"></i>
                <i v-show="!PassedOne" class="el-icon-error"></i>
              </div>
            </div>
            <!-- 版本开始 -->
            <div class="receiverVersionError" @click="closeContent('receiverVersion')">
              <div>
                <i v-show="receiverVersionClose" class="el-icon-arrow-right"></i>
                <i v-show="!receiverVersionClose" class="el-icon-arrow-down"></i>
                <p>
                {{ $t('coordinationRoom.receiverVersion') }}
                </p>
              </div>
              <div>
                <i v-show="PassedTwo" class="el-icon-success"></i>
                <i v-show="UnPassedTwo" class="el-icon-error"></i>
                <i v-show="NotCheckTwo" class="iconfont icon-Subtract"></i>
              </div>
            </div>

            <div class="receiverVersionErrorContent"  v-show="!receiverVersionClose" style="display: block;">
              <p>{{ $t('coordinationRoom.currentVersion') }}{{callBackReceiverVersion}}</p>
              <p v-if="showAbleDelyMessage">{{ $t('coordinationRoom.ableDelyMessage') }}</p>
            </div>
            <!-- 版本结束 -->

            <div class="ipAndPortError" :style="{'cursor':!NotCheckFourth?'pointer':''}" @click="closeContent('ipAndPort')">
              <div>
                <i v-show="ipArrowClose && !showIpDetail" class="el-icon-arrow-right"></i>
                <i v-show="!ipArrowClose && !showIpDetail" class="el-icon-arrow-down"></i>
                <p :style="{'margin-left':!showIpDetail?'0px':'7px'}">
                  {{ $t('coordinationRoom.ipAndPort') }}
                </p>
              </div>
              <div>
                <i v-show="PassedFourth" class="el-icon-success"></i>
                <i v-show="UnPassedFourth" class="el-icon-error"></i>
                <i v-show="NotCheckFourth" class="iconfont icon-Subtract"></i>
              </div>
            </div>

            <div class="ipAndPortErrorContent" v-show="!ipArrowClose && !showIpDetail">
              <p>{{checkUrl?checkUrl:$t('coordinationRoom.notFoundIpAndPort') }} </p>
            </div>

            <div class="funtionError" :style="{'cursor':!NotCheckThird?'pointer':''}" @click="closeContent('funtionEnable')">
              <div>
                <i v-show="funtionClose && !showFunctionDetail" class="el-icon-arrow-right"></i>
                <i v-show="!funtionClose && !showFunctionDetail" class="el-icon-arrow-down"></i>
                <p :style="{'margin-left':!showFunctionDetail?'0px':'7px'}">
                  {{ $t('coordinationRoom.remoteCommentator') }}
                </p>
              </div>
              <div>
                <i v-show="PassedThird" class="el-icon-success"></i>
                <i v-show="UnPassedThird" class="el-icon-error"></i>
                <i v-show="NotCheckThird" class="iconfont icon-Subtract"></i>
              </div>
            </div>

            <div class="funtionErrorContent" v-show="!funtionClose && !showFunctionDetail">
              <p>{{ $t('coordinationRoom.remoteCommentatorErrorInfo')}}</p>
            </div>
            <!--hardware前面失败后只有未检测-->
            <div class="hardWareError">
              <p>
                {{ $t('coordinationRoom.hardwareVersion') }}
              </p>
              <div>
                <i v-show="notCheckWare" class="iconfont icon-Subtract"></i>
              </div>
            </div>

            <div class="singleRnote">
              <p style="color:#6D6D6D;">
              {{ $t('coordinationRoom.hardwareVersionNote') }}
              </p>
            </div>

        </div>
      </div>
      <i v-show="!hasFeature && !loadingShow" class="el-icon-warning"></i>
    </el-tooltip>
  </span>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: {
    receiverMainOrBack: {
      type: String
    }
  },
  computed: {
    ...mapState({
      State: (state) => state
    })
  },
  data () {
    return {
      showAbleDelyMessage: false,
      checkSucess: false,
      loadingShow: true,
      hardWareClose: true,
      hasFeature: true,
      receiverVersionClose: true,
      ipArrowClose: true,
      funtionClose: true,
      showIpDetail: false,
      showFunctionDetail: false,
      passedHardWareSucess: '',
      passedHardWareWarning: '',
      passedHardWareUnkown: '',
      callBackReceiverVersion: '',
      callBackHardWareVersion: '',
      PassedOne: '',
      PassedTwo: '',
      UnPassedTwo: '',
      NotCheckTwo: '',
      PassedThird: '',
      UnPassedThird: '',
      NotCheckThird: '',
      PassedFourth: '',
      UnPassedFourth: '',
      NotCheckFourth: '',
      notCheckWare: '',
      checkUrl: ''
    }
  },
  methods: {
    closeContent (val) {
      if (val === 'hardWareVersion') {
        this.hardWareClose = !this.hardWareClose
      } else if (val === 'funtionEnable') {
        this.funtionClose = !this.funtionClose
      } else if (val === 'receiverVersion') {
        this.receiverVersionClose = !this.receiverVersionClose
      } else {
        this.ipArrowClose = !this.ipArrowClose
      }
    },
    setDefault () {
      this.checkSucess = false
      this.hasFeature = true
      this.loadingShow = true
    },
    async checkRFeature (receiverPeerId) {
      // 重置展开按钮状态
      this.receiverVersionClose = true
      this.funtionClose = true
      this.ipArrowClose = true
      this.hardWareClose = true

      this.showIpDetail = false
      this.showFunctionDetail = false
      this.showAbleDelyMessage = false
      if (this.receiverMainOrBack == 'mainReceiver') {
        this.State.mainReceiverAbleDely = false
      } else {
        this.State.backReceiverAbleDely = false
      }
      this.checkSucess = false
      this.loadingShow = false
      this.hasFeature = true
      let hasFeature = true
      await this.$http
        .post(`/commentator-backend/receiver/checkCommentatorFeature/${receiverPeerId}`, {}, { errorTips: false })
        .then(res => {
          const { errorCode, result } = res.data
          if (errorCode != '0x0') {
            hasFeature = false
            setTimeout(() => {
              this.hasFeature = false
            }, 500)
            this.callBackReceiverVersion = result ? result.version : ''
            this.checkUrl = result ? result.checkUrl : ''
            // 未检查 0
            // 未通过 -1
            // 通过 1
            result.infos && result.infos.forEach((item, index, val_arr) => {
              if (val_arr[index].message == 'online') {
                if (val_arr[index].status == 1) {
                  this.PassedOne = true
                } else if (val_arr[index].status == -1) {
                  this.PassedOne = false
                }
              } else if (val_arr[index].message == 'version') {
                if (val_arr[index].status == 1) {
                  this.PassedTwo = true
                  this.UnPassedTwo = false
                  this.NotCheckTwo = false
                } else if (val_arr[index].status == -1) {
                  this.UnPassedTwo = true
                  this.PassedTwo = false
                  this.NotCheckTwo = false
                } else if (val_arr[index].status == 0) {
                  this.NotCheckTwo = true
                  this.PassedTwo = false
                  this.UnPassedTwo = false
                }
              } else if (val_arr[index].message == 'feature') {
                if (val_arr[index].status == 1) {
                  this.PassedThird = true
                  this.UnPassedThird = false
                  this.NotCheckThird = false
                } else if (val_arr[index].status == -1) {
                  this.UnPassedThird = true
                  this.PassedThird = false
                  this.NotCheckThird = false
                } else if (val_arr[index].status == 0) {
                  this.showFunctionDetail = true
                  this.NotCheckThird = true
                  this.PassedThird = false
                  this.UnPassedThird = false
                }
              } else if (val_arr[index].message == 'ipPortMapping') {
                if (val_arr[index].status == 1) {
                  this.PassedFourth = true
                  this.UnPassedFourth = false
                  this.NotCheckFourth = false
                } else if (val_arr[index].status == -1) {
                  this.UnPassedFourth = true
                  this.PassedFourth = false
                  this.NotCheckFourth = false
                } else if (val_arr[index].status == 0) {
                  this.showIpDetail = true
                  this.NotCheckFourth = true
                  this.PassedFourth = false
                  this.UnPassedFourth = false
                }
              } else if (val_arr[index].message == 'hardWareVersion') {
                if (val_arr[index].status == 0) {
                  this.notCheckWare = true
                }
              } else if (val_arr[index].message == 'session') {
                if (val_arr[index].status == 1) {
                  this.showAbleDelyMessage = true
                  if (this.receiverMainOrBack == 'mainReceiver') {
                    this.State.mainReceiverAbleDely = true
                  } else {
                    this.State.backReceiverAbleDely = true
                  }
                }
              }
            })
            // this.$message.error(this.$t('coordinationRoom.unavailable'))
          } else {
            this.callBackReceiverVersion = result ? result.version : ''
            this.callBackHardWareVersion = result.currentHwVersion
            result.infos && result.infos.forEach((item, index, val_arr) => {
              if (val_arr[index].message == 'hardWareVersion') {
                if (val_arr[index].status == 1) {
                  this.passedHardWareSucess = true
                  this.passedHardWareWarning = false
                  this.passedHardWareUnkown = false
                } else if (this.callBackHardWareVersion && val_arr[index].status != 1) {
                  this.passedHardWareWarning = true
                  this.passedHardWareSucess = false
                  this.passedHardWareUnkown = false
                } else if (!this.callBackHardWareVersion && val_arr[index].status != 1) {
                  this.passedHardWareUnkown = true
                  this.passedHardWareWarning = false
                  this.passedHardWareSucess = false
                }
              } else if (val_arr[index].message == 'feature') {
                if (val_arr[index].status == 1) {
                  this.PassedThird = true
                  this.UnPassedThird = false
                  this.NotCheckThird = false
                } else if (val_arr[index].status == -1) {
                  this.UnPassedThird = true
                  this.PassedThird = false
                  this.NotCheckThird = false
                } else if (val_arr[index].status == 0) {
                  this.showFunctionDetail = true
                  this.NotCheckThird = true
                  this.PassedThird = false
                  this.UnPassedThird = false
                }
              } else if (val_arr[index].message == 'session') {
                if (val_arr[index].status == 1) {
                  this.showAbleDelyMessage = true
                  if (this.receiverMainOrBack == 'mainReceiver') {
                    this.State.mainReceiverAbleDely = true
                  } else {
                    this.State.backReceiverAbleDely = true
                  }
                }
              }
            })
            this.hasFeature ? this.checkSucess = true : this.checkSucess = false
            this.loadingShow = true
          }
        })
      return hasFeature
    }
  }
}
</script>
<style lang="less">
.defaultContent{
  margin-left: 4px;
}
.checkrSucess{
  margin-top:3px;
}
 .icon-Subtract{
  font-size:12px !important;
}
.tipSuceess{
  margin-left:10px;
  div:not(.hardWareWarningMessage){
    display:flex;
    margin-top:3px;
  }
  div {
    justify-content:space-between;
  }
  .receiverVersionError{
    cursor:pointer;
  }
  .receiverVersionErrorContent{
    margin-left:-4px;
    background: #33ab4f1A;
    border-radius: 2px;
    p{
      padding-left:4px;
      word-break: break-all;
    }
  }
  .hardWareWarningContent{
    margin-left:-4px;
    background: #FF9A241A;
    border-radius: 2px;
    p{
      padding-left:4px;
      word-break: break-all;
    }
  }
}
.tip {
  div{
      display:flex;
      margin-top:3px;
      justify-content:space-between;
    }
  .onlineError,.singleRnote,.hardWareError{
    margin-left:7px;
  }
  .receiverVersionError{
    cursor:pointer;
  }
  .receiverVersionErrorContent{
    margin-left:-4px;
    background: #33ab4f1A;
    border-radius: 2px;
    p{
      padding-left:4px;
      word-break: break-all;
    }
  }
  .ipAndPortErrorContent,.hardWareErrorContent,.funtionErrorContent{
    margin-left:-4px;
    background: rgba(246, 68, 90, 0.1);
    border-radius: 2px;
    p{
      padding-left:4px;
      word-break: break-all;
    }
  }
}
.el-icon-success{
  color:#33AB4F;
}
.el-icon-error{
  color:#FA5555;
}
.el-icon-warning{
  color: #FFB524;
}
</style>
