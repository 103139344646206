var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":_vm.$t('eventList.ParticipationSettings'),"visible":_vm.info.show,"show-close":_vm.info.userInfo.nickName ? true : false,"top":"10px","close-on-click-modal":false,"append-to-body":true,"width":"450px"},on:{"update:visible":function($event){return _vm.$set(_vm.info, "show", $event)}}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"nickname",class:{ red: _vm.redTips }},[_c('span',[_c('label',[_vm._v("*")]),_vm._v(_vm._s(_vm.$t('coordinationRoom.name'))+":")]),_c('el-input',{attrs:{"maxlength":32},on:{"blur":function($event){return _vm.blurFn('nickName')}},model:{value:(_vm.nickName),callback:function ($$v) {_vm.nickName=$$v},expression:"nickName"}}),(_vm.redTips)?_c('p',[_vm._v(_vm._s(_vm.redTips))]):_vm._e()],1),(
        _vm.$route.name == 'reporterParticipate' || _vm.$route.name == 'coordinator'
      )?_c('div',{staticClass:"nickname",class:{ red: _vm.redTips1 },staticStyle:{"margin-bottom":"20px"}},[_c('span',[_c('label',[_vm._v("*")]),_vm._v(_vm._s(_vm.$t('coordinationRoom.corporation'))+":")]),_c('el-input',{attrs:{"maxlength":256},on:{"blur":function($event){return _vm.blurFn('corporation')}},model:{value:(_vm.organization),callback:function ($$v) {_vm.organization=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"organization"}}),(_vm.redTips1)?_c('p',[_vm._v(_vm._s(_vm.redTips1))]):_vm._e()],1):_vm._e(),(!_vm.info.hideBtn)?_c('div',[_c('span',{staticClass:"aduio"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"Sound of the site","placement":"bottom"}},[_c('i',{staticClass:"iconfont icon-speakeron"})]),_c('span',{staticClass:"btn"},[_c('i',{class:{ gray: _vm.setInfo.speaker },on:{"click":function($event){return _vm.setBtn('speaker', false)}}},[_vm._v(_vm._s(_vm.$t('coordinationRoom.on'))+" ")]),_vm._v("/ "),_c('i',{class:{ gray: !_vm.setInfo.speaker },on:{"click":function($event){return _vm.setBtn('speaker', true)}}},[_vm._v(_vm._s(_vm.$t('coordinationRoom.off')))])])],1),_c('span',{staticClass:"aduio"},[(_vm.info.cameraId)?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"Camera","placement":"bottom"}},[_c('i',{staticClass:"iconfont icon-universal_cameraon"})]):_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"No access","placement":"bottom"}},[_c('i',{staticClass:"iconfont"},[_c('svg',{staticClass:"iconfont carmerHover",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#icon-camerahover"}})])])]),_c('span',{staticClass:"btn"},[_c('i',{class:{
              gray: _vm.setInfo.camera || !_vm.info.cameraId,
              notAllowed: !_vm.info.cameraId
            },on:{"click":function($event){return _vm.setBtn('camera', false)}}},[_vm._v(_vm._s(_vm.$t('coordinationRoom.on')))]),_vm._v("/ "),_c('i',{class:{
              gray: !_vm.setInfo.camera && _vm.info.cameraId,
              notAllowed: !_vm.info.cameraId
            },on:{"click":function($event){return _vm.setBtn('camera', true)}}},[_vm._v(_vm._s(_vm.$t('coordinationRoom.off')))])])],1),_c('span',{staticClass:"aduio"},[(_vm.info.microphoneId)?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"Audio","placement":"bottom"}},[_c('i',{staticClass:"iconfont icon-universal_micon"})]):_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"No access","placement":"bottom"}},[_c('i',{staticClass:"iconfont"},[_c('svg',{staticClass:"iconfont audio carmerHover",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#icon-Michover"}})])])]),_c('span',{staticClass:"btn"},[_c('i',{class:{
              gray: _vm.setInfo.mic || !_vm.info.microphoneId,
              notAllowed: !_vm.info.microphoneId
            },on:{"click":function($event){return _vm.setBtn('mic', true)}}},[_vm._v(_vm._s(_vm.$t('coordinationRoom.on')))]),_vm._v("/ "),_c('i',{class:{
              gray: !_vm.setInfo.mic && _vm.info.microphoneId,
              notAllowed: !_vm.info.microphoneId
            },on:{"click":function($event){return _vm.setBtn('mic', false)}}},[_vm._v(_vm._s(_vm.$t('coordinationRoom.off')))])])],1)]):_vm._e()]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[(_vm.info.userInfo.nickName)?_c('el-button',{staticClass:"gray",on:{"click":_vm.cancelFn}},[_vm._v(_vm._s(_vm.$t('coordinationRoom.cancel')))]):_vm._e(),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t('coordinationRoom.submit')))])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }