<template>
  <div class="networkConnect">
    <span class="content"><i class="el-icon-warning"></i>{{ $t(`HostCommon.${text ? text : msg}`) }}</span>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      msg: 'notConnect'
    }
  },
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  created () {
    window.addEventListener('online', (info) => {
      this.State.networkConnect = true
    })
    window.addEventListener('offline', (info) => {
      this.State.networkConnect = false
    })
  },
  computed: {
    ...mapState({
      State: state => state
    })
  }
}
</script>
<style lang="less" scoped>
.networkConnect {
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  text-align: center;
  z-index: 20000;
  color: #e6a23c;
  .content {
    margin-top: 20px;
    display: inline-block;
    font-size: 14px;
    padding: 10px 18px;
    background-color: #fdf6ec;
    border-color: #faecd8;
    border-radius: 4px;
    text-align: left;
    .el-icon-warning {
      margin-right: 10px;
    }
  }
}
</style>
