/**
 * wsRtilCode: ws 推送过来需要订阅的 rtilCode
 * AgoraRtilCode: Agora 推送 videoTrack 或者 AudioTrack 的 rtilCode
 * replaceRtilCode: 需要替换的 rtilCode
 */
import { showVolume } from '@/assets/js/utils.js'

class SubscribeTrack {
  constructor () {
    this.wsRtilCode = {}
    this.AgoraRtilCode = {}
    this.replaceRtilCode = {}
  }

  addReplaceRtilCode (videoRtilCode, audioRtilCode) {
    this.replaceRtilCode[videoRtilCode] = audioRtilCode
    console.log(111111111, this.replaceRtilCode)
  }

  dealTrack (rtc, payload) {
    // videoKeyAudioValue
    const { id } = payload
    let isNeedReplace = false
    let replaceRtilCode = ''
    // 判断是否有要替换的值
    Object.keys(this.replaceRtilCode).forEach(videoRtilCode => {
      if (this.replaceRtilCode[videoRtilCode] == id) {
        replaceRtilCode = videoRtilCode
        isNeedReplace = true
      }
    })
    if (this.replaceRtilCode[id] || isNeedReplace) {
      return isNeedReplace ? this.replaceTrack(rtc, payload, replaceRtilCode, id) : this.replaceTrack(rtc, payload, id, this.replaceRtilCode[id])
    } else {
      return payload
    }
  }

  replaceTrack (rtc, payload, replaceRtilCode, changeToRtilCode) {
    const remoteUser = rtc._remotePublishUser[changeToRtilCode]
    if (remoteUser && remoteUser._audioTrack) {
      payload.audioTrack = remoteUser._audioTrack
      // 音量柱的显示
      showVolume(remoteUser._audioTrack, replaceRtilCode)
    }
    return payload
  }
}

export default new SubscribeTrack()
