import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import axios from '@/assets/js/axiosExtend.js'
import axios from 'axios'
import ElementUI from 'element-ui'
// import locale from 'element-ui/lib/locale/lang/en'
import axiosExtend from './assets/js/axiosExtend'
import Scroll from '@/directive/scroll' // Scroll loading
import Loading1 from '@/directive/loading/index.js'
import VueClipboard from 'vue-clipboard2'
import '@/assets/icons/iconfont.css'
import '@/assets/icons/iconfont.js'
// import $ from "jquery";
import i18n from '@/lang/index.js'

import '@/assets/css/index.less'
import 'element-ui/lib/theme-chalk/index.css'

import FullCalendar from 'vue-full-calendar'
import '@/assets/css/fullcalendar.css'

// 遍历注册全局过滤器
import filters from './assets/js/filters'

// 节流
import repeatClick from '@/directive/repeatClick'
import { BackAndFrontVersion, ServiceJumpCollect, HelpCenter, TopHeader } from 'tvu-common-ui'
import 'babel-polyfill'
// 集成sentry
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

Vue.use(ServiceJumpCollect)
Vue.use(HelpCenter)
Vue.use(TopHeader)

Vue.prototype.$backAndFrontVersion = BackAndFrontVersion;
Vue.use(FullCalendar)
Object.keys(filters).forEach((key) => Vue.filter(key, filters[key]))
Vue.use(repeatClick)

Vue.config.productionTip = false
VueClipboard.config.autoSetContainer = true

// Vue.use(ElementUI, { locale })
Vue.use(axiosExtend)
Vue.use(Scroll)
Vue.use(VueClipboard)
Vue.use(Loading1)

Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
})

const $message = options => {
  if (options.single) {
    if (document.getElementsByClassName('el-message').length === 0) {
      return ElementUI.Message({
        ...options,
        offset: 50,
        showClose: true
      })
    }
  } else {
    return ElementUI.Message({
      ...options,
      offset: 50,
      showClose: true
    })
  }
}
['success', 'warning', 'info', 'error'].forEach((type) => {
  $message[type] = (options) => {
    if (typeof options === 'string') {
      options = {
        message: options,
        offset: 50,
        showClose: true
      }
    }
    options.type = type
    if (options.single) {
      if (document.getElementsByClassName('el-message').length === 0) {
        return ElementUI.Message(options)
      }
    } else {
      return ElementUI.Message(options)
    }
  }
})
// Vue.prototype.$ = $;
Vue.prototype.$message = $message
window.Vue = Vue
// 供集成的外部js使用
window.JSMessage = ElementUI.Message
window.JSConfirm = ElementUI.MessageBox

// 用于集成mediaService
Vue.prototype.$store = store
window.i18n = i18n

// Vue.config.errorHandler = function (err, vm, info) {
//   // `err` 是错误对象
//   // `vm` 是抛出错误的Vue实例
//   // `info` 是有关错误所在生命周期钩子的信息
//   console.error(`Error: ${err.toString()}\nInfo: ${info}`);
// };
window.vm = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
// 集成sentry
if (!location.host.includes('cn')) {
  // if (process.env.NODE_ENV === 'development') {
  //   Sentry.init({
  //     Vue,
  //     dsn: 'https://aed40419434a4badbd6ffc756dee3f03@o982456.ingest.sentry.io/4504915364020224',
  //     integrations: [
  //       new BrowserTracing({
  //         routingInstrumentation: Sentry.vueRouterInstrumentation(router),
  //         tracingOrigins: ['localhost', 'local@tvunetworks.com', /^\//]
  //       })
  //     ],
  //     tracesSampleRate: 0.01,
  //     environment: store.state.config.env
  //   })
  // } else
  if (process.env.NODE_ENV === 'production') {
    Sentry.init({
      Vue,
      dsn: 'https://aed40419434a4badbd6ffc756dee3f03@o982456.ingest.sentry.io/4504915364020224',
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ['localhost', 'local@tvunetworks.com', /^\//]
        })
      ],
      tracesSampleRate: 0.01,
      environment: store.state.config.env
    })
  }
}
const versionControls = store.state.config
if (versionControls.versionControl) {
  Vue.prototype.$TVU = {
    http: axios,
    userGroup: 'https://' + versionControls.versionControl.versionApiUrl
  }
}
