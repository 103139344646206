import axios from 'axios'
import { Message } from 'element-ui'
import $store from '@/store/index.js'
import Cookies from 'js-cookie'

// 监听网络状态
let internetIsOnline = true
window.addEventListener('online', () => {
  internetIsOnline = true
  $store.commit('setInternetIsOnline', internetIsOnline)
})

window.addEventListener('offline', () => {
  internetIsOnline = false
  $store.commit('setInternetIsOnline', internetIsOnline)
})

// 用于连续多次点击时，取消之前的请求，之保留最后一次的请求状态
const pending = [] // 声明一个数组用于存储每个正在pending的状态请求
const CancelToken = axios.CancelToken
const removePending = (config) => {
  for (const i in pending) {
    if (pending[i].u === config.url + '&' + config.method) {
      // 当当前请求在数组中存在时执行函数体
      pending[i].f() // 执行取消操作
      pending.splice(i, 1)
    }
  }
}

// 设置默认超时时间
axios.defaults.timeout = 60000
axios.defaults.withCredentials = true

// 拦截器
axios.interceptors.request.use(
  (config) => {
    if (!internetIsOnline) {
      Message({
        message: 'Internet connection failed, please try again later',
        type: 'error'
      })
      return
    }

    if (config.isCancelToken) {
      removePending(config) // 在一个axios发送前执行一下取消操作
      config.cancelToken = new CancelToken((cancel) => {
        // 这里的axios标识用请求地址&请求方式拼接的字符串
        pending.push({ u: config.url + '&' + config.method, f: cancel })
      })
    }

    /* if(config.method  === 'post' && config.url.indexOf("unvarnishedTransmission") > -1 ){
      config.data = Qs.stringify(config.data);
    } */

    if (config.requestBase && config.requestBase === 'GATEWAY') {
      const protocol = window.location.protocol
      const url = `${protocol}//${$store.state.config.urlInfo.gateway}/drive`
      config.baseURL = url
      config.headers.Authorization =
        Cookies.get('SID') || localStorage.getItem('commentatorSession')
    }
    // config.headers.sid =
    // Cookies.get('SID') || localStorage.getItem('commentatorSession') // localStorage.getItem('commentatorSession')
    return config
  },
  (err) => {
    return Promise.reject(err)
  }
)
let lastErrorMessage = '' // 保存上一次错误信息
axios.interceptors.response.use(
  (response) => {
    if (response.config && response.config.isCancelToken) {
      removePending(response.config) // 在一个axios响应后再执行一下取消操作，把已经完成的请求从pending中移除
    }
    if (response.data.errorCode === '82200301') {
      Message({
        message: 'Please check the Receiver.',
        type: 'error'
      })
    } else if (response.data.errorCode === '82200302') {
      Message({
        message: 'Please try again.',
        type: 'error'
      })
    } else if (response.data.errorCode === '82200304') {
      Message({
        message:
          'The current network connection is faulty. Please check the following:- R port need to be correct.- Network need to be stable.- The pairing/connection between TVU Anywhere and Receiver failed, please try again.',
        type: 'error'
      })
    } else if (response.config.method == 'post' &&
      response.data.errorCode !== '0x0' && response.config.errorTips !== false
    ) {
      Message({
        message: response.data.errorInfo,
        type: 'error'
      })
    } else if (response.config.method == 'get' &&
      response.data.errorCode !== '0x0' && response.config.errorTips !== false
    ) {
      Message({
        message: response.data.errorInfo,
        type: 'error'
      })
    } else if (response.data.errorCode === '81000002' || response.data.errorCode === '0x80060005') {
      // 直接返回response即可，不弹出任何信息
      return response;
    }
    return response
  },
  (error) => {
    console.log('error', error.response)
    if (error.response) {
      switch (error.response.status) {
        case 401: {
          localStorage.removeItem('commentatorSession')
          if ($store.state.config && $store.state.config.urlInfo) {
            window.location.href = `${$store.state.config.urlInfo.userservice}/?url=${location.protocol}//${location.host}/commentator&serviceName=${$store.state.config.serverName}`
          }
        }
        case 503:
          Message({
            message: 'Service Unavailable,  please try again later',
            type: 'error'
          })
          break
        // 超时
        case 504:
          Message({
            message: 'Request timed out,  please try again later',
            type: 'error',
            single: true
          })
          break
        // 服务
        case 500:
          Message({
            message: 'Internal server error,  please try again later',
            type: 'error'
          })
          break
      }
    }
    //  else if (error.message && error.message.includes('timeout')) {
    //   Message({
    //     message: 'Request timed out,  please try again later',
    //     type: 'error'
    //   })
    // }
    if (error.message && error.message.includes('timeout')) {
      if (error.message !== lastErrorMessage) {
        Message({
          message: 'Request timed out,  please try again later',
          type: 'error',
          single: true
        })
        if (error.config && error.config.showAlert) {
          $store.state.newPageLoading = false
          Vue.prototype.$alert('Request timed out, please click refresh to reload the page', {
            confirmButtonText: 'Refresh ',
            customClass: 'reloadAlert',
            confirmButtonClass: 'reloadBtn',
            showClose: false,
            showCancelButton: false,
            closeOnClickModal: false,
            closeOnPressEscape: false
          }).then(() => {
            location.reload(); // 重新加载页面
          })
        }
        lastErrorMessage = error.message // 更新上一次错误信息
      }
    }
    if (!error.message) {
      return {
        data: {
          errorCode: '8220000',
          errorInfo: 'Cancel'
        }
      }
    }
    if (error) {
      return Promise.reject(error)
    }
  }
)

export const $http = axios

export default {
  install: function (vm) {
    vm.prototype.$http = axios
  }
}
