export async function getMediaDevice (rtc) {
  const device = await rtc.getDevice()
  return device
}

export async function createCameraVideoTrack ({ cameraId, config = {}, videoConfig, AgoraRTC }) {
  const track = await AgoraRTC.createCameraVideoTrack({
    cameraId: cameraId, // 指定摄像头
    encoderConfig: videoConfig ? {
      width: videoConfig[0].width,
      height: videoConfig[0].height,
      frameRate: {
        min: 15,
        max: videoConfig[0].fps
      },
      bitrateMin: videoConfig[0].bitrate,
      bitrateMax: videoConfig[0].bitrate
    } : {
      // 视频分辨率
      width: config.width || 640,
      height: config.height || 360
    }
  })
  return track
}

export async function playVideoTrack (track, id) {
  track && await track.play(id ? id : 'local_stream', {
    mirror: true
  })
}

export async function createMicrophoneAudioTrack ({ microphoneId, config, audioConfig, AgoraRTC }) {
  let track = null
  let params = null
  if (audioConfig) {
    const { audioProfil, checkedAec, checkedAns, checkedAgc } = audioConfig
    const encoderConfig = audioProfil ? audioProfil : 'music_standard'
    params = {
      AEC: checkedAec != undefined ? checkedAec : true, //回声消除
      ANS: checkedAns != undefined ? checkedAns : true, //噪声抑制
      AGC: checkedAgc != undefined ? checkedAgc : true,  //麦克风增强
      microphoneId, //指定麦克风
      encoderConfig //音频参数
    }
  } else {
    params = {
      AEC: config.AEC, // 回声消除
      ANS: config.ANS, // 噪声抑制
      microphoneId: microphoneId // 指定麦克风
    }
  }
  track = await AgoraRTC.createMicrophoneAudioTrack(params)
  return track
}

export async function createMediaTrack ({ type, list, videoConfig, AgoraRTC }) {
  let track = null
  const promises = []
  if (type === 'video') {
    list.forEach(info => {
      promises.push(createCameraVideoTrack({ cameraId: info.deviceId, config: {}, videoConfig, AgoraRTC }))
    })
    await Promise.any(promises)
    .then(result => {
      if (!result) return
      track = result
      localStorage.setItem('cameraId', result._config.cameraId)
    }).catch (e => {
      console.log(e)
    })
    return track
  }
}

export async function changeDeviceAndSaveId ({ type, deviceId, track, devicesInfo }, cb) {
  if (!type || !deviceId || !track) return
  let key = retrunDeviceKey(type)
  try {
    track.setDevice(deviceId).then(() => {
      if (cb) {
        localStorage.setItem(key, deviceId)
        devicesInfo[key] = deviceId
        cb({ type: 'success' })
      }
    }).catch ((e) => {
      if (cb) {
        cb({ type: 'failed' })
      }
    })
  } catch (e) {
    console.log(e)
  }
}

export function retrunDeviceKey (type) {
  let key = ''
  switch (type) {
    case 'video':
      key = 'cameraId'
      break
    case 'audio':
      key = 'microphoneId'
      break
    case 'speaker':
      key = 'speakerId'
      break
  }
  return key
}

export async function getMicAndSpeakerDevices (rtc) {
  if (!rtc) return
  let microphoneId = ''
  let microphoneArray = []
  let speakerId = ''
  let speakerArray = []
  microphoneArray = await rtc._AgoraRTC.getMicrophones().catch(() => console.log('getMicrophones error'))
  if (microphoneArray && microphoneArray.length > 0) {
    microphoneId = 'default'
    localStorage.setItem('microphoneId', 'default')
  }
  speakerArray = await rtc._AgoraRTC.getPlaybackDevices().catch(() => console.log('getPlaybackDevices error'))
  if (speakerArray && speakerArray.length > 0) {
    speakerId = 'default'
    localStorage.setItem('speakerId', 'default')
  }
  return {
    microphoneId,
    microphoneArray,
    speakerId,
    speakerArray
  }
}