/**
 * 使用该指令需要传递一个对象
 * {
 *    onScroll: this.onScroll  滚动加载时触发的回调
 *    distance: 10, 距离底部多少距离的时候触发回调 默认是100px
 *    directive: '.el-select-dropdown .el-select-dropdown__wrap' 你需要绑定的dom的class，默认为绑定的指令的当前dom
 * },
 * **/

scroll.install = function (Vue) {
  // 必须出现滚动条才可触发
  Vue.directive('scroll', {
    bind: (el, binding) => {
      // 是否执行回调事件
      let isCallback = true
      // 距离底部剩余距离开始触发回调
      const distance = binding.value.distance || 100
      const scrollDirection = binding.value.scrollDirection || 'bottom'
      // 以后绑定的哪个dom就将该DOM绑定给directive
      const elDom = binding.value.directive
        ? el.querySelector(binding.value.directive)
        : el
      // 监听滚动事件
      if (!elDom) {
        return
      }
      elDom.onscroll = (e) => {
        let isBtn = false
        // 获取当前节点可滚动的距离
        const scrollHeight = e.target.scrollHeight - e.target.clientHeight
        // 获取节点剩余可滚动的高度
        const residualHeight = scrollHeight - e.target.scrollTop
        //  滚动到指定区域执行回调事件
        if (scrollDirection == 'top') {
          isBtn = e.target.scrollTop == 0
        } else if (scrollDirection == 'bottom') {
          isBtn = residualHeight <= distance
        }
        if (
          typeof binding.value.onScroll === 'function' &&
          isBtn &&
          isCallback
        ) {
          // 执行事件回调函数
          binding.value.onScroll()
          isCallback = false
        } else if (residualHeight > distance) {
          isCallback = true
        }
      }
    }
  })
}
export default scroll
