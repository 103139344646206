export default {
  HostCommon: {
    transceiver: 'Transceiver',
    cloudR: 'Cloud',
    totalConsumedTime: 'Total Consumed this Month',
    hs: 'hs',
    min: 'mins',
    timeRemain: 'Time Remaining',
    purchase: 'Purchase',
    productTitle: 'Remote Commentator',
    notConnect: 'Not connected. Connecting...',
    receiverBackupTips: '(To keep the output sources synchronized), Please set the (same) delay on Transceiver with versions lower than 7.9.',
    participateSetting: 'Participate Setting',
    partyName: 'Party name',
    nickName: 'Nickname',
    startNow: 'Start now',
    installAnyWhereText: 'Open/Install TVU Anywhere app',
    testMic: 'Test your Microphone',
    MicTest: 'Microphone testing',
    MicrophoneSelection: 'Microphone selection',
    startTesting: 'Start testing',
    stopTesting: 'Stop testing',
    note: 'Note: If you can hear yourself, then the microphone is working properly.',
    inputLevel: 'Input level',
    noPermission: 'No Permission',
    participantPassCode: 'Participant passcode',
    enterPassWord: 'Enter participant passcode',
    caseSensitive: 'Passcode is case sensitive',
    accountNotAllowed: 'Your account is not allowed by host. Please contact them for permission.',
    backHome: 'Back to Home',
    joinParty: 'Join Party',
    incorrectPassword: 'Incorrect password',
    notLogin: 'The current meeting mode requires an invitation, please log in first.',
    initCameraTips: 'The current {name} is not available, please switch another avaible device',
    initVideoTrack: 'Camera is connecting, please wait.',
    switchFailed: 'Switch to the current camera failed.',
    reNameSuccess: 'Rename successful',
    renameParticipantSuceess: 'Rename participant successful',
    rejoinR: 'Rejoin R',
    rejoinRText: 'The commentator is disconnected.',
    operateSucces: 'Operate successfully.'
  },
  operationBtn: {
    AudioOnlyEnabled: 'Audio-Only Mode enabled',
    on: 'Turn on',
    off: 'Turn off',
    camera: 'Camera',
    microphone: 'mic',
    speaker: 'speaker',
    selectMicrophone: 'Select a Microphone',
    selectCamera: 'Select a Camera',
    selectSpeaker: 'Select a Speaker',
    shareScreen: 'Share Screen',
    stopShareScreen: 'Stop Screen Share',
    chat: 'Chat',
    tagExplain: 'After a command from Global,the tagged participants will go on its party-Main Window.',
    notAllowed: 'Private chat is unavailable when Audio Matrix is on.'
  },
  personalInfo: {
    usagePage: 'Usage',
    usage: 'Usage',
    Good: 'Good',
    Poor: 'Poor',
    Mid: 'Mid',
    helpCenter: 'Helper',
    bandWidth: 'Bandwidth Quality',
    cloudProxy: 'Cloud Proxy',
    onCloud: 'Turn on cloud proxy',
    offCloud: 'Turn off cloud proxy',
    lang: 'Language',
    logOut: 'Log out',
    login: 'Login',
    register: 'Register'
  },
  leftPane: {
    input: 'Input',
    ppPgm: 'PP PGM',
    ppSlot: 'PP Slot',
    transGrid: 'TVU Grid',
    upLoadFile: 'Upload files',
    extIpSource: 'EXT IP Source',
    pack: 'Pack',
    tmpPush: 'RTMP Push',
    sdiIn: 'SDI IN',
    add: 'Add',
    inputSelectInputSource: 'Please select the Input source in Producer.',
    note: 'Note:',
    noteWords: 'The designated slot will always be the input source, no matter if it is in PP PGM or not.',
    rename: 'Rename',
    more: 'More',
    del: 'Delete',
    upLoadOneFile: 'Uploading 1 file',
    upLoadFileComplete: 'Upload to complete',
    fileSynchronizedTo: 'The uploaded files have been synchronized to',
    tvuDriver: 'TVU Driver',
    renameFileSuccess: 'update success',
    delTips: 'Are you sure you want to delete it?',
    cancel: 'Cancel',
    delSuccessTips: 'Delete Success',
    extIpSourceSetting: 'EXT IP Source Settings',
    sourceName: 'Name',
    lessThanWords: 'Less than 100 characters',
    supportMov: 'Support MOV, MP4 file',
    clickBtn: 'Click add button to select',
    fileUpload: 'files to upload'
  },
  leftPaneOutPut: {
    output: 'Output',
    receiver: 'Receiver',
    receiverSetting: 'Receiver Settings',
    receiverName: 'Receiver Name',
    followEvent: 'Follow "event Format"',
    issLive: 'The ISSP that Receiver live with',
    ipOut: 'IP OUT',
    searchReceiver: 'Search Receiver',
    searchOutputName: 'Search output name',
    selected: 'selected',
    noAvailableIpout: 'No available IP OUT',
    clickAddBtn: 'Click add button to add',
    destination: 'destinations.',
    noResult: 'No Result',
    changeKeyWords: 'Please change the keyboard',
    save: 'Save',
    edit: 'Edit',
    gridSetting: 'TVU Grid Setting',
    outputName: 'Output name',
    inputName: 'Please enter the output name',
    bitrate: 'Bitrate',
    resolution: 'Resolution',
    streamType: 'Stream type',
    all: 'All',
    todo: 'To Do',
    preparing: 'Preparing',
    stopping: 'Stopping',
    inprogress: 'In progress',
    stop: 'Stopped',
    failed: 'Failed',
    done: 'Done',
    preparingFiveMin: 'Preparing(5 min)',
    programFeed: 'Program Feed (With overlay)',
    cleanFeed: 'Clean Feed (With overlay)',
    outputNameRequired: 'The output name is required',
    isspNote: 'If you want to modify the ISSP template, you can edit it in Output-ISSP.'
  },
  eventList: {
    copy: 'Copy success',
    eventId: 'Event id:',
    advanced: 'More',
    advancedSettings: 'Advanced Settings',
    rtmpPush: 'RTMP Push',
    pageSize: 'Page size:',
    create: 'Create',
    central: 'Central Audio Mixer',
    cloudRecord: 'Cloud Record',
    cloudReceiver: 'Cloud Receiver',
    physicalReceiver: 'Physical Receiver',
    eventName: 'Event Name',
    time: 'Time',
    status: 'Status',
    log: 'Log',
    input: 'Input',
    output: 'Output',
    groupNum: 'Group Num',
    operate: 'Operate',

    stop: 'Stop',
    view: 'View',
    stopEvent: 'Stop Event',
    deleteEvent: 'Delete Event',
    noData: 'No Data',
    delTips: 'delete success',
    stopTips: 'Are you sure to stop the event?',
    deleteTips: 'Are you sure you want to delete event ',
    theEvent: 'The event is',
    onGoing: 'on going',
    isPreparing: 'is being prepared',
    allProgramsShutDown: 'All programs will be shut down after stopping the event.',
    stopTipSuccess: 'Stop event success.',
    createEvent: 'Create an event',
    editEvent: 'Edit an event',
    viewAnEvent: 'View an event',
    addTitle: 'Add title',
    startTime: 'Start time',
    endTime: 'End time',
    now: 'Now',
    duration: 'Duration',
    reset: 'Reset',
    pleaseSelect: 'Select Source',
    ReceiverSelect: 'Select Receiver',
    noneData: 'no data',
    loading: 'loading...',
    selectFile: 'Select file',
    backUp: 'Backup',
    bkpInput: 'BKP Input',
    pgm_out: 'PGM OUT',
    inputSelect: 'Input select',
    mainIn: 'Main IN',
    backUpIn: 'Backup IN',
    apply: 'Apply',
    outputs: 'Output',
    search: 'search',
    delay: 'Delay',
    delayTips: 'Low delay is recommended for excellent network conditions.',
    outputFormat: 'Output Format',
    region: 'Region',
    remark: 'Remark',
    addDescription: 'Add description',
    group: 'Group',
    groupTips: 'The group will not take effect until the name is added.',
    addNew: 'Add new',
    addGroupName: 'Add group name',
    copyGroupLink: 'Copy group link',
    advancedAudioMixer: 'Advanced audio mixer',
    copyLink: 'Copy link',
    doneN: 'Done',
    reuse: 'Reuse',
    createEventTips: 'Please, fill up the required fileds Event Name / Time / Input / Region / Group Name',
    createEventSuccess: 'Event creation successful',
    viewEvent: 'view',
    eventTimeTips: 'The Time interval must be greater than 10 minutes',
    groupLink: 'Group Link',
    switchInputSuccess: 'Switch Input select successful',
    serviceEntry: 'Service Entrance',
    countDown: 'Countdown',
    stoppedTime: 'The event was manually stopped on ',
    countDownEvent: 'Countdown to the end of the event',
    eventRemaining: 'Event duration exceeds remaining time,please select appropriate time or renewal fee',
    notSupportSdi: 'The current receiver is not support this feature',
    eventINProgress: 'Event in progress,countdown to the end of the event',
    ParticipationSettings: 'Participation Settings',
    backupReceiver: 'BKP Receiver',
    invited: 'Invited Model'
  },
  eventRegions: {
    seoul: 'Asia Pacific (Seoul)',
    mumbai: 'Asia Pacific (Mumbai)',
    paulo: 'South America (São Paulo)',
    parise: 'Europe (Paris)',
    frankfurt: 'Europe (Frankfurt)',
    tokyo: 'Asia Pacific (Tokyo)',
    virginia: 'US East (N. Virginia)',
    sydney: 'Asia Pacific (Sydney)',
    oregon: 'US West (Oregon)',
    bahrain: 'Middle East (Bahrain)'
  },
  otherWords: {
    viewSetting: 'View settings',
    eventId: 'eventId',
    copySuccess: 'Copy successfully',
    copyLinkTips: 'Click the link directly to join TVU Remote Commentator: \n',
    endTimeGreater: 'The EndTime must be greater than the StartTime',
    min: 'min',
    hour: 'hour',
    selectRegion: 'Select region',
    upLoadFileContinueTips: 'Upload not completed, are you sure to cancel the upload?',
    cancelTheUpload: 'Cancel the upload',
    continueToUpload: 'Continue uploading',
    cancelToUpload: 'Cancel upload',
    addExtIpSourceSuccess: 'Add EXT IP Source Success',
    nameIsRequired: 'Name is required',
    urlIsRequired: 'URL is required',
    urlValidTips: 'Please enter a valid URL starting with',
    fileAndSlotOnlyOne: 'Upload file and Slot5 cannot be selected together',
    onlySelectedOnce: 'can only be selected once',
    partyId: 'Party ID',
    elasticIpExplain: 'An Elastic IP address is a static IPv4 address designed for dynamic cloud computing.',
    elasticIp: 'Elastic IP',
    contactSupport: 'Please contact TVU SUPPORT for permission.',
    selectIp: 'Select IP',
    pleaseSelect: 'Please Select',
    ipOccupiedByProducer: 'The IP is occupied by',
    ofProducer: 'of producer.',
    receiverPlay: 'The selected Receivers will play it',
    editSetting: 'Edit setting',
    copyLink: 'Copy link'
  },
  coordinationRoom: {
    repeatJoin: 'Repeat Join',
    ok: 'OK',
    repeatContent: 'The current account has been logged in elsewhere. If you want to retain control of the meeting, please press F5 to refresh the page.',
    mainWindow: 'Main window',
    talkToAllGroups: 'Talk to All Commentator Groups',
    talkToGroup: 'Talk to Group',
    notJoinR: 'NOT-JOIN-R',
    onAir: 'ON-AIR',
    offAir: 'OFF-AIR',
    muted: 'MUTED',
    mutedOff: 'Commentator mic is OFF',
    onAirEnabled: 'Commentator mic is enabled and audio is enabled in audio mixer output',
    offAirEnabled: 'Commentator mic is enabled but audio is disabled in audio mixer output',
    startPlaying: 'Start playing',
    eventNameInfo: 'Event name',
    eventTimeInfo: 'Event time',
    groupNumInfo: 'Group number',
    eventInfoInput: 'Main Input',
    inputRType: 'Mode',
    cloudReceiver: 'Cloud',
    listVersion: 'The list shows all Receivers under your account.',
    bufferSize: 'Buffer size',
    eventReceiver: 'For event start, receivers are required to meet：',
    unavailable: 'The Receiver is unavailable.',
    online: 'Online',
    receiverVersion: 'Version 7.8+',
    currentVersion: 'Current Version:',
    ableDelyMessage: '"Delay" in Advance Setting is available.',
    detail: 'Detail',
    remoteCommentator: '"Remote Commentator" function enable',
    remoteCommentatorErrorInfo: 'Please enable this function on the web configuration page of the Receiver',
    hardwareVersion: 'Hardware version vs3500v3+',
    hardwareVersionWarning: 'Problems may occur with hardware versions lower than required',
    hardwareVersionUnknown: 'Unknown: no results',
    hardwareVersionNote: 'Note: Single R is recommended.',
    ipAndPort: 'IP and Port Mapping enable',
    notFoundIpAndPort: 'Unable to find ip and port',
    tvuTransceiver: 'TVU Transceiver',
    mainInput: 'Main Input',
    backUpInput: 'Backup Input',
    inputSelectInfo: 'INPUT select',
    mainIn: 'Main IN',
    eventBackUpIn: 'Backup IN',
    eventInfoOutPut: 'OUTPUT',
    coordinationInputSource: 'Input Source',
    me: 'Me',
    isSharing: 'is sharing',
    live: 'Live',
    pictureInPicture: 'Picture in picture',
    name: 'Name',
    corporation: 'Corporation',
    on: 'ON',
    off: 'OFF',
    cancel: 'Cancel',
    submit: 'Submit',
    producer: 'Producer',
    on_air: 'Turn ON, show "ON-AIR" tag and open to commentators.',
    settingName: 'Setting',
    forHostAndCommon: 'For Hosts and Commentators',
    forHost: 'For Yourself',
    PgmOutInput: 'PGM OUT',
    AudioChannelSetting: 'Audio Channel Setting',
    AudioChannelSettings: 'The setting takes effect for all Commentators.',
    mutiple: 'Multiple',
    stereo: 'Stereo',
    previewSourceType: 'Preview Source Type',
    unavailableSource: 'The source is unavailable.',
    couldNotAccessCoordination: 'Could not access to Coordination Room',
    exitCoordinationRoom: 'Exit this Coordination Room',
    accountAccessed: 'Your account has already accessed the event Coordination room elsewhere.',
    closeOtherAccount: 'Do you want to close that active session to proceed here?',
    agree: 'Yes',
    no: 'No',
    nameEmpty: 'Nickname cannot be empty.',
    groupDisplay: 'Group display',
    main: 'Main Input',
    backup: 'Backup Input',
    pgm: 'Main Output',
    backupPgm: 'Backup Output'

  },
  coordinationParticipant: {
    audioSetting: 'Audio Setting',
    reJoin: 'ReJoin',
    unableJoin: 'Unable to join',
    partyDisabled: 'The party as it may have been deleted or does not exist.',
    talkBackFail: 'Please "Click and Hold" for Talkback.',
    talkBackConflict: 'Functions "Talkback All, Talkback Coord and Talkback Group" cannot be used simultaneously.',
    commentatorAbnormalNet: 'Network is unstable! Due to network fluctuation you are out of sync with program. Please refresh and/or check your connection.',
    refresh: 'Refresh',
    theEventTimeIs: 'The event time is',
    gettingReady: 'Getting ready...',
    leaveParty: 'Leave party',
    turnOffCamera: 'Turn off camera',
    turnOnCamera: 'Turn on camera',
    talkBack: 'Talkback All',
    talkHostBack: 'Talkback Coord',
    talkPipBack: 'Talkback Group',
    talkBackEnabled: 'Commentator talkback is enabled',
    leavePartyTitle: 'Leave the party',
    cancel: 'Cancel',
    leave: 'Leave',
    leavePartyContent: 'Are you sure you want to leave? That will clear your audio assignation state on the output?',
    operationFailed: 'Failed to start video or audio. Please check your capture device',
    turnOffSpeaker: 'Turn off speaker',
    turnOnSpeaker: 'Turn on speaker',
    websiteSoundOff: 'Website sound off',
    notOperateFrequery: 'Please do not operate frequently',
    websiteSoundOn: 'Website sound on',
    releaseToEndTalk: 'Release to end talk',
    clikAndHold: 'Click and Hold (hold spacebar) to talk with Host and Group',
    clikAndHostHold: 'Click and Hold (hold "C") to talk with Host only',
    clikAndPipHold: 'Click and Hold (hold "G") to talk with Group only',
    turnOnMicPhone: 'Turn on microphone(mix into Audio Mixer)',
    turnOffMicPhone: 'Turn off microphone(stop mixed into Audio Mixer)',
    turnOnHostMicPhone: 'Turn on microphone',
    turnOffHostMicPhone: 'Turn off microphone',
    talentCommentator: 'Talent Commentator',
    mic: 'Mic',
    selectCamera: 'Select a Camera',
    selectMicrophone: 'Select a Microphone',
    selectSpeaker: 'Select a Speaker',
    bottomDown: 'Hide the bottom menu bar',
    bottomUp: 'Show the bottom menu bar',
    openAEC: 'AEC',
    openANS: 'ANS',
    openAGC: 'AGC',
    aecDetail: 'Acoustic Echo Canceller',
    ansDetail: 'Active Noise Cancellation',
    agcDetail: 'Automatic Gain Control',
    autoSwitchNewCameraSuccess: 'Auto switch new camera successfully',
    autoSwitchNewCameraFailed: 'Auto switch new camera failed',
    autoSwitchNewMicSuccess: 'AUto switch new mic successfully',
    autoSwitchNewMicFailed: 'Auto switch new mic failed',
    autoPlayFailed: 'The video auto play was interrupt by Chrome, do you want to resume?'
  },
  showAirTag: 'Show "ON-AIR" Tag',
  HideAirTag: 'Hide "ON-AIR" Tag',
  btnText: {
    rename: {
      text: 'Rename'
    },
    removeParticipant: {
      text: 'Remove from program'
    },
    setCrew: {
      text: 'Production Crew'
    },
    cancelCrew: {
      text: 'Participant'
    },
    startPrivateConversation: {
      text: 'Set private chat'
    },
    cancelPrivateConversation: {
      text: 'Hang up private chat'
    },
    replace: {
      text: 'Replace'
    },
    roleSetting: {
      text: 'Role setting'
    },
    setSpeaker: {
      text: 'Speaker'
    },
    cancelSpeaker: {
      text: 'Participant'
    },
    cancelQuestionSpeaker: {
      text: 'Participant'
    },
    hangUpInterview: {
      text: 'Hang up interview'
    },
    setQuestionSpeaker: {
      text: 'Interviewer'
    },
    interviewer: {
      text: 'Interviewer'
    },
    setPublicTalk: {
      text: 'Set public talk'
    },
    cancelQuestioner: {
      text: 'Participant'
    },
    cancelQuestionerRemove: {
      text: 'Remove from Question queue'
    },
    setQuestioner: {
      text: 'Participant'
    },
    addQuestioner: {
      text: 'Add in questioner queue'
    },
    toTop: {
      text: 'To top'
    },
    participant: {
      text: 'Participant'
    },
    denyRaiseHand: {
      text: 'Remove from Raise hand queue'
    },
    setBroadcast: {
      text: 'Broadcaster'
    },
    setPublicSpeaker: {
      text: 'Public speaker'
    },
    editTagName: {
      text: 'Edit tag name'
    },
    fullScreen: {
      text: 'Full screen'
    },
    moveParty: {
      text: 'Move to'
    }
  },
  producer: 'Producer',
  mixer: 'Audio Mixer',
  coordiRoom: 'Coord Room',
  tudn: 'TUDN',
  coordination: 'Coordination room',
  leaveParty: {
    notExist: 'Party ID does not exist',
    leave: 'Leave Party',
    removeFromParty: 'You have been removed from the party by the host.',
    backHome: 'Back to Home',
    enterPassWord: 'Enter participant passcode',
    alert: 'Alert',
    eventOverText: 'The event is over.',
    eventDeleteText: 'The event has been deleted.',
    invalidPartyIdText: 'Invalid Party ID',
    leavePartyText: 'You have left the party.',
    unableToJoin: 'Unable to join'
  },
  joinPartyWays: {
    clickMenu: 'Click on the menu above',
    openWays: 'Please use Safari or Google Chrome to open it.',
    joinPartyWithBrowser: 'Having issues to download or use TVU Anywhere?',
    joinByBrowser: 'Join party with browser',
    accessByBrowser: 'Access by browser',
    or: 'or',
    installAnyWhere: 'TVU Anywhere is installed',
    openAppJoinPartyLine: 'Click on “Join Partyline” to open app and join partyline.',
    joinPartyLine: 'Join Partyline',
    noApplication: 'No application installed',
    toInstallAnyWhere: 'Click on “Download” to go to app store and install TVU Anywhere.',
    downLoadFromStore: 'Download from Store'
  },
  advancedSettings: {
    linuxRrequired: '· This setting will only take effect for version 7.9+ Transceiver.',
    requiredBack: '· If you are using Backup with version 7.9+ Transceiver, this setting will take effect for both Main and Backup Transceivers.',
    delayRequired: '· If either of them has a Transceiver lower than version 7.9, you will need to contact Support to change the delay of that Transceiver to avoid desynchronisation.',
    required: 'You can make changes as required.',
    mainWindowSetting: 'Main Window Setting',
    mainWindowSettingNote: 'The following setting will take effect for Coordination Room Main Window (including Main Input, Backup Input, and PGM OUT sources) and the commenters Main Window.',
    bitrate: 'Bitrate',
    resolution: 'Resolution',
    framerate: 'Frame rate',
    defaultBitrate: '30(default)',
    OtherFeatures: 'Other Features',
    inputSourceRecordingTitle: 'The selected Slots will be recorded in Recording service.',
    SourceNum: 'Source {num}',
    outPutBufferFourth: 'profanity).If an issue occurs, you have time to react and is able to switch to a replacement video/image.',
    outPutBufferThird: '· This is primarily so the operator can censor the live source for any issues in the program(such as',
    outPutBufferSecond: 'Live Output Program and the actual video being encoded for output.',
    outPutBufferOne: '· The Output Buffer allows a time buffer (up to 300 seconds) to be set between what is viewed in the',
    outPutBufferTitle: 'What is "Output Buffer"?',
    replayAppTitle: 'The Replay app will capture all input sources including commentators.',
    pgmRecordingTitle: 'The PGM with remixes will be recorded in Recording service.',
    rpsTwo: 'The input source must be a PP slot or PP PGM.',
    rpsOne: 'Must be authorised at RPS.',
    rpsTitle: 'Condition for use:',
    allowCommentator: 'Allow Commentator to define audio output pair',
    replayApp: 'Replay App',
    pgmRecording: 'PGM Recording',
    inputSourceRecording: 'Input Source Recording',
    outPutBuffer: 'Output Buffer',
    rps: 'RPS',
    enableFeatures: 'Enable Features',
    pleaseSelect: 'If you need any features please select them.',
    encoderTitle: 'Commentator Video Encoder',
    encoderTips: 'When the commenters set as the Input source, the selected resolution configuration will be used. When a resolution of 720P and above is selected, the system will use a dual-stream mode, with the smaller stream being 180P for web preview and the larger stream being the below selected stream for production and broadcast.'
  },
  audioSettings: {
    title: 'Audio Advanced Settings',
    cancel: 'Cancel',
    save: 'Refresh to save'
  },
  mutedPop: {
    title: 'You’re muted.',
    notes: 'Press “Mic” button to mix your audio.',
    detailNotes: 'Or press “Talkback All” button (hold spacebar) / “Talkback Coord” button (hold “C”) / “Talkback Group”button (hold “G”) to start internal talk.'
  },
  rtmpPush: {
    name: 'Name',
    server: 'Server',
    key: 'Key',
    generate: 'Generate',
    copy: 'Copy',
    copyUrl: 'Copy URL',
    rtmpName: 'Please enter source name',
    serverInput: 'Automatically generated at the start of the program',
    keyInput: 'Please enter key',
    saveTips: 'Source name/Key cannot be empty'
  },
  receiverConflictTips: {
    outPutTitle: 'OutPut-Receiver usage time conflict',
    inPutTitle: 'InPut-Receiver usage time conflict',
    eventTime: 'The current event time is from',
    receiver: 'The receiver',
    scheduled: 'has been scheduled',
    by: 'by',
    from: 'from',
    coordinateTime: 'If necessary, please contact ',
    coordinateTimeInput: 'Please coordinate the use time of Receivers.',
    goIt: 'Go it'
  },
  sourceConflictTips: {
    title: 'IP OUT usage time conflict',
    eventTime: 'The current event time is from',
    receiver: 'The IP OUT',
    scheduled: 'has been scheduled',
    by: 'by',
    from: 'from',
    coordinateTime: 'Please coordinate the use time of Sources.',
    goIt: 'Go it'
  },
  preview: {
    settingTitle: 'Commentator Setting',
    groupName: 'Group name',
    joinNow: 'Join now',
    partyId: 'Party ID',
    noPartyCode: 'There is not have partyCode to join party',
    nameCannotEmpty: 'Please input your nickname before proceeding.',
    partyDisabled: 'Event is not running, party is disabled',
    partyHasDelete: 'This party has been deleted.',
    partyIdNotExist: 'Party ID does not exist'
  },
  invite: {
    receivedCohost: 'Received Invites',
    invite: 'Invite',
    getLink: 'Get link',
    shareTo: 'Share with participants',
    copyLink: 'Copy link',
    saveInvite: 'Save',
    anyoneJoin: 'Anyone with the link can join',
    onlyUserJoin: 'Only users added to the list can join',
    onlyByPasscode: 'Anyone with passcode can join',
    emailNotation: 'Email Notification',
    emailCorrectly: 'Please input correct email',
    remove: 'Remove',
    inviteCohoster: 'Add Co-host email',
    invitePlaceholder: "Add participant's email",
    inviteSetSuccess: 'Set successful',
    inviteSetCopySuccess: 'Set and Copy successful',
    inviteDeleteSuccess: 'Delete success',
    passCode: 'Passcode',
    copyPassCode: 'Copy passcode',
    passWordText: '6-digit password required.',
    plsInpPasscode: 'Please input passcode',
    setPasscodeSuccess: 'Set passcode success',
    wrongPasscodeFormat: 'Please enter the correct password format.',
    alreadyInvited: ' already invited'
  },
  receiverControl: {
    title: 'Remote Commentator/Commentators Connection Status',
    refreshTips: 'These status are automatically updated every 5s.',
    eventName: 'Event name',
    eventNameTips: 'Display the running events',
    groupName: 'Group name',
    commentatorName: 'Commentator name',
    statusPartyline: 'Connection Status with Partyline',
    mainInStatusReceiver: 'Connection Status with Receiver',
    mainIn: '(Main IN)',
    backupIn: '(Backup IN)',
    mainReceiverStatusTips: 'For TVU Transceiver Mode only',
    backupInStatusReceiver: 'Conenction Status with Receiver',
    backupReceiverStatusTips: 'Display when Backup IN enabled',
    operations: 'Operations',
    operationsTips: 'Buttons take effect for both Receivers',
    addToR: 'Add to R',
    removeFromR: 'Remove from R'
  }
}
