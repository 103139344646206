<template>
  <div class="invited">
    <TipsDailog class="setJoinBox" :info="inviteInfo" @save="setJoinWay" @open="getHadInviteUser" width="560px">
      <div class="controlInvite">
        <svg class="m_r_20 icon-a-TypeinvitationStatepermission" aria-hidden="true">
          <use xlink:href="#icon-a-TypeinvitationStatepermission"></use>
        </svg>
        <el-select v-model="selectModule" @change="showHadInvite" popper-class="modelSelect" placeholder="please select model">
          <el-option
            v-for="item in joinModel"
            :key="item.value"
            :label="item.value | showJoinModel"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <!--中间分割线-->
      <el-divider class="inviteSlider" v-if="selectModule === 1 || selectModule === 3"></el-divider>
      <!--分享给(1)-->
      <div class="inviteBox" v-if="selectModule === 1">
        <div class="userListBox">
          <p class="moduleTitle">{{$t('invite.shareTo')}}</p>
          <div class="moduleContent">
            <svg class="m_r_20 icon-a-TypeinvitationStateshare" aria-hidden="true">
              <use xlink:href="#icon-a-TypeinvitationStateshare"></use>
            </svg>
            <el-input @keyup.enter.native="addInviteList" :class="{redBorder: showErrorEmailText}" :placeholder="$t('invite.invitePlaceholder')" ref="emailInput" @input="checkInputText" v-model="searchName" clearable></el-input>
            <el-button @click="addInviteList">{{$t('leftPane.add')}}</el-button>
          </div>
          <p v-if="showErrorEmailText" class="redColor emailWarn">* {{$t('invite.emailCorrectly')}}</p>
        </div>
        <div class="hadInviteList scrollbar gray">
          <div class="li" v-for="(user, index) in willInviteList" :key="user.name">
            <span class="firstName" :class="user.color">{{ user.name? user.name.slice(0, 1): '' }}</span>
            <span class="emailContent">{{ user.name }}</span>
            <el-popover
              placement="bottom-start"
              width="200"
              trigger="hover"
              popper-class="remove"
              >
              <i class="el-icon-more right" slot="reference"></i>
              <span class="pointer" @click="removeInvite(index)">{{$t('invite.remove')}}</span>
            </el-popover>
          </div>
          <el-divider v-if="willInviteList.length"></el-divider>
          <div class="li" v-for="user in hadInviteList" :key="user.name">
            <span class="firstName" :class="user.color">{{ user.name? user.name.slice(0, 1): '' }}</span>
            <span class="emailContent">{{ user.name }}</span>
            <el-popover
              placement="bottom-start"
              width="200"
              trigger="hover"
              popper-class="remove"
              >
              <i class="el-icon-more right" slot="reference"></i>
              <span class="pointer" @click="deleteInvite(user.name)">{{$t('invite.remove')}}</span>
            </el-popover>
          </div>
        </div>
      </div>
      <!--密码(3)-->
      <div class="passCodeBox" v-if="selectModule === 3">
        <div class="moduleTitle">{{$t('invite.passCode')}}</div>
        <div class="passContent" :class="{noMarginRIght: editPass}">
          <svg class="m_r_20 icon-a-TypeinvitationStatepasscode" aria-hidden="true">
            <use xlink:href="#icon-a-TypeinvitationStatepasscode"></use>
          </svg>
          <el-input v-model="passCodeVal" :maxlength="6" @keyup.enter.native="modifyPassWord('byEnter')" @input="judgePassCode" :class="{onFocus: editPass,onFocusError:showErrorPassCode}"></el-input>
          <el-button
            v-if="editPass"
            class="copy"
            v-clipboard:copy="passCodeVal"
            :class="{saves:editPass}"
            @click="modifyPassWord('byEnter')"
            >{{$t('invite.saveInvite')}}
          </el-button>
            <el-button
            v-else
            class="copy"
            v-clipboard:copy="passCodeVal"
            :class="{saves:editPass}"
            @click="onCopy"
            >{{$t('invite.copyPassCode')}}
          </el-button>
        </div>
        <div v-if="showErrorPassCode" class="redColor passwordText">{{ $t('invite.passWordText') }}</div>
      </div>
    </TipsDailog>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import i18n from '@/lang/index.js'
import TipsDailog from '@/components/common/tipsDailog'

export default {
  components: {
    TipsDailog
  },
  props: {
    partyCode: {
      type: [String, Number],
      default: ''
    },
    inviteInfo: {
      type: Object,
      default: function () {
        return {}
      }
    },
    invitedInfo: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      searchName: '',
      passCodeVal: '',
      selectModule: '',
      willInviteList: [],
      hadInviteList: [],
      showErrorEmailText: false,
      hadInviteUserColor: ['green', 'orange', 'purple', 'blue'],
      joinModel: [
        { value: 0, label: 'Anyone with the link can join' },
        { value: 1, label: 'Only users added to the list can join' },
        { value: 3, label: 'Anyone with passcode can join' }
      ],
      editPass: false,
      env: JSON.parse(localStorage.getItem('partylineCommentatorConfig')).apiEnv,
      inputTimer: null,
      showErrorPassCode: false
    }
  },
  computed: {
    ...mapState({
      State: state => state
    })
  },
  watch: {
    'inviteInfo.show': {
      handler (newVal) {
        if (newVal) {
          // 弹窗打开清空状态
          this.editPass = false
          this.showErrorPassCode = false
        }
      }
    },
    invitedInfo: {
      handler (newVal) {
        if (newVal && JSON.stringify(newVal) !== '{}') {
          const inviteMode = newVal.inviteMode
          const array = []
          switch (inviteMode) {
            case 0:
              this.selectModule = 0
              break
            case 1:
              this.selectModule = 1
              newVal.emailList.forEach(email => {
                const obj = {}
                obj.name = email
                obj.color = this.hadInviteUserColor[(email.length) % 4] || 'green'
                array.push(obj)
              })
              this.hadInviteList = array
              break
            case 3:
              this.selectModule = 3
              this.passCodeVal = newVal.invitePassCode
              break
          }
        }
      }
    }
  },
  methods: {
    checkInputText (val) {
      if (this.inputTimer) clearTimeout(this.inputTimer)
      const _this = this
      this.inputTimer = setTimeout(() => {
        const regEmail = /^([a-zA-Z0-9_])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
        if (val && !regEmail.test(val)) {
          this.showErrorEmailText = true
          _this.$refs.emailInput.$el.children[0].focus()
        } else {
          this.showErrorEmailText = false
        }
      }, 800)
    },
    setJoinWay () {
      if (!this.isEmail(this.searchName)) return
      if (!this.passCodeVal && this.selectModule === 3 && this.passCodeVal.length != 6) {
        this.$message.warning(this.$t('invite.plsInpPasscode'))
        return
      }

      const inviteList = []
      this.willInviteList.forEach(user => {
        const obj = {}
        obj.memberEmail = user.name
        obj.partyCode = this.partyCode
        inviteList.push(obj)
      })
      // 只添加一个走这里
      if (!this.willInviteList.length && this.searchName) {
        inviteList.push({ memberEmail: this.searchName, partyCode: this.partyCode })
      }
      const linkUrl = 'https://' + window.location.host + '/partyline?join_id=' + this.partyCode + `${this.env ? '&env=' + this.env : ''}`
      const params = {
        inviteMode: this.selectModule,
        partyCode: this.partyCode,
        emailNotification: 1,
        linkUrl: linkUrl,
        partyInviteMemberList: !this.selectModule ? [] : inviteList,
        invitePassCode: this.passCodeVal ? this.passCodeVal : ''
      }
      this.$http
        .post(`${this.State.config.urlInfo.partyline}/partyline-backend/partyExtendInfo`, params, { errorTips: false })
        .then(res => {
          const { errorCode, errorInfo } = res.data
          if (errorCode === '0x0') {
            this.searchName = ''
            if (!params.inviteMode) this.hadInviteList = []
            this.$message.success(this.$t('invite.inviteSetSuccess'))
          } else if (errorCode === '9000002') {
            this.$message.warning(this.$t('personalInfo.forbidSetting'))
          } else if (errorCode === '80520105') {
            this.$message.error(params.partyInviteMemberList[0].memberEmail + this.$t('invite.alreadyInvited'))
          } else {
            this.$message.error(errorInfo)
          }
        })
    },
    isEmail (val) {
      const regEmail = /^([a-zA-Z0-9_])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
      if (val && !regEmail.test(val)) {
        this.showErrorEmailText = true
        this.$refs.emailInput.$el.children[0].focus()
        return false
      }
      return true
    },
    async getHadInviteUser (showList) {
      // 清空之前的状态
      this.searchName = ''
      this.showErrorEmailText = false
      this.willInviteList = []

      await this.$http
        .get(`${this.State.config.urlInfo.partyline}/partyline-backend/partyExtendInfo/${this.partyCode}`)
        .then(res => {
          const { errorCode, result } = res.data
          if (errorCode === '0x0') {
            const array = []
            result.emailList.forEach(user => {
              const obj = {}
              obj.name = user
              obj.color = this.hadInviteUserColor[(user.length) % 4] || 'green'
              array.push(obj)
            })
            this.hadInviteList = array
            this.passCodeVal = result.invitePassCode
            // 切换模式的时候不需要更改值
            if (!showList) {
              this.selectModule = result.inviteMode
            }
          }
        })
    },
    async showHadInvite (model) {
      // 清空模式三passcode
      this.passCodeVal = ''
      this.inviteInfo.hideSave = true
      if (model === 1) {
        await this.getHadInviteUser('showList')
      }
      if (model === 3) {
        await this.getHadInviteUser('showList')
        this.passCodeVal = this.createRandomPassCode()
      }
      this.setJoinWay()
      this.$emit('callback', {
        type: 'changeModel',
        val: model
      })
    },
    createRandomPassCode () {
      let code = ''
      for (let i = 0; i < 6; i++) {
        const type = this.createRandom(1, 3)
        switch (type) {
          case 1:
            code += String.fromCharCode(this.createRandom(48, 57))
            break
          case 2:
            code += String.fromCharCode(this.createRandom(65, 90))
            break
          case 3:
            code += String.fromCharCode(this.createRandom(97, 122))
            break
        }
      }
      return code
    },
    createRandom (min, max) {
      return Math.round(Math.random() * (max - min) + min)
    },
    addInviteList (email) {
      if (this.searchName === this.State.loginInfo.email) {
        this.$message.warning(this.$t('personalInfo.forbidSetting'))
        return
      }
      if (email && this.searchName && this.isEmail(this.searchName) && !this.isHadInvitedPass(this.searchName)) {
        const obj = {}
        obj.name = this.searchName
        obj.color = 'gray'
        this.willInviteList.push(obj)
      }
      this.willInviteList = [...new Set(this.willInviteList)]
      // 转移save逻辑到add(邀请email)
      this.setJoinWay()
      this.inviteInfo.show = false
    },
    // 判断是否已经存在于列表中(邀请passcode)
    isHadInvitedPass (email) {
      let flag = false
      this.willInviteList.forEach(item => {
        if (item.name == email) {
          flag = true
        }
      })
      this.hadInviteList.forEach(info => {
        if (info.name == email && info.status != 2) {
          flag = true
        }
      })
      if (flag) {
        this.$message.warning(this.$t('personalInfo.hadInvited'))
      }
      return flag
    },
    removeInvite (index) {
      this.willInviteList.splice(index, 1)
    },
    deleteInvite (email) {
      const params = {
        emailList: [email],
        partyCode: this.partyCode
      }
      this.$http
        .delete(`${this.State.config.urlInfo.partyline}/partyline-backend/partyExtendInfo/emails`, { data: params })
        .then(res => {
          const { errorCode } = res.data
          if (errorCode === '0x0') {
            this.$message.success(this.$t('invite.inviteDeleteSuccess'))
            this.getHadInviteUser('showList')
          }
        })
    },
    judgePassCode (passCode) {
      this.editPass = true
      this.passCodeVal = passCode.replace(/[^\w]/ig, '')
      if (!this.passCodeVal || this.passCodeVal.length != 6) {
        this.showErrorPassCode = true
      } else {
        this.showErrorPassCode = false
      }
    },
    modifyPassWord (type) {
      if (!this.passCodeVal) {
        this.$message.warning(this.$t('invite.plsInpPasscode'))
        return
      }
      if (this.passCodeVal.length != 6) {
        this.$message.warning(this.$t('invite.wrongPasscodeFormat'))
        return
      }
      const params = {
        partyCode: this.partyCode,
        invitePassCode: this.passCodeVal
      }
      this.$http
        .post(`${this.State.config.urlInfo.partyline}/partyline-backend/partyExtendInfo`, params)
        .then(res => {
          const { errorCode } = res.data
          if (errorCode === '0x0') {
            this.inviteInfo.show = false
            if (type === 'byEnter') this.editPass = false
            this.$message.success(this.$t('invite.inviteSetCopySuccess'))
          }
        })
    },
    returnColor (info) {
      const { status } = info
      if (status === 0 || status === 2) {
        return 'gray'
      } else if (status === 1) {
        return this.hadInviteUserColor[(info.collaborateEmail.length) % 4] || 'green'
      }
    },
    onCopy (item) {
      this.$message({
        message: this.$t('copy.onCopy'),
        type: 'success'
      })
    }
  },
  filters: {
    showJoinModel (value) {
      if (value === 0) {
        return i18n.t('invite.anyoneJoin')
      } else if (value === 1) {
        return i18n.t('invite.onlyUserJoin')
      } else if (value === 3) {
        return i18n.t('invite.onlyByPasscode')
      } else {
        return i18n.t('invite.anyoneJoin')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-dialog__wrapper.setJoinBox {
  /deep/.el-dialog{
    margin-top: 24px!important;
  }
}
</style>
