/* //节流
export default {}.install = (Vue, options = {}) => {
    Vue.directive('repeatClick', {
        inserted(el, binding) {
            let delayTime = binding.value.delay || 2600
            el.addEventListener('click', e => {
                if (!el.disabled) {
                    el.disabled = true
                    el.style.cursor = 'not-allowed'
                    binding.value.params = binding.value.params || []
                    binding.value.event(...binding.value.params)
                    setTimeout(() => {
                        el.style.cursor = 'pointer'
                        el.disabled = false
                    }, delayTime)
                }
            })
        }
    })
} */

// 防抖
// export default {}.install = (Vue, options = {}) => {
// Vue.directive('repeatClick', {
//   inserted (el, binding) {
//     let timer
//     el.addEventListener('click', e => {
//       const delayTime = binding.value.delay || 1000
//       clearTimeout(timer)
//       timer = setTimeout(() => {
//         binding.value.params = binding.value.params || []
//         binding.value.event(...binding.value.params)
//       }, delayTime)
//     })
//   }
// })
// 防抖
// let delayTime
// let timer
// let globalHandleEvent
// Vue.directive('repeatClick', {
//   inserted (el, binding) {
//     delayTime = 1000
//     timer = null
//     const handleEvent = () => {
//       if (timer !== null) {
//         console.log('连续点击')
//         // console.log('连续点击');
//         clearTimeout(timer)
//       }
//       const canSend = !timer // 第一次 先发送一次

//       timer = setTimeout(() => {
//         timer = null
//       }, delayTime)
//       if (canSend) {
//         binding.value.params = binding.value.params || []
//         binding.value.event(...binding.value.params)
//       }
//     }
//     globalHandleEvent = handleEvent
//     el.addEventListener('click', handleEvent)
//   },
//   unbind (el) {
//     try {
//       el.removeEventListener('click', globalHandleEvent)
//     } catch (e) {
//       console.info('error', e)
//     }
//   }
// })
// }
export default {
  install (Vue) {
    Vue.directive('repeatClick', {
      inserted (el, binding) {
        el.addEventListener('click', () => {
          if (!el.disabled) {
            el.disabled = true
            setTimeout(() => {
              el.disabled = false
            }, binding.value || 3 * 1000)
          }
        })
      }
    })
  }
}
