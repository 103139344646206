<template>
  <div class="tips">
    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="30%"
      @close="closeDialog"
    >
      <template v-slot:title>
        <span class="el-icon-warning"></span>
        <span>Output-Receiver status of </span>
        <span id="title">{{ '"' + occupiedReceiverInfo.eventName + '"' }}</span>
      </template>
      <ul class="content scrollbar" v-if="occupiedReceiverInfo.receivers && occupiedReceiverInfo.receivers.length > 0">
        <li v-for="value in occupiedReceiverInfo.receivers" :key="value.receiverId">
          <p class="text">
            The current Receiver <span id="title">{{'"' + value.receiverName + '"'}}</span> is living with <span id="title">{{'"' + value.liveName + '"'}}</span>, do you want
            to switch to the current source ?
          </p>
          <div class="operate">
            <button class="yes" @click="changeStatus(value,true)" :class="{active: value.status}">Yes</button>
            <button class="deny" @click="changeStatus(value, false)" :class="{active: !value.status }">No</button>
          </div>
          <el-divider></el-divider>
        </li>
      </ul>
      <div slot="footer" class="dialog-footer">
      <el-button class="cancel" @click="submitChoose('no')">Cancel</el-button>
      <el-button class="submit" @click="submitChoose">Confirm</el-button>
    </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    occupiedReceiverInfo: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      dialogVisible: true
    }
  },
  methods: {
    changeStatus (value, type) {
      this.$emit('callBack', this.occupiedReceiverInfo.eventId, value, type)
    },
    async submitChoose (value) {
      const occupiedPolicy = []
      this.occupiedReceiverInfo.receivers.forEach(item => {
        occupiedPolicy.push({
          receiverId: item.receiverId,
          occupied: value === 'no' ? false : !!item.status
        })
      })
      try {
        await this.$http.post('/commentator-backend/output/receiver/occupied', {
          eventId: this.occupiedReceiverInfo.eventId,
          occupiedPolicy: occupiedPolicy
        })
        this.$emit('closeReceiverDialog', this.occupiedReceiverInfo.eventId)
      } catch (err) {
        console.log(err);
      }
    },
    closeDialog () {
      this.submitChoose('no')
    }
  }
}
</script>

<style scoped lang="less">
.tips {
  /deep/ .el-dialog {
    width: 480px !important;
    min-height: 188px;
    background-color: #28282e;
    .el-dialog__header {
      color: #f1f1f1;
      font-size: 16px;
      .el-dialog__headerbtn {
        .el-dialog__close {
          color: #999999;
          font-size: 20px;
          font-weight: bold;
        }
      }
      #title {
        color: #03897b;
      }
      .el-icon-warning {
        width: 21px;
        height: 21px;
        line-height: 21px;
        text-align: center;
        margin-right: 17.5px;
        &:before {
          display: inline-block;
          color: #ffb524;
          font-size: 21px;
          transform: translateY(2px);
        }
      }
    }
    .el-dialog__body {
      padding-top: 16px;
      padding: 16px 20px 30px 20px;
      padding-bottom: 0px;
      .content {
        max-height: 475px;
        overflow-y: auto;
        overflow-x: hidden;
        padding-right: 10px;
        .text {
          width: 392px;
          height: 44px;
          margin-left: 37px;
          color: #f1f1f1;
          font-size: 14px;
          padding-right: 28px;
          margin-bottom: 40px;
          word-break: break-word;
          #title {
            color: #03897b;
          }
        }
        .operate {
          display: flex;
          justify-content: flex-end;
          button {
            border-radius: 4px;
            width: 80px;
            height: 32px;
            text-align: center;
            color: #dbdbdb;
            font-size: 14px;
            cursor: pointer;
          }
          .deny {
            background-color: #28282e;
            border: 1px solid #666666;
          }
          .yes {
            margin-right: 10px;
            background-color: #28282e;
            border: 1px solid #666666;
          }
          .active {
            background-color: #03897b;
            border: none;
          }
        }
        .el-divider {
          width: 402px;
          margin-left: 37px;
          background-color: #333339;
        }
      }
    }
    .el-dialog__footer {
      .dialog-footer {
        .cancel {
          width: 80px;
          height: 32px;
          line-height: 9px;
          color: #FFFFFF;
          background-color: #28282e;
          border: 1px solid #666666;
        }
        .submit {
          width: 168px;
          height: 32px;
          border: none;
          background-color: #03897B;
          color: #FFFFFF;
        }
      }
    }
  }
}
</style>
