<template>
  <main class="clearfix">
    <PageLoading></PageLoading>
  </main>
</template>

<script>
import PageLoading from './pageLoading'
import Tool from '@/assets/js/utils.js'
import { mapState } from 'vuex'
export default {
  components: {
    PageLoading
  },
  data () {
    return {
      partyCode: '',
      env: '',
      participateUrl: '',
      isWindows: true,
      isAndroid: false,
      isMac: false,
      isLinux: false,
      participateChannelMode: {
        12: 'commentator'
      },
      channelMode: {
        12: 'commentator'
      }
    }
  },
  computed: {
    ...mapState({
      State: (state) => state,
      Config: (state) => state.config,
      CheckEnv: (state) => state.checkEnv
    })
  },
  async created () {
    if (this.CheckEnv.updateEnv) {
      window.location.reload()
      return
    }
    this.isWindows = navigator.userAgent.includes('Windows')
    this.isAndroid = navigator.userAgent.includes('Android')
    this.isMac = /Macintosh|Mac OS X/i.test(navigator.userAgent)
    this.isLinux = /Linux/i.test(navigator.userAgent)
    const isChromeOs = navigator.userAgent.includes('CrOS')
    const { join_id } = this.$route.query
    const joinId = join_id ? join_id.replace(/[^0-9]/gi, '') : ''
    this.partyCode = joinId
    this.participateUrl = `${location.protocol}//${location.host}/commentator/coordinationParticipate?id=${this.partyCode}${this.Config.apiEnv ? `&env=${this.Config.apiEnv}` : ''}`
    // 判断用户登录了没有
    console.info(50, this.CheckEnv, this.CheckEnv.channelModel, this.participateUrl)
    const sid = Tool.getSession()
    // 获取router信息
    if (sid) {
      // 判断 join party 是否是当前 用户的
      if (joinId) {
        this.checkGroup()
      } else {
        this.$router.push({
          name: 'commentator'
        })
      }
    } else {
      if (joinId) {
        if (!isChromeOs && !this.isWindows && (!this.isLinux || this.isAndroid) && !sessionStorage.getItem('enterPartyType')) {
          this.$router.push({
            name: 'joinParty',
            query: {
              join_id: joinId
            }
          })
          return
        }
        if (this.participateChannelMode[this.CheckEnv.channelModel]) {
          this.$router.push({
            name: 'preview',
            query: {
              id: joinId
            }
          })
        } else {
          if (this.CheckEnv.channelModel) {
            this.$message.error('Please enter correct party ID or URL')
            this.$router.push({
              name: 'leaveParty',
              query: {
                type: 'unExitParty'
              }
            })
          }
        }
      } else {
        console.info('登陆页面')
        window.location.href = `${this.Config.urlInfo.userservice}/?url=${location.protocol}//${location.host}/commentator&serviceName=${this.Config.serverName}`
      }
    }
  },
  methods: {
    checkGroup () {
      if (this.CheckEnv.channelModel == -1) {
        // -1代表partyCode不存在
        this.$message.error('Please enter correct party ID or URL')
      } else {
        if (this.participateChannelMode[this.CheckEnv.channelModel]) {
          this.$router.push({
            name: 'preview',
            query: {
              id: this.partyCode
            }
          })
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
main {
  display: flex;
  .contentBox {
    flex: 1;
    width: 70%;
  }
  .loadingPage {
    background: #212125;
    text-align: center;
    width: 100vw;
    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    i {
      color: #33ab4f;
      font-size: 40px;
    }
    p {
      color: #f1f1f1;
      margin-top: 30px;
      font-size: 20px;
    }
  }
}
</style>
